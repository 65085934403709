import React, { useEffect, useMemo, useState } from 'react';
import { StockOnHandCount } from '../../Models/Carton';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  GridAggregationFunction,
  GridAggregationModel,
  GridColDef,
  GridColTypeDef,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { Box } from '@mui/material';
import {
  standardDataGridStyles,
  toolbarProps,
} from '../../Components/StandardDataGrid';
import {
  StockOnHandCountWithNames,
  StockOnHandCustomerCountWithNames,
} from './StockOnHand';
import CardTitle from '../../Components/CardTitle';
export interface StockOnHandDataGridProps {
  data: StockOnHandCustomerCountWithNames[];
  filteredProductId?: number;
  loading?: boolean;
  onRowClick?: (row: StockOnHandCount | StockOnHandCountWithNames) => void;
}

const numericColType: GridColTypeDef = {
  type: 'number',
  minWidth: 120,
  cellClassName: 'font-tabular-nums',
  availableAggregationFunctions: ['sum'],
};
const stockOnHandDataGridStyle = {
  ...standardDataGridStyles,
  fontSize: '13px',
  '& .MuiDataGrid-aggregationColumnHeaderLabel': {
    visibility: 'hidden',
  },
};

export default function ({
  data,
  loading,
  filteredProductId,
  onRowClick,
}: StockOnHandDataGridProps) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const [aggregationModel, setAggregationModel] =
    useState<GridAggregationModel>({
      palletCount: !filteredProductId ? 'uniquePallets' : 'sum',
      qty: 'sum',
      totalWeight: 'sum',
      availableQty: 'sum',
      availableWeight: 'sum',
      holdQty: 'sum',
      holdWeight: 'sum',
      allocatedQty: 'sum',
      allocatedWeight: 'sum',
    });

  const dataForTable = useMemo(() => {
    const counts: StockOnHandCountWithNames[] = [];
    for (const row of data || []) {
      counts.push(
        ...row.stockOnHandCount.filter(
          (s) => !filteredProductId || s.productId === filteredProductId
        )
      );
    }
    return counts;
  }, [data, filteredProductId]);

  // will sum all totalPalletCounts depending on how many customers are in the data set
  // if we are filtering by product id, we don't need the unique pallets and can sum the palletCount from the SOH counts
  const uniquePallets = useMemo(() => {
    return {
      label: 'Sum of unique pallets',
      getCellValue: ({ row }) => {
        if (filteredProductId) return (row.palletCount ?? 0) as number;
        return null;
      },
      apply: ({ values }) => {
        if (filteredProductId)
          return values.reduce((a, b) => (a ?? 0) + (b ?? 0), 0);
        return data?.map((t) => t.totalPalletCount).reduce((a, b) => a + b, 0);
      },
      columnTypes: ['number'],
    } as GridAggregationFunction;
  }, [filteredProductId, data]);

  useEffect(() => {
    aggregationModel.palletCount = filteredProductId ? 'sum' : 'uniquePallets';
    setAggregationModel(aggregationModel);
  }, [filteredProductId, aggregationModel]);

  const handleRowClick = (e: GridRowParams<StockOnHandCountWithNames>) => {
    if (e.id == GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) return;
    if (onRowClick) {
      onRowClick(e.row);
    }
  };

  const columnDefs: GridColDef<StockOnHandCountWithNames>[] = [
    {
      field: 'productCode',
      headerName: 'Product',
      sortable: true,
      aggregable: false,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      sortable: true,
      minWidth: 120,
      flex: 1,
      aggregable: false,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      sortable: true,
      flex: 1,
      minWidth: 120,
      aggregable: false,
    },
    {
      field: 'inTransit',
      headerName: 'In Transit',
      sortable: true,
      flex: 1,
      aggregable: false,
      minWidth: 130,
      renderCell: (params) => (params.row.inTransit ? 'In Transit' : ''),
    },
    {
      field: 'palletCount',
      headerName: 'Pallets',
      sortable: true,
      ...numericColType,
      availableAggregationFunctions: !filteredProductId
        ? ['uniquePallets', 'sum']
        : ['sum'],
    },
    {
      field: 'qty',
      headerName: 'Qty',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'totalWeight',
      headerName: 'Total Weight',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'availableQty',
      headerName: 'Available Qty',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'availableWeight',
      headerName: 'Available Weight',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'holdQty',
      headerName: 'Hold Qty',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'holdWeight',
      headerName: 'Hold Weight',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'allocatedQty',
      headerName: 'Allocated Qty',
      sortable: true,
      ...numericColType,
    },
    {
      field: 'allocatedWeight',
      headerName: 'Allocated Weight',
      sortable: true,
      ...numericColType,
    },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
      }}
    >
      <CardTitle
        title="Stock on Hand - Products / Customers"
        loading={loading}
      />
      <DataGridPremium
        data-testid={'stock-on-hand-data-grid'}
        rows={dataForTable}
        columns={columnDefs}
        loading={loading}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          uniquePallets,
        }}
        onRowClick={(e: GridRowParams<StockOnHandCountWithNames>) =>
          handleRowClick(e)
        }
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: toolbarProps,
        }}
        sx={stockOnHandDataGridStyle}
      />
    </Box>
  );
}
