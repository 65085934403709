import React, { useMemo } from 'react';
import SortedTable, { SortedTableColumn } from '../SortedTable';
import { tryFormatDateStr } from '../../Lib/utils';
import { PalletMovementHistory } from '../../Models/PalletMovementHistory';

export interface PalletMovementProps {
  palletMovementHistory: PalletMovementHistory[] | undefined;
  loading: boolean;
}

export default function ({
  palletMovementHistory,
  loading,
}: PalletMovementProps) {
  const columnDefs = useMemo<SortedTableColumn<PalletMovementHistory>[]>(() => {
    return [
      {
        id: 'palletCode',
        label: 'Pallet',
        sortable: true,
      },
      {
        id: 'type',
        label: 'Type',
        sortable: true,
      },
      {
        id: 'cartonsQty',
        label: 'Carton Qty',
        sortable: true,
        numeric: true,
      },
      {
        id: 'cartonsWeight',
        label: 'Carton Weight',
        sortable: true,
        numeric: true,
      },
      {
        id: 'previousLocation',
        label: 'From Location',
        sortable: true,
      },
      {
        id: 'newLocation',
        label: 'To Location',
        sortable: true,
      },
      {
        id: 'creationTime',
        label: 'Created Time',
        sortable: true,
        cellRender: (data) =>
          tryFormatDateStr(data.creationTime, 'dd/MM/yyyy hh:mm:ss a'),
      },
      {
        id: 'bolNumber',
        label: 'BOL Number',
        sortable: true,
      },
      {
        id: 'containerCode',
        label: 'Container Code',
        sortable: true,
      },
      {
        id: 'userName',
        label: 'User Name',
        sortable: true,
      },
      {
        id: 'notes',
        label: 'Notes',
        sortable: true,
      },
    ];
  }, []);

  return (
    <SortedTable
      columnDefs={columnDefs}
      data={palletMovementHistory}
      loading={loading}
      title="Pallet Movement - History"
      rowKey="id"
      smallFont
      smallGap
      searchable
      pageSize="auto"
      exportable
    />
  );
}
