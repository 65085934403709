import React, { useContext } from 'react';
import { toast } from '../../Components/Toast';
import FormField from '../../Components/Forms/FormField';
import { Box, Button, Stack, Typography } from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import LoadingButton from '@mui/lab/LoadingButton';
import CurrentUserService from '../../Services/CurrentUserService';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../Providers/AuthProvider';

/***
 * Change Password
 */
interface ChangePasswordProps {
  fullWidthButtons: boolean;
  showBackButton: boolean;
  showLogoutButton: boolean;
}

const ChangePasswordBase = ({
  fullWidthButtons,
  showBackButton,
  showLogoutButton,
}: ChangePasswordProps) => {
  const nav = useNavigate();
  const { refreshToken, logout } = useContext(AuthContext);

  const onSuccess = () => {
    // toast to show message
    // redirect back to the login page
    toast('Password changed successfully');
    refreshToken();
    // route to the dashboard
    nav('/');
  };

  const handleCancel = () => {
    // redirect back to the previous page
    window.history.back();
  };

  const handleLogout = () => {
    logout();
  };

  const onSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    // ensure the new password and confirm password match
    if (form.data.newPassword !== form.data.newPasswordConfirm) {
      toast('Passwords do not match');
      return;
    }

    form.submit();
  };

  const form = useApiForm(
    CurrentUserService.changePassword,
    {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess,
    }
  );

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      data-testid="change-password-form"
      noValidate
    >
      <Typography variant="body1" gutterBottom sx={{ paddingBottom: 1 }}>
        To change your password, please enter your current password followed by
        the new password.
      </Typography>

      <FormField
        form={form}
        id="currentPassword"
        type="password"
        label="Current Password"
        required
      />
      <FormField
        form={form}
        id="newPassword"
        type="password"
        label="New Password"
        required
      />
      <FormField
        form={form}
        id="newPasswordConfirm"
        type="password"
        label="Confirm New Password"
        required
      />

      {!fullWidthButtons && (
        <Stack spacing={1} direction="row" sx={{ paddingTop: 2 }}>
          {showBackButton && (
            <Button
              variant="contained"
              onClick={handleCancel}
              disabled={form.processing}
              tabIndex={1}
              data-testid="change-password-back"
            >
              Back
            </Button>
          )}

          {showLogoutButton && (
            <Button
              variant="contained"
              onClick={handleLogout}
              disabled={form.processing}
              tabIndex={2}
              data-testid="change-password-logout"
            >
              Logout
            </Button>
          )}

          <LoadingButton
            variant="contained"
            type="submit"
            loading={form.processing}
            tabIndex={3}
            data-testid="change-password-save"
          >
            Save
          </LoadingButton>
        </Stack>
      )}
      {fullWidthButtons && (
        <Box sx={{ mt: 1 }}>
          {showBackButton && (
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              disabled={form.processing}
              tabIndex={1}
              data-testid="change-password-back"
              sx={{ mb: 2 }}
            >
              Back
            </Button>
          )}

          {showLogoutButton && (
            <Button
              variant="contained"
              fullWidth
              onClick={handleLogout}
              data-testid="change-password-logout"
              disabled={form.processing}
              tabIndex={2}
              sx={{ mb: 2 }}
            >
              Logout
            </Button>
          )}

          <LoadingButton
            variant="contained"
            fullWidth
            type="submit"
            loading={form.processing}
            data-testid="change-password-save"
            tabIndex={3}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default ChangePasswordBase;
