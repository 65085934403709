import { faker } from '@faker-js/faker';
import { PaperProps } from '@mui/material';
import { Factory } from 'fishery';
import React from 'react';
import StatusChip, { StatusChipColors } from '../Components/StatusChip';
import { UserNameDto } from './User';
import { CodeEntityQuantity } from './UserDefinedCode';

// this is used for assigning Billing Codes in a way which can be audited on the Container entity
export const CONTAINER_ENTITY_NAME = 'BOLContainers.BOLContainer';

export const CONTAINER_ENTITY_AUDIT_NAME = `Coolpak.WMS.${CONTAINER_ENTITY_NAME}`;

export interface BOLContainer {
  id: number;
  code: string;
  status: BOLContainerStatus;
  statusName: string;
  type?: string;
  location?: string;
  bolNumber?: number;
  billOfLadingId?: number;
  billingCompletedDate?: string;
  billingCycle?: string;
  loadingCompletedDate?: string;
  checklistCompletedDate?: string;
  loadedCartons?: number;
  totalCartons?: number;
  loadedPallets?: number;
  palletsInPickedLocation?: number;
  totalPallets?: number;
  loadedWeight?: number;
  totalWeight?: number;
  admin?: UserNameDto;
  customerName?: string;
  consigneeName?: string;
  customerOrderNo?: string;
  warehouseId?: number;
  warehouseName?: string;
  checklistResponseIds: number[];
  eCert?: ECert;
  netsuiteSalesOrderNumber?: string;
  isDeleteAble?: boolean;
  notes?: string;
  loadType?: string;
  priority?: number;
  instructions?: string;
  vesselName?: string;
  shipDate?: string;
  canExportBol: boolean;
  bolOrderType?: string;
}

export interface ECert {
  id: number;
  certificatePublicId: string;
  consignorId?: string;
  consigneeId?: string;
  status?: ECertStatus;
  bolContainerCodes?: string[];
  remarks?: ECertRemark[];
}
export enum ECertStatus {
  draft,
  submitted,
}
export interface ECertRemark {
  id: number;
  remarkType: string;
  remarkValue: string;
}

export interface BOLContainerWithCharges {
  id: number;
  code: string;
  status: number;
  statusName: string;
  entityType: string;
  entityTypeId?: number;
  entityTypeNumber?: number;
  billingCycle?: string;
  loadingCompletedDate?: string;
  customerName?: string;
  customerOrderNo?: string;
  warehouseId?: number;
  warehouseName?: string;
  netsuiteSalesOrderNumber?: string;
  charges: CodeEntityQuantity[];
}

// Keep these in sync with statuses in the backend.
export enum BOLContainerStatus {
  open, // 0
  loading, // 1
  adminReview, // 2
  managerReview, // 3
  pendingInvoice, // 4
  invoiced, // 5
  readyToPick, // 6
  pickingInProgress, // 7
  readyToLoad, // 8
}

export const CONTAINER_LABELS = [
  'Open',
  'Loading',
  'Admin Review',
  'Manager Review',
  'Pending Invoice',
  'Invoiced',
  'Ready To Pick',
  'Picking In Progress',
  'Ready To Load',
] as const;

export type ContainerStatusLabel = typeof CONTAINER_LABELS[number];

export const CONTAINER_STATUS_COLORS: StatusChipColors<ContainerStatusLabel> = {
  Open: { bg: '#e6e6e6', fg: '#1a1a1a' },
  Loading: { bg: '#30cb40', fg: '#1a1a1a' },
  'Admin Review': { bg: '#1a5e20', fg: '#ffffff' },
  'Manager Review': { bg: '#1a5e20', fg: '#ffffff' },
  'Pending Invoice': { bg: '#1a5e20', fg: '#ffffff' },
  Invoiced: { bg: '#1a5e20', fg: '#ffffff' },
  'Ready To Pick': { bg: '#fac711', fg: '#1a1a1a' },
  'Picking In Progress': { bg: '#fef444', fg: '#1a1a1a' },
  'Ready To Load': { bg: '#80d4fa', fg: '#1a1a1a' },
};

export const ContainerChip = ({
  status,
  ...props
}: { status: BOLContainerStatus } & PaperProps) => (
  <StatusChip
    {...props}
    label={CONTAINER_LABELS[status]}
    colors={CONTAINER_STATUS_COLORS}
    width="11em"
  />
);

export const containerFactory = Factory.define<BOLContainer>(() => ({
  id: faker.datatype.number(),
  code: faker.datatype.hexadecimal({ length: 10 }),
  bolNumber: faker.datatype.number(),
  billOfLadingId: faker.datatype.number(),
  location: faker.datatype.string(),
  status: faker.helpers.arrayElement([
    BOLContainerStatus.open,
    BOLContainerStatus.loading,
    BOLContainerStatus.adminReview,
    BOLContainerStatus.managerReview,
    BOLContainerStatus.pendingInvoice,
    BOLContainerStatus.invoiced,
    BOLContainerStatus.readyToPick,
    BOLContainerStatus.pickingInProgress,
    BOLContainerStatus.readyToLoad,
  ]),
  statusName: faker.datatype.string(),
  billingCompletedDate: undefined,
  billingCycle: undefined,
  loadingCompletedDate: undefined,
  checklistCompletedDate: undefined,
  palletsInPickedLocation: 1,
  loadedPallets: 1,
  totalPallets: 2,
  loadedCartons: 1,
  totalCartons: 2,
  loadedWeight: 800.52,
  totalWeight: 1601.02,
  customerName: faker.datatype.string(),
  customerOrderNo: faker.datatype.string(),
  consigneeName: '',
  admin: undefined,
  checklistResponseIds: [],
  loadType: faker.datatype.string(),
  priority: faker.datatype.number(),
  instructions: faker.datatype.string(),
  vesselName: faker.datatype.string(),
  shipDate: faker.datatype.datetime().toISOString(),
  canExportBol: false,
}));

export const containerWithChargesFactory =
  Factory.define<BOLContainerWithCharges>(() => ({
    id: faker.datatype.number(),
    code: faker.datatype.hexadecimal({ length: 10 }),
    status: faker.helpers.arrayElement([
      BOLContainerStatus.open.valueOf(),
      BOLContainerStatus.loading.valueOf(),
      BOLContainerStatus.adminReview.valueOf(),
      BOLContainerStatus.managerReview.valueOf(),
      BOLContainerStatus.pendingInvoice.valueOf(),
      BOLContainerStatus.invoiced.valueOf(),
    ]),
    statusName: faker.datatype.string(),
    entityType: faker.helpers.arrayElement([
      'BOLContainers.BOLContainer',
      'ASNs.ASN',
    ]),
    entityTypeId: faker.datatype.number(),
    entityTypeNumber: faker.datatype.number(),
    billingCycle: faker.datatype.string(),
    loadingCompletedDate: faker.datatype.datetime().toISOString(),
    customerName: faker.datatype.string(),
    customerOrderNo: faker.datatype.string(),
    warehouseId: faker.datatype.number(),
    warehouseName: faker.datatype.string(),
    netsuiteSalesOrderNumber: faker.datatype.string(),
    charges: [],
  }));

export const eCertFactory = Factory.define<ECert>(() => ({
  id: faker.datatype.number(),
  certificatePublicId: faker.datatype.string(20),
  consignorId: faker.datatype.string(),
  consigneeId: faker.datatype.string(),
  status: ECertStatus.draft,
  bolContainerCodes: faker.random.words(3).split(' '),
  remarks: eCertRemarkFactory.buildList(1),
}));

export const eCertRemarkFactory = Factory.define<ECertRemark>(() => ({
  id: faker.datatype.number(),
  remarkType: faker.random.words(),
  remarkValue: faker.random.words(),
}));
export const isContainerClosed = (containerStatus: BOLContainerStatus) => {
  return (
    containerStatus === BOLContainerStatus.adminReview ||
    containerStatus === BOLContainerStatus.managerReview ||
    containerStatus === BOLContainerStatus.pendingInvoice ||
    containerStatus === BOLContainerStatus.invoiced
  );
};
