import {
  Alert,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import alertSound from '../Sounds/homer_doh.mp3';
import theme from '../theme';
import CloseIcon from '@mui/icons-material/Close';
import { CountdownButton } from './CountdownButton';

export type ModalDialogSeverity = 'success' | 'info' | 'warning' | 'error';
export interface ModalDialogProps {
  severity?: ModalDialogSeverity;
  maxWidth?: Breakpoint;
  title: ReactNode;
  content: ReactNode;
  onClose?: () => void;
  isClosableFromBackdrop?: boolean;
  showCloseButton?: boolean;
  buttonRenderer?: (closeCallback: () => void) => ReactNode;
}

const MODAL_DIALOG_EVENT = 'modalDialogEvent';

export function modalDialog(props: ModalDialogProps) {
  window.dispatchEvent(
    new CustomEvent<ModalDialogProps>(MODAL_DIALOG_EVENT, {
      detail: props,
    })
  );
}

export interface AlertModalDialogProps {
  severity?: ModalDialogSeverity;
  title: ReactNode;
  content: ReactNode;
  onClose?: () => void;
  isClosableFromBackdrop?: boolean;
  showCloseButton?: boolean;
  okButtonLabel?: string;
}

export function alertModalDialog(props: AlertModalDialogProps) {
  modalDialog({
    severity: props?.severity,
    title: props.title,
    content:
      props?.severity != null ? (
        <Alert variant="filled" severity={props?.severity}>
          {props.content}
        </Alert>
      ) : (
        props.content
      ),
    onClose: props.onClose,
    isClosableFromBackdrop: props.isClosableFromBackdrop,
    buttonRenderer: (closeCallback) => (
      <Button
        fullWidth
        role="button"
        onClick={() => closeCallback()}
        data-testid="dismiss-btn"
      >
        {props.okButtonLabel ?? 'Ok'}
      </Button>
    ),
  });
}

export interface ConfirmModalDialogProps {
  onDecline?: () => void;
  onAccept?: () => void | boolean;
  severity?: ModalDialogSeverity;
  title: ReactNode;
  content: ReactNode;
  declineButtonLabel?: string;
  acceptButtonLabel?: string;
  acceptButtonCountdown?: number;
  declineButtonCountdown?: number;
  maxWidth?: Breakpoint;
}

export async function confirmModalDialog(
  props: ConfirmModalDialogProps
): Promise<boolean> {
  return new Promise((resolve) => {
    modalDialog({
      severity: props?.severity,
      title: props.title,
      maxWidth: props.maxWidth,
      content:
        props?.severity != null ? (
          <Alert variant="filled" severity={props?.severity}>
            {props.content}
          </Alert>
        ) : (
          props.content
        ),
      isClosableFromBackdrop: false,
      buttonRenderer: (closeCallback) => (
        <>
          <CountdownButton
            fullWidth
            role="button"
            data-testid="decline-confirm-btn"
            onClick={() => {
              props.onDecline?.();
              resolve(false);
              closeCallback();
            }}
            title={props.declineButtonLabel ?? 'Cancel'}
            countdownSecs={props.declineButtonCountdown ?? 0}
          />

          <CountdownButton
            fullWidth
            role="button"
            variant="contained"
            data-testid="accept-confirm-btn"
            onClick={() => {
              const accepted = props.onAccept?.();
              if (typeof accepted === 'undefined' || accepted) {
                resolve(true);
                closeCallback();
              }
            }}
            title={props.acceptButtonLabel ?? 'Ok'}
            countdownSecs={props.acceptButtonCountdown ?? 0}
          />
        </>
      ),
    });
  });
}

export default function () {
  const [modalDialog, setModalDialog] = useState<ModalDialogProps | null>(null);
  const [open, setOpen] = useState(false);
  const [transitionTimerId, setTransitionTimerId] = useState(0);

  const audio = useMemo(() => new Audio(alertSound), []);

  useEffect(() => {
    const handler = (data: Event) => {
      clearTimeout(transitionTimerId);
      const newModalDialog = (data as CustomEvent<ModalDialogProps>).detail;
      setModalDialog(newModalDialog);
      setOpen(true);
      if (newModalDialog?.severity == 'error') {
        audio.play();
        navigator.vibrate(100);
      }
    };
    window.addEventListener(MODAL_DIALOG_EVENT, handler);

    return () => window.removeEventListener(MODAL_DIALOG_EVENT, handler);
  }, [setModalDialog, transitionTimerId, audio]);

  const handleCloseModalDialog = () => {
    setOpen(false);
    modalDialog?.onClose?.();
    // start a timer and when complete clear the dialog content to prevent a redraw as the dialog close animation runs
    setTransitionTimerId(
      setTimeout(() => {
        setModalDialog(null);
      }, theme.transitions.duration.leavingScreen) as unknown as number
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={modalDialog?.maxWidth ?? 'xs'}
      data-testid="modal-dialog"
      scroll="paper"
      onClose={() =>
        (modalDialog?.isClosableFromBackdrop ?? true) &&
        handleCloseModalDialog()
      }
    >
      <DialogTitle data-testid="modal-dialog-title">
        {modalDialog?.title}
      </DialogTitle>
      {(modalDialog?.showCloseButton ?? false) && (
        <IconButton
          aria-label="close"
          data-testid="modal-close"
          onClick={handleCloseModalDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent data-testid="modal-dialog-content">
        {modalDialog?.content}
      </DialogContent>
      {modalDialog?.buttonRenderer && (
        <DialogActions>
          <Stack direction={'column'} spacing={2} width="100%">
            <Stack
              direction={'row'}
              spacing={2}
              justifyContent={'space-between'}
            >
              {modalDialog?.buttonRenderer(handleCloseModalDialog)}
            </Stack>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}
