import React, { useContext, useEffect } from 'react';
import MobilePage from '../../Components/MobilePage';
import { Stack } from '@mui/material';
import BillOfLadingService from '../../Services/BillOfLadingService';
import { AuthContext } from '../../Providers/AuthProvider';
import AdminReview from './AdminReview';
import useAuthorizedRoutes from '../../Hooks/useAuthorizedRoutes';

export default function () {
  const { user } = useContext(AuthContext);
  const { routeMenuItems } = useAuthorizedRoutes();

  useEffect(() => {
    const bolAdminItem = routeMenuItems.find((m) => m.id == 'BolAdminList');
    if (bolAdminItem) {
      const getCountToleranceFail = async () => {
        const [count] = await BillOfLadingService.countToleranceFailBOLs({
          params: { warehouseId: user?.currentWarehouseId ?? 0 },
        });
        return count ?? '-';
      };
      getCountToleranceFail().then((count) => {
        bolAdminItem.setBadge?.(count);
      });
    }
  }, [routeMenuItems, user]);

  return (
    <MobilePage maxWidth={false}>
      <Stack
        direction="row"
        spacing={2}
        flexWrap="wrap"
        justifyContent="space-around"
      >
        <AdminReview />
      </Stack>
    </MobilePage>
  );
}
