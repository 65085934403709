import { Customer, customerFactory } from './Customer';
import { FAKE_WAREHOUSES, Warehouse } from './Warehouse';
import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';
import { FAKE_ORDER_TYPES } from './BillOfLading';
import { PalletDetails, palletDetailsFactory } from './Pallet';

export interface ASN {
  id: number;
  asnNumber: number;
  status: ASNStatus;
  orderType: number;
  customer: Customer;
  customerOrderNumber: string;
  origin: string;
  warehouseId: number;
  warehouse: Warehouse;
  arrivalTime: string;
  instructions?: string;
  expectedTime: string;
  checklistCompletedDate?: string;
}

export interface ASNWithCounts extends ASN {
  totalPallets?: number;
  verifiedPallets?: number;
  putAwayPallets?: number;
  newPallets?: number;
  notReceivedPallets?: number;
  pallets?: PalletDetails[];
  receivedWeight?: number;
  checklistCompletedDate?: string;
  expectedPallets?: number;
  billingCompletedDate?: string;
}

export interface ASNForCustomer {
  id: number;
  asnNumber: number;
  status: ASNStatus;
  orderType: number;
  customer: Customer;
  customerOrderNumber: string;
  origin: string;
  warehouseId: number;
  warehouse: Warehouse;
  arrivalTime: string;
  instructions?: string;
  expectedTime: string;
  checklistCompletedDate?: string;
}

export interface CartonsByProduct {
  productId: number;
  productCode: string;
  productDescription: string;
  cartonsCount: number;
  cartonsWeight: number;
  palletsCount: number;
}

export interface ASNWithCountsForCustomer extends ASNForCustomer {
  totalPallets?: number;
  totalCartons?: number;
  verifiedPallets?: number;
  putAwayPallets?: number;
  newPallets?: number;
  notReceivedPallets?: number;
  pallets?: PalletDetails[];
  receivedWeight?: number;
  cartonsByProduct?: CartonsByProduct[];
}

export interface UpdateASNRequest {
  asnId: number;
  checklistCompleted?: boolean;
  billingCompleted?: boolean;
}

export enum ASNStatus {
  pending,
  arrived,
  receiving,
  adminReview,
  managerReview,
  pendingInvoice,
  invoiced,
  completed,
}

export const asnFactory = Factory.define<ASN>(() => {
  return {
    id: faker.datatype.number(),
    asnNumber: faker.datatype.number(),
    status: faker.helpers.arrayElement([
      ASNStatus.pending,
      ASNStatus.arrived,
      ASNStatus.receiving,
      ASNStatus.adminReview,
      ASNStatus.managerReview,
      ASNStatus.pendingInvoice,
      ASNStatus.invoiced,
      ASNStatus.completed,
    ]),
    orderType: faker.helpers.arrayElement(FAKE_ORDER_TYPES).id,
    customer: customerFactory.build(),
    customerOrderNumber: faker.datatype.string(),
    origin: faker.datatype.string(),
    warehouseId: FAKE_WAREHOUSES[0].id,
    warehouse: FAKE_WAREHOUSES[0],
    arrivalTime: faker.datatype.datetime().toISOString(),
    instructions: faker.lorem.lines(2),
    expectedTime: faker.datatype.datetime().toString(),
  };
});

export const asnWithCountsFactory = Factory.define<ASNWithCounts>(() => {
  const totalPallets = faker.datatype.number({ min: 2, max: 5 });
  return {
    ...asnFactory.build(),
    pallets: palletDetailsFactory.buildList(totalPallets),
    totalPallets: totalPallets,
    verifiedPallets: faker.datatype.number({ min: 0, max: totalPallets }),
    putAwayPallets: faker.datatype.number({ min: 0, max: totalPallets }),
    newPallets: faker.datatype.number({ min: 0, max: totalPallets }),
    notReceivedPallets: faker.datatype.number({ min: 0, max: totalPallets }),
    expectedPallets: faker.datatype.number({ min: 0, max: totalPallets }),
  };
});

export const asnWithCountsForCustomerFactory =
  Factory.define<ASNWithCountsForCustomer>(() => {
    const totalPallets = faker.datatype.number({ min: 2, max: 5 });
    return {
      ...asnFactory.build(),
      pallets: palletDetailsFactory.buildList(totalPallets),
      totalPallets: totalPallets,
      totalCartons: faker.datatype.number({ min: 2, max: 5 }),
    };
  });

export const asnWithCountsAndCartonsWithProductsForCustomerFactory =
  Factory.define<ASNWithCountsForCustomer>(() => {
    const totalPallets = faker.datatype.number({ min: 2, max: 5 });
    return {
      ...asnFactory.build(),
      pallets: palletDetailsFactory.buildList(totalPallets),
      totalPallets: totalPallets,
      totalCartons: faker.datatype.number({ min: 2, max: 5 }),
      cartonsByProduct: [],
    };
  });

export const ASN_ENTITY_NAME = 'ASNs.ASN';

export const ASN_ENTITY_AUDIT_NAME = `Coolpak.WMS.${ASN_ENTITY_NAME}`;

export const ASN_STATUS_LABELS = [
  'Pending',
  'Arrived',
  'Receiving',
  'Admin Review',
  'Manager Review',
  'Pending Invoice',
  'Invoiced',
  'Completed',
] as const;
