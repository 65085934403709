import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { NetsuiteLog } from '../../Models/NetsuiteLog';

export interface AuditLogItemProps {
  item: NetsuiteLog;
}

export default function ({ item }: AuditLogItemProps) {
  const date = useMemo(
    () => format(new Date(item.date), 'dd/MM/yyyy HH:mm:ss'),
    [item]
  );

  return (
    <CustomAccordion
      disableGutters
      elevation={0}
      square
      TransitionProps={{ unmountOnExit: true }}
      data-testid={'audit-log-item-' + item.date}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, .03)',
          '.MuiAccordionSummary-content': {
            maxWidth: '100%',
          },
        }}
      >
        <Stack spacing={2} direction="row" width="100%" alignItems="center">
          <Typography variant="body2" whiteSpace="nowrap">
            {date}
          </Typography>
          <Box width="70px">
            <Chip
              label={(item?.exceptionMessage ?? '') == '' ? 'Success' : 'Error'}
              size="small"
              color={(item?.exceptionMessage ?? '') == '' ? 'success' : 'error'}
            />
          </Box>
          <Typography
            variant="body2"
            flexGrow={1}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {item.methodName} - {item.exceptionMessage}
          </Typography>
          <Typography variant="subtitle2" whiteSpace="nowrap">
            by: {item.usersName}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer
          component={Paper}
          sx={{ width: 'fit-content', margin: 'auto' }}
        >
          <Table size="small" sx={{ width: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell>Property</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Method Name</TableCell>
                <TableCell>{item.methodName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Parameters</TableCell>
                <TableCell>{JSON.stringify(item.parameters)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Duration (ms)</TableCell>
                <TableCell>{item.duration}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Error</TableCell>
                <TableCell>{item.exceptionMessage}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </CustomAccordion>
  );
}

const CustomAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));
