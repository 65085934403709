import React from 'react';
import MobilePage from '../../Components/MobilePage';
import { Card, CardContent, Grid } from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import ChangePasswordBase from './ChangePasswordBase';

/***
 * Change Password default page
 */
export default function () {
  return (
    <MobilePage showTitle={false} maxWidth={'md'}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardTitle title="Change Password" />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ width: '100%' }}>
            <CardContent>
              <ChangePasswordBase
                fullWidthButtons={false}
                showBackButton={true}
                showLogoutButton={false}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MobilePage>
  );
}
