import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import CardTitle from '../../../Components/CardTitle';
import useApiGet from '../../../Hooks/useApiGet';
import { AuthContext } from '../../../Providers/AuthProvider';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import CustomDateCalendar from '../../../Components/CustomDateCalendar';
import { friendlyDate } from '../../../Lib/utils';
import MobilePage from '../../../Components/MobilePage';
import useLocalStorage from '../../../Hooks/useLocalStorage';
import AsnService from '../../../Services/AsnService';
import { ASNStatus, ASNWithCountsForCustomer } from '../../../Models/ASN';
import ASNStatusChip, { ASN_STATUS_LABELS } from '../../ASNs/ASNStatusChip';
import { Link } from 'react-router-dom';

/**
 * This page shows a list of ASN's for the customer
 */

export default function () {
  const { user } = useContext(AuthContext);
  const [filterSelectedDate, setFilterSelectedDate] =
    useLocalStorage<Date | null>(
      'CustomerPages.InboundOrders.selectedDate',
      null,
      { sessionOnly: true, todayOnly: true }
    );

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    filterSelectedDate || new Date()
  );

  const { data, loading, refresh } = useApiGet(
    AsnService.getAllWithCountsForCustomer,
    {
      params: {
        warehouseId: user?.currentWarehouseId,
        queryDate: selectedDate,
      },
    }
  );

  useEffect(() => {
    if (user?.currentWarehouseId) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentWarehouseId]);

  useMemo(() => {
    if (selectedDate) setFilterSelectedDate(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const columnVisibilityModel = {
    customerName: !user?.currentCustomerId,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ASN',
      minWidth: 50,
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        // If the user is a customer user, the ASN number should be a link to the customer ASN detail page
        user && user.roleNames?.some((r) => r == 'CUSTOMER USER') ? (
          <Link to={`${params.row.asnNumber}`}>{params.row.asnNumber}</Link>
        ) : (
          <Link to={`/asn/${params.row.asnNumber}`}>
            {params.row.asnNumber}
          </Link>
        ),
      valueGetter: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        params.row.asnNumber,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      minWidth: 250,
      maxWidth: 300,
      valueGetter: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        params.row.customer.name,
      renderCell: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        params.row.customer.name,
    },
    {
      field: 'customerOrderNumber',
      headerName: 'Order No',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'origin',
      headerName: 'Origin',
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 50,
      maxWidth: 100,
      valueGetter: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        params.row.warehouse.name,
      renderCell: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        params.row.warehouse.name,
    },
    {
      field: 'totalCartons',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'totalPallets',
      headerName: 'Pallets',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'receivedWeight',
      headerName: 'Weight',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      maxWidth: 300,
      renderCell: (params: GridRenderCellParams<ASNWithCountsForCustomer>) => (
        <ASNStatusChip status={params.row.status as ASNStatus} />
      ),
      valueGetter: (params: GridRenderCellParams<ASNWithCountsForCustomer>) =>
        ASN_STATUS_LABELS[params.row.status],
    },
  ];

  return (
    <MobilePage showTitle={false} maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardTitle
            title={`Inbound Orders on ${friendlyDate(selectedDate)}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4} md={12} sm={12} xl={3}>
              <Card variant="outlined">
                <CardContent>
                  <CustomDateCalendar
                    initialDate={selectedDate}
                    onDateSelected={(date) => {
                      setSelectedDate(date!);
                    }}
                  ></CustomDateCalendar>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <StandardDataGrid
                    testId="data-grid-asnsbydate-table"
                    rows={data?.items || []}
                    loading={loading}
                    columns={columns}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick={true}
                    columnVisibilityModel={columnVisibilityModel}
                    noRowsMessage="No Inbound Orders found"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobilePage>
  );
}
