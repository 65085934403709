import { Product } from '../Models/Product';
import Api, {
  PagedAndSortedRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';

/**
 * The CustomerService provides api endpoints for an Admin to operate on Customers.
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getAllProductNames: ServiceGetMethod<
  { includeSupportedCustomers?: boolean },
  Product[]
> = (options) =>
  Api.get('api/services/app/Product/GetAllProductNames', options);

const getProducts: ServiceGetMethod<
  PagedAndSortedRequest,
  PagedResult<Product>
> = (options) => Api.get('api/services/app/Product/GetAll', options);

const createOrUpdateProduct: ServicePostMethod<EditProductRequest, Product> = (
  data,
  options
) =>
  data.id > 0
    ? Api.put('api/services/app/Product/Update', data, options)
    : Api.post('api/services/app/Product/Create', data, options);

interface EditProductRequest {
  id: number;
  code: string;
  eCertCode?: string;
  description: string;
  eCertDesc?: string;
  hsc?: string;
  unitOfMeasure?: string;
  packageType?: string;
  weight?: number | null;
  packWeight?: number | null;
  palletStandardConfiguration?: number | null;
  billGroup?: string;
  species?: string;
  brand?: string;
  syncStatus?: number;
  eCertExempt?: boolean;
}

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getAllProductNames,
  getProducts,
  createOrUpdateProduct,
};

/**
 *  Models used in this service
 */
