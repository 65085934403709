import { useCallback, useMemo } from 'react';
import { SelectItem, selectLabelsById } from '../Components/Forms/FormField';
import { pagedParams } from '../Services/Api';
import WarehouseService from '../Services/WarehouseService';
import useApiGet from './useApiGet';

/***
 * A convenient hook to consume the cached Warehouses in various formats
 */

export default function () {
  // fetch cached warehouses
  const { data: warehouseData } = useApiGet(WarehouseService.getWarehouses, {
    params: pagedParams,
    cache: true,
  });

  // convert to [id, label]
  const warehouseSelectItems = useMemo(
    () =>
      (warehouseData?.items || []).map<SelectItem>((warehouse) => ({
        id: warehouse.id,
        label: warehouse.name,
      })),
    [warehouseData]
  );

  // convert to [code, label]
  const warehouseCodeSelectItems = useMemo(
    () =>
      (warehouseData?.items || []).map<SelectItem>((warehouse) => ({
        id: warehouse.code,
        label: warehouse.name,
      })),
    [warehouseData]
  );

  // convert to Record<id, name>
  const warehouseNamesById = useMemo(
    () => selectLabelsById(warehouseSelectItems),
    [warehouseSelectItems]
  );

  const getWarehouseName = useCallback(
    /* istanbul ignore next */
    (id: number | null | undefined) => (id ? warehouseNamesById[id] : ''),
    [warehouseNamesById]
  );

  return {
    warehouses: warehouseData?.items,
    warehouseSelectItems,
    warehouseCodeSelectItems,
    warehouseNamesById,
    getWarehouseName,
  };
}
