import React, { useContext, useEffect, useState } from 'react';
import PalletLocationSummaryCard from './PalletLocationSummaryCard';
import { PalletForStaging } from '../../Models/Pallet';
import { Grid } from '@mui/material';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';

const defaultTextColor = 'white';
const backgroundColours = [
  'rgb(0, 136, 209)',
  'rgb(47, 124, 49)',
  'rgb(156, 39, 176)',
  'rgb(211, 47, 47)',
  'rgb(237, 108, 3)',
  'rgb(133, 68, 66)',
  'rgb(17, 167, 160)',
  'rgb(96, 92, 168)',
  'rgb(254, 138, 113)',
  'rgb(23, 162, 184)',
];

interface PalletLocationsSummaryCardProps {
  data: PalletForStaging[];
}

export interface RoomsSummary {
  roomName: string;
  sides: SideSummary[];
}

export interface SideSummary {
  sideName: string;
  palletCount: number;
}

export default function PalletLocationsSummaryCard({
  data,
}: PalletLocationsSummaryCardProps) {
  const [roomsSummary, setRoomsSummary] = useState<RoomsSummary[]>([]);
  const { mobileView } = useContext(ResponsiveContext);

  useEffect(() => {
    const roomsMap = new Map<string, Map<string, number>>();

    // finds all sides in data and if it's the same side, increments by 1
    data.map((pallet) => {
      const [room, side] = pallet.location.split('.');

      if (!roomsMap.has(room)) {
        roomsMap.set(room, new Map<string, number>());
      }

      const sidesMap = roomsMap.get(room)!;

      if (!sidesMap.has(side)) {
        sidesMap.set(side, 0);
      }

      sidesMap.set(side, sidesMap.get(side)! + 1);
    });

    // maps and sorts the sides
    const summary: RoomsSummary[] = Array.from(roomsMap.entries()).map(
      ([roomName, sidesMap]) => {
        const sides: SideSummary[] = Array.from(sidesMap.entries()).map(
          ([sideName, palletCount]) => ({
            sideName,
            palletCount,
          })
        );

        sides.sort((a, b) => {
          if (a.sideName < b.sideName) {
            return -1;
          }
          if (a.sideName > b.sideName) {
            return 1;
          }
          return 0;
        });

        return { roomName, sides };
      }
    );

    setRoomsSummary(
      summary.sort((a, b) => {
        if (a.roomName < b.roomName) {
          return -1;
        }
        if (a.roomName > b.roomName) {
          return 1;
        }
        return 0;
      })
    );
  }, [data]);

  if (!mobileView) {
    return (
      <Grid
        container
        direction="row"
        spacing={1}
        rowGap={1}
        maxWidth={'100%'}
        paddingTop={3}
        paddingBottom={2}
        overflow={'auto'}
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'flex-start',
        }}
      >
        {roomsSummary.map((room, index) => {
          return (
            <PalletLocationSummaryCard
              key={index}
              roomName={room.roomName}
              sides={room.sides}
              backgroundColor={
                backgroundColours[index % backgroundColours.length]
              }
              textColor={defaultTextColor}
              testId={`location-summary-card-${room.roomName}`}
            />
          );
        })}
      </Grid>
    );
  } else {
    return null;
  }
}
