import React, { useEffect } from 'react';
import FormField from '../../Components/Forms/FormField';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import LoadingButton from '@mui/lab/LoadingButton';
import CurrentUserService from '../../Services/CurrentUserService';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from '../../Components/Toast';

/**
 * Reset Password page
 * This will take a usernameOrEmail, token in the querystring
 */
const ResetPasswordConfirm = () => {
  const nav = useNavigate();
  const location = useLocation();

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const usernameOrEmail = queryParams.get('usernameOrEmail') || '';
  const token = queryParams.get('token') || '';

  useEffect(() => {
    if (!usernameOrEmail || !token) {
      nav('/');
    }
  }, [usernameOrEmail, token, nav]);

  const handleCancel = () => {
    // redirect back to the previous page
    window.history.back();
  };

  const success = () => {
    toast('Password reset successfully');
    nav('/');
  };

  const onSubmit = (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    // ensure the new password and confirm password match
    if (form.data.newPassword !== form.data.newPasswordConfirm) {
      toast('Passwords do not match');
      return;
    }

    form.submit();
  };

  const form = useApiForm(
    CurrentUserService.resetPasswordConfirm,
    {
      usernameOrEmail: usernameOrEmail,
      newPassword: '',
      newPasswordConfirm: '',
      token: token,
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess: success,
    }
  );

  return (
    <Container maxWidth="xs">
      <Paper
        sx={{
          p: 2,
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          component="form"
          onSubmit={onSubmit}
          data-testid="reset-password-form"
          noValidate
        >
          <Typography variant="body1" gutterBottom sx={{ paddingBottom: 1 }}>
            To reset your password, please enter your username or email address.
            A email will be sent to you to reset your password.
          </Typography>

          <FormField
            form={form}
            id="newPassword"
            type="password"
            label="New Password"
            required
          />
          {
            <FormField
              form={form}
              id="newPasswordConfirm"
              type="password"
              label="Confirm New Password"
              required
            />
          }

          <Box sx={{ mt: 1 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleCancel}
              disabled={form.processing}
              tabIndex={1}
              data-testid="change-password-back"
              sx={{ mb: 2 }}
            >
              Back
            </Button>

            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={form.processing}
              data-testid="change-password-save"
              tabIndex={3}
            >
              Reset
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPasswordConfirm;
