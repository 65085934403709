import React from 'react';
import useApiGet from '../../Hooks/useApiGet';
import PalletService from '../../Services/PalletService';
import PalletMovement from './PalletMovement';

export interface PalletMovementForUserProps {
  palletId: number | null;
}

export default function ({ palletId }: PalletMovementForUserProps) {
  const { data, loading } = useApiGet(
    PalletService.getPalletMovementByPalletId,
    {
      params: {
        palletId: palletId,
      },
    }
  );

  return <PalletMovement palletMovementHistory={data} loading={loading} />;
}
