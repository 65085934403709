import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import {
  GridFilterModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-premium';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import CardTitle from '../../Components/CardTitle';
import { SelectItem } from '../../Components/Forms/FormField';
import MobilePage from '../../Components/MobilePage';
import useApiGet from '../../Hooks/useApiGet';
import { StockOnHandCarton } from '../../Models/Carton';
import { customerSelectItems } from '../../Models/Customer';
import { Lot } from '../../Models/Lot';
import { AuthContext } from '../../Providers/AuthProvider';
import CartonService from '../../Services/CartonService';
import CustomerService from '../../Services/CustomerService';
import LotService from '../../Services/LotService';
import CartonsDataGrid from './Components/CartonsDataGrid';
import LotsDataGrid from './Components/LotsDataGrid';

export const defaultProductCodeLabel = 'Not set';

function LotCartonLinking() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectItem | null>(
    null
  );

  const [lots, setLots] = useState<Lot[]>([]);
  const initialLotsRef = useRef<Lot[]>([]);

  const { user } = useContext(AuthContext);

  const [cartons, setCartons] = useState<StockOnHandCarton[]>([]);
  const initialStockOnHandCartonsRef = useRef<StockOnHandCarton[]>();
  const [selectedCartons, setSelectedCartons] = useState<
    StockOnHandCarton[] | null
  >(null);

  const initialLotsFilterModel = useMemo(
    () => ({
      items: [
        {
          id: 1,
          field: 'productCode',
          operator: 'equals',
          value: defaultProductCodeLabel,
        },
      ],
    }),
    []
  );
  const [lotsFilterModel, setLotsFilterModel] = useState<GridFilterModel>(
    initialLotsFilterModel
  );

  const initialCartonsFilterModel = useMemo(
    () => ({
      items: [
        {
          id: 1,
          field: 'lot',
          operator: 'equals',
          value: '0',
        },
      ],
    }),
    []
  );
  const [cartonsFilterModel, setCartonsFilterModel] = useState<GridFilterModel>(
    initialCartonsFilterModel
  );

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  // Effects --------------------------------- //

  // Load customers data
  const { data: customers, loading: loadingCustomers } = useApiGet(
    CustomerService.getAllCustomerNames
  );
  const customerItems = useMemo(
    () => customerSelectItems(customers || []),
    [customers]
  );

  // Load lots data
  const {
    data: lotsData,
    loading: lotsLoading,
    refresh: refreshLots,
  } = useApiGet(LotService.getAllLots);
  useMemo(() => {
    if (lotsData) {
      const processedData = lotsData.map((lot) => ({
        ...lot,
        productCode: lot.productCode
          ? lot.productCode
          : defaultProductCodeLabel,
      })) as Lot[];

      setLots(processedData ?? []);
      initialLotsRef.current = processedData ?? [];
    } else {
      setLots([]);
      initialLotsRef.current = [];
    }
  }, [lotsData]);

  // Load stock on hand cartons.
  const {
    data: stockOnHand,
    loading: cartonLoading,
    refresh: refreshCartons,
  } = useApiGet(CartonService.getStockOnHand, {
    params: {
      warehouseId: user?.currentWarehouseId ?? null,
      customerId: selectedCustomer ? (selectedCustomer?.id as number) : 0,
      productId: 0, // all products
      batchNumber: null,
      includeSourceEdNumber: false,
    },
  });
  useEffect(() => {
    if (selectedCustomer) {
      setCartons(stockOnHand ?? []);
      initialStockOnHandCartonsRef.current = stockOnHand;
    } else {
      setCartons([]);
      initialStockOnHandCartonsRef.current = [];
    }
  }, [selectedCustomer, stockOnHand]);

  // Filter lots based on the first selected carton
  useEffect(() => {
    if (selectedCartons && selectedCartons.length > 0) {
      const firstSelectedCarton = selectedCartons[0];
      const productCode = firstSelectedCarton.productCode;
      const batchNumber = firstSelectedCarton.batchNumber;

      setLots((prevState) =>
        prevState.filter(
          (lot) =>
            lot.productCode === productCode &&
            (batchNumber ? lot.productionBatch === batchNumber : true)
        )
      );
      setLotsFilterModel({ items: [] });
    } else {
      // Reset lots to initial data if selectedCartons is empty
      setLots(initialLotsRef.current);
      setLotsFilterModel(initialLotsFilterModel);
    }
  }, [initialLotsFilterModel, selectedCartons]);

  // handlers ----------------------------------------------- //

  const handleOnCartonsSelect = (cartons: StockOnHandCarton[]) => {
    if (cartons.length > 0) {
      setSelectedCartons(cartons);

      setCartons((prevState) => {
        const firstSelectedCarton = cartons[0];

        return prevState.filter(
          (carton) =>
            carton.productCode === firstSelectedCarton.productCode &&
            carton.customerCode === firstSelectedCarton.customerCode &&
            carton.batchNumber === firstSelectedCarton.batchNumber &&
            carton.asn === firstSelectedCarton.asn
        );
      });
    } else {
      setSelectedCartons(null);
      setCartons(initialStockOnHandCartonsRef.current ?? []);
    }
  };

  // Reset stock on hand cartons & lots on successful linking.
  const handleOnSuccessfulLinking = () => {
    refreshCartons();
    setCartonsFilterModel(initialCartonsFilterModel);

    setSelectedCartons(null);
    setRowSelectionModel([]);

    refreshLots();
    setLotsFilterModel(initialLotsFilterModel);
  };

  return (
    <MobilePage showOnDesktop maxWidth={false}>
      <Stack
        direction="row"
        spacing={1}
        sx={{ py: 1, maxWidth: { md: '50%', sm: '100%' } }}
      >
        <Autocomplete
          data-testid={'customer-selector'}
          id="customerId"
          value={selectedCustomer}
          options={customerItems}
          loading={loadingCustomers}
          fullWidth
          size={'small'}
          noOptionsText={'No customer found'}
          renderInput={(params) => <TextField {...params} label="Customer" />}
          onChange={(_, newValue) => {
            setSelectedCustomer(newValue);
          }}
        />
      </Stack>

      <Box sx={{ height: '26vh' }}>
        <CardTitle title="Lots" />
        <LotsDataGrid
          loading={lotsLoading}
          lots={lots}
          filterModel={lotsFilterModel}
          selectedCartons={selectedCartons}
          onSuccessfulLinking={handleOnSuccessfulLinking}
          onSuccessfulLotEdit={refreshLots}
        />
      </Box>

      <Box sx={{ height: '45vh', marginTop: '40px !important' }}>
        <CardTitle title="Link ECert" />
        <CartonsDataGrid
          loading={cartonLoading}
          cartons={cartons}
          filterModel={cartonsFilterModel}
          rowSelectionModel={rowSelectionModel}
          selectedCustomer={selectedCustomer}
          onCartonsSelect={handleOnCartonsSelect}
        />
      </Box>
    </MobilePage>
  );
}

export default LotCartonLinking;
