import {
  Alert,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import MobilePage from '../../Components/MobilePage';
import { toast, toastError } from '../../Components/Toast';
import { confirmModalDialog } from '../../Components/ModalDialog';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import { PalletDetailsForUser } from '../../Components/PalletDetails/PalletDetailsForUser';

export default function () {
  // Used for showing the link barcode form
  const [isLinkBarcodeFormVisible, setIsLinkBarcodeFormVisible] =
    useState(false);

  const [isAlreadyLinked, setIsAlreadyLinked] = useState(false);

  const resetForms = () => {
    getPalletForm.setData('code', '');
    getPalletForm.setErrors({});
    resetPalletData();
    resetLinkBarcodeForm();
  };

  const resetLinkBarcodeForm = () => {
    linkPalletForm.setData('linkedBarcode', '');
    linkPalletForm.setErrors({});
    setIsLinkBarcodeFormVisible(false);
  };

  const resetPalletData = () => {
    linkPalletForm.setData('palletId', 0);
    setIsAlreadyLinked(false);
    resetLinkBarcodeForm();
  };

  //
  // This code adds a new pallet to the database, and links a barcode to that pallet.
  // The barcode is entered by the user via a form, and the pallet ID is retrieved from
  // the database. If the barcode is successfully linked, the form is reset and a toast
  // is displayed.
  const linkPalletForm = useApiForm(
    PalletService.linkPalletBarcode,
    {
      palletId: 0,
      linkedBarcode: '',
    },
    {
      onSuccess: () => {
        toast('New barcode successfully linked.');
        resetForms();
      },
      onError: (error) => {
        toastError(`Linking failed: ${error}`);
      },
    }
  );

  // Fetch the pallet from the server and set the data in the form
  const getPalletForm = useApiForm(
    PalletService.getByCode,
    {
      code: '',
      syncFromBasis: false,
      performPalletOnHandCheck: false,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
        resetPalletData();
      },
      onSuccess: (result) => {
        linkPalletForm.setData('palletId', result.id);

        if (result.linkedBarcode) {
          setIsLinkBarcodeFormVisible(false);
          setIsAlreadyLinked(true);
        } else {
          setIsLinkBarcodeFormVisible(true);
        }
      },
    }
  );

  // Submit the pallet form to fetch from the server
  const fetchPallet = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    resetPalletData();

    getPalletForm.submit();

    return false;
  };

  const onPalletScan = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setIsAlreadyLinked(false);
    getPalletForm.submit();
  };

  const onLinkBarcodeScan = async () => {
    if (linkPalletForm.data.linkedBarcode === getPalletForm.data.code) {
      linkPalletForm.setErrors({
        linkedBarcode: 'The two barcodes cannot be the same',
      });
    } else {
      await confirmLinkBarcode();
    }
  };

  const confirmLinkBarcode = async () => {
    const yes = await confirmModalDialog({
      title: 'This will create a new barcode for this pallet.',
      content: 'Do you want to continue?',
    });
    if (yes) {
      linkPalletForm.submit();
    }
  };

  const hasCurrentPallet = linkPalletForm.data.palletId !== 0;

  return (
    <MobilePage showOnDesktop showTitle title="Link Barcode">
      <Stack spacing={1}>
        <Box component="form" onSubmit={fetchPallet} noValidate>
          <Typography variant="subtitle2" textAlign="center">
            Scan pallet/carton:
          </Typography>
          <Stack>
            {isAlreadyLinked && (
              <Alert variant="filled" severity="warning">
                Pallet is already linked to a barcode.
              </Alert>
            )}
          </Stack>
          <BarcodeScannerFormInput
            label="Source Barcode"
            id="code"
            form={getPalletForm}
            onClear={resetForms}
            onBarcode={onPalletScan}
            disabledBackgroundInput={hasCurrentPallet}
            required
          />
        </Box>
        {isLinkBarcodeFormVisible && (
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Box>
                <Stack>
                  <BarcodeScannerFormInput
                    label="Linked Barcode"
                    id="linkedBarcode"
                    form={linkPalletForm}
                    required
                    disabled={!hasCurrentPallet}
                    onBarcode={onLinkBarcodeScan}
                    hideSearchButton
                  />
                </Stack>
              </Box>
            </CardContent>
          </Card>
        )}
        {hasCurrentPallet && (
          <Stack sx={{ marginTop: 2 }}>
            <PalletDetailsForUser palletId={linkPalletForm.data.palletId} />
          </Stack>
        )}
      </Stack>
    </MobilePage>
  );
}
