import { Box } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CardTitle from '../../Components/CardTitle';
import MobilePage from '../../Components/MobilePage';
// import { confirmModalDialog } from '../../Components/ModalDialog';
// import StandardDataGrid from '../../Components/StandardDataGrid';
// import useApiForm from '../../Hooks/useApiForm';
import useDateFormatter from '../../Hooks/useDateFormatter';
import useBOLOrderTypes from '../../Hooks/useBOLOrderTypes';
// import usePermissions from '../../Hooks/usePermissions';
// import { ASNStatus, ASNWithCounts } from '../../Models/ASN';
import { AuthContext } from '../../Providers/AuthProvider';
import { pagedParams } from '../../Services/Api';
import AsnService from '../../Services/AsnService';
import ASNStatusChip, { ASN_STATUS_LABELS } from './ASNStatusChip';
import StandardDataGrid from '../../Components/StandardDataGrid';
import { ASNStatus, ASNWithCounts } from '../../Models/ASN';
import usePermissions from '../../Hooks/usePermissions';
import { confirmModalDialog } from '../../Components/ModalDialog';
import useApiForm from '../../Hooks/useApiForm';
import { parseISO } from 'date-fns';

export default function () {
  const { user } = useContext(AuthContext);
  const [canUpdateAsn] = usePermissions(['ASN.Update']);
  const formatDate = useDateFormatter();
  const { getBolOrderTypeNameById } = useBOLOrderTypes();
  const [asnRows, setAsnRows] = useState<ASNWithCounts[] | null>(null);
  const [loading, setLoading] = useState(false);

  // Using refresh method from useApiGet didn't work when invoking it twice or many times
  // Haven't found the reason yet, so fetch data manually.
  const getAsnWithCounts = () => {
    setLoading(true);
    AsnService.getAllWithCounts({
      params: {
        ...pagedParams,
        warehouseId: user?.currentWarehouseId,
      },
    }).then((resp) => {
      const [data] = resp;
      if (data) {
        setAsnRows(data);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    if (user?.currentWarehouseId) {
      setAsnRows(null);
    }
  }, [user?.currentWarehouseId]);
  useEffect(() => {
    if (!asnRows) {
      getAsnWithCounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentWarehouseId, asnRows]);
  const markAsReviewedForm = useApiForm(
    AsnService.markAsReviewed,
    {
      asnId: 0,
    },
    {
      onSuccess: () => getAsnWithCounts(),
    }
  );

  const markAsArrivedForm = useApiForm(
    AsnService.markAsArrived,
    {
      asnId: 0,
    },
    {
      onSuccess: () => getAsnWithCounts(),
    }
  );

  const handleMarkAsReviewed = async (asnId: number) => {
    const confirmed = await confirmModalDialog({
      title: 'Complete review of ASN',
      acceptButtonLabel: 'OK',
      content: (
        <Box textAlign={'center'} fontWeight={'normal'}>
          <h3 style={{ fontWeight: 500 }}>
            Are you sure you want to mark this ASN as reviewed?
          </h3>
        </Box>
      ),
    });
    if (confirmed) {
      markAsReviewedForm.setData('asnId', asnId);
      markAsReviewedForm.submit();
    }
  };

  // Handle action for marking the ASN as arrived
  const handleMarkAsArrived = async (asnId: number, asnNumber: number) => {
    const confirmed = await confirmModalDialog({
      title: `Mark ASN ${asnNumber} as Arrived`,
      acceptButtonLabel: 'OK',
      content: (
        <Box textAlign={'center'} fontWeight={'normal'}>
          <h3 style={{ fontWeight: 500 }}>
            Are you sure you want to mark this ASN as arrived?
          </h3>
        </Box>
      ),
    });
    if (confirmed) {
      markAsArrivedForm.setData('asnId', asnId);
      markAsArrivedForm.submit();
    }
  };

  const renderActions = useMemo(
    () => (params: GridRowParams<ASNWithCounts>) => {
      const actions = [];
      if (canUpdateAsn && params.row.status === ASNStatus.adminReview) {
        const markAsReadyBtn = (
          <GridActionsCellItem
            data-testid={`mark-as-reviewed-action-${params.row.asnNumber.toString()}`}
            label={'Mark as Reviewed'}
            onClick={() => handleMarkAsReviewed(params.row.id)}
            showInMenu
          />
        );
        actions.push(markAsReadyBtn);
      }
      if (canUpdateAsn && params.row.status === ASNStatus.pending) {
        // add a cell item for marking the ASN as arrived
        const markAsArrivedBtn = (
          <GridActionsCellItem
            data-testid={`mark-as-arrived-action-${params.row.asnNumber.toString()}`}
            label={'Mark as Arrived'}
            onClick={() =>
              handleMarkAsArrived(params.row.id, params.row.asnNumber)
            }
            showInMenu
          />
        );
        actions.push(markAsArrivedBtn);
      }
      return actions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [canUpdateAsn]
  );

  const columns = useMemo<GridColDef<ASNWithCounts>[]>(
    () => [
      {
        field: 'asnNumber',
        headerName: 'ASN No.',
        width: 95,
        minWidth: 80,
        maxWidth: 200,
        type: 'number',
        align: 'center',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) => (
          <Link to={`/asn/${params.row.asnNumber}`}>
            {params.row.asnNumber}
          </Link>
        ),
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          params.row.asnNumber,
      },
      {
        field: 'expectedTime',
        headerName: 'Expected Date',
        width: 105,
        minWidth: 100,
        maxWidth: 300,
        type: 'date',
        renderCell: (params) => formatDate(params.row.expectedTime),
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          parseISO(params.row.expectedTime),
      },
      {
        field: 'orderType',
        headerName: 'Type',
        width: 80,
        minWidth: 90,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          getBolOrderTypeNameById(params.row.orderType),
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          getBolOrderTypeNameById(params.row.orderType),
      },
      {
        field: 'customer',
        headerName: 'Customer',
        minWidth: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          params.row.customer.name,
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          params.row.customer.name,
      },
      {
        field: 'customerOrderNumber',
        headerName: 'Customer Order #',
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'origin',
        headerName: 'Origin',
        minWidth: 100,
        maxWidth: 300,
      },
      {
        field: 'warehouseId',
        headerName: 'Destination Warehouse',
        minWidth: 70,
        width: 100,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          params.row.warehouse.name,
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          params.row.warehouse.name,
      },
      {
        field: 'expectedPallets',
        headerName: 'Expected',
        minWidth: 70,
        width: 80,
        type: 'number',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.expectedPallets}`,
      },
      {
        field: 'totalPallets',
        headerName: 'Received',
        minWidth: 70,
        width: 80,
        type: 'number',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.totalPallets}`,
      },
      {
        field: 'newPallets',
        headerName: 'New',
        minWidth: 70,
        width: 80,
        type: 'number',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.newPallets ?? 0}`,
      },
      {
        field: 'notReceivedPallets',
        headerName: 'Not Received',
        type: 'number',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.notReceivedPallets}`,
      },
      {
        field: 'putAwayPallets',
        headerName: 'Pallets Put Away',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.putAwayPallets} / ${
            (params.row.verifiedPallets ?? 0) + (params.row.newPallets ?? 0)
          }`,
        align: 'right',
      },
      {
        field: 'receivedWeight',
        headerName: 'Weight',
        type: 'number',
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) =>
          `${params.row.receivedWeight}`,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        maxWidth: 300,
        renderCell: (params: GridRenderCellParams<ASNWithCounts>) => (
          <ASNStatusChip status={params.row.status} />
        ),
        valueGetter: (params: GridRenderCellParams<ASNWithCounts>) =>
          ASN_STATUS_LABELS[params.row.status],
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 80,
        maxWidth: 300,
        headerName: 'Actions',
        getActions: renderActions,
      },
    ],
    [formatDate, getBolOrderTypeNameById, renderActions]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'asnNumber',
      sort: 'desc',
    },
  ];

  return (
    <MobilePage sx={{ position: 'relative' }} maxWidth={false}>
      <Box sx={{ height: '84vh' }}>
        <CardTitle title="ASN List" />
        <StandardDataGrid
          testId="asn-data-grid"
          loading={loading}
          columns={columns}
          rows={asnRows || []}
          getRowId={(row) => row.id}
          initialSortModel={initialSortModel}
          disableRowSelectionOnClick={true}
        />
      </Box>
    </MobilePage>
  );
}
