import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useApiForm from '../../Hooks/useApiForm';
import PalletService from '../../Services/PalletService';
import { useSearchParams } from 'react-router-dom';
import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import { Pallet, PalletForCustomer } from '../../Models/Pallet';
import { PalletDetailsForUser } from '../../Components/PalletDetails/PalletDetailsForUser';

export interface PalletSearchProps {
  barcode: string;
  onGotPalletEnquiryInfo?: (pallet: Pallet | PalletForCustomer) => void;
}

export default function ({
  barcode,
  onGotPalletEnquiryInfo,
}: PalletSearchProps) {
  const [palletId, setPalletId] = useState(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | string>(
    ''
  );
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [pallets, setPallets] = useState<Pallet[]>([]);
  const [searchParams] = useSearchParams();
  const onGotPalletInfo = useCallback(
    (pallet: Pallet) => {
      if (onGotPalletEnquiryInfo != undefined) {
        onGotPalletEnquiryInfo!(pallet);
      }
    },
    [onGotPalletEnquiryInfo]
  );

  const resetForms = () => {
    getPalletForm.setData('code', '');
    setSelectedCustomerId('');
    setPalletId(0);
  };

  const onSelectCustomer = (
    event: SelectChangeEvent<typeof selectedCustomerId>
  ) => {
    const selectedValue = Number(event.target.value) || '';
    setSelectedCustomerId(selectedValue);
  };

  const renderCustomerConfirmation = () => {
    const palletNumbers = new Set(pallets.map((p) => p.code));
    return (
      <Dialog
        disableEscapeKeyDown
        open={confirmationOpen}
        maxWidth={'sm'}
        fullWidth={true}
        data-testid="pallet-search-modal-select-customer"
      >
        <DialogTitle>{`Pallet #${Array.from(palletNumbers).join(
          ' - '
        )}`}</DialogTitle>
        <DialogContent>
          <Box fontWeight={'normal'}>
            <h3 style={{ fontWeight: 500, textAlign: 'center' }}>
              Multiple pallets found! <br /> Please select a customer:
            </h3>
            <FormControl
              fullWidth={true}
              size={'small'}
              margin="normal"
              variant="outlined"
            >
              <InputLabel id="pallet-search-customer-selector-label">
                Select Customer
              </InputLabel>
              <Select
                value={selectedCustomerId}
                required
                data-testid="pallet-search-customer-selector"
                labelId="pallet-search-customer-selector-label"
                label="Select Customer"
                input={<OutlinedInput label="Select Customer" />}
                onChange={onSelectCustomer}
              >
                {pallets.map((r) => (
                  <MenuItem key={r.customer.id} value={r.customer.id}>
                    {r.customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            role="button"
            variant="contained"
            onClick={() => setConfirmationOpen(false)}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            data-testid={'btn-confirm-select-customer'}
            role="button"
            variant="contained"
            disabled={selectedCustomerId == ''}
            onClick={() => {
              const selectedPallet = pallets.find(
                (p) => p.customer.id == selectedCustomerId
              );
              setPalletId(selectedPallet?.id ?? 0);
              setConfirmationOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const getPalletForm = useApiForm(
    PalletService.enquirePallet,
    {
      code: '',
      syncFromBasis: false,
      performPalletOnHandCheck: false,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: async (results) => {
        // if the result contains only 1 pallet, just set it as the found result
        if (results.length == 1) {
          setPalletId(results[0].id);
        } else if (results.length > 1) {
          setPallets(results);
          setConfirmationOpen(true);
        }
      },
      useSearchParams: true,
    }
  );

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    if (searchParams.get('code')) {
      getPalletForm.submit();
    }
    if (barcode != '') {
      getPalletForm.setData('code', barcode);
      getPalletForm.submit();
    }
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPallet = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    getPalletForm.submit();
    return false;
  };

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    getPalletForm.submit();
    resetForms();
  };

  return (
    <Box component="form" onSubmit={fetchPallet} noValidate>
      <Typography variant="subtitle2" textAlign="center">
        Scan pallet/carton:
      </Typography>
      <BarcodeScannerFormInput
        label="Barcode"
        id="code"
        form={getPalletForm}
        onBarcode={onPalletScan}
        onClear={() => resetForms()}
      />
      {palletId !== 0 && (
        <Stack>
          <PalletDetailsForUser
            palletId={palletId}
            onGotPalletDetails={onGotPalletInfo}
          />
        </Stack>
      )}
      {pallets.length > 1 && renderCustomerConfirmation()}
    </Box>
  );
}
