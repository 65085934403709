import { Downloading, LocalShipping } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import { useTheme } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MediaLibrary from '../../Components/MediaLibrary/MediaLibrary';
import MobilePage, { MobilePageTab } from '../../Components/MobilePage';
import PrintAddressLabelForm from '../../Components/Printing/PrintAddressLabelForm';
import usePermissions from '../../Hooks/usePermissions';
import { BillOfLadingWithCountsAndPalletDetails } from '../../Models/BillOfLading';
import { BOLContainer } from '../../Models/BOLContainer';
import { FileEntityType } from '../../Models/WMSFile';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import ECerts from '../ECerts/ECerts';
import BolContainer from './BolContainer';
import BolsPickList from './BolsPickList';
import BolLoadContainerList from './ContainerList/BolLoadContainerList';
import SaveBolDetails from './CreateUpdateBol/SaveBolDetails';
import { toast } from '../../Components/Toast';
/**
 * This is the 'Load' tab for an Operator loading a BoL
 */
export interface BolOperatorLoadProps {
  bol: BillOfLadingWithCountsAndPalletDetails;
  refresh: () => void;
}

export default function ({ bol, refresh }: BolOperatorLoadProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { containerCode } = useParams<{ containerCode: string }>();
  const { mobileView } = useContext(ResponsiveContext);
  const tabLists = mobileView
    ? ['containers', 'picking', 'loading']
    : ['boldetails', 'containers', 'loading', 'ecerts', 'printaddresslabels'];
  type Tab = typeof tabLists[number];
  const [canUploadFiles] = usePermissions(['BOL.Create']);
  const [canViewSoH] = usePermissions(['Pages.StockOnHand']);
  const hash = location.hash.substring(1);
  const [container, setContainer] = useState<BOLContainer | null>(null);
  const [tab, setTab] = useState<Tab>(
    tabLists.includes(hash) ? hash : tabLists[0]
  );

  useEffect(() => {
    const cont =
      (containerCode && bol.containers?.find((c) => c.code == containerCode)) ||
      null;
    setContainer(cont);
  }, [containerCode, bol.containers]);

  // in the BoL view, show photos for each container, grouped with the container number as the header
  // only show this on Desktop
  const mediaLibrary = useMemo(() => {
    const containers = bol.containers || [];

    const containersLabels: string[] = [];
    const checklistResponseEntityIds: number[] = [];
    containers.forEach((container) => {
      container.checklistResponseIds.forEach((crId) => {
        containersLabels.push(`Checklist: ${container.code}`);
        checklistResponseEntityIds.push(crId);
      });
    });

    return (
      canUploadFiles &&
      !mobileView && (
        <MediaLibrary
          entityTypes={[
            'BillOfLadings.BillOfLading',
            ...containers.map<FileEntityType>(
              () => 'BOLContainers.BOLContainer'
            ),
            ...checklistResponseEntityIds.map<FileEntityType>(
              () => 'ChecklistItemResponses.ChecklistItemResponse'
            ),
          ]}
          entityIds={[
            bol.id,
            ...containers.map((c) => c.id),
            ...checklistResponseEntityIds.map((crId) => crId),
          ]}
          labels={[
            `BoL ${bol.bolNumber}`,
            ...containers.map((c) => `Container: ${c.code}`),
            ...containersLabels.map<FileEntityType>(
              (label) => label as FileEntityType
            ),
          ]}
          zipName={`bol-${bol.customerName}-${bol.bolNumber}-photos.zip`}
          allowDelete
          allowUpload={canUploadFiles}
          combineGroups
        />
      )
    );
  }, [bol, mobileView, canUploadFiles]);

  const onTabChange = (tab: Tab) => {
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };

  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'boldetails',
      icon: LocalShipping,
      complete: false,
      hidden: mobileView,
      label: 'Details',
    },
    {
      id: 'containers',
      icon: LocalShipping,
      complete: false,
    },
    {
      id: 'picking',
      icon: Downloading,
      complete: false,
      hidden: mobileView || !canViewSoH,
      label: 'Picking List',
    },
    {
      id: 'ecerts',
      icon: Downloading,
      complete: false,
      hidden: mobileView,
      label: 'ECerts',
    },
    {
      id: 'printaddresslabels',
      icon: Downloading,
      complete: false,
      hidden: mobileView,
      label: 'Labels',
    },
  ];

  return !container ? (
    <MobilePage
      right={mediaLibrary}
      showOnDesktop
      maxWidth={false}
      pageTitle={`BOL ${bol.bolNumber}`}
      pageTitleProps={{
        color: theme.palette.primary.main,
        fontWeight: '500',
        paddingTop: { md: '4px' },
        paddingX: '5px',
      }}
      tabs={tabs}
      tab={tab}
      onTabChange={onTabChange}
    >
      <TabPanel value={!mobileView ? 'boldetails' : ''}>
        <SaveBolDetails
          bol={bol}
          onSaveBolDetails={() => toast('Bill of Lading updated successfully.')}
          detailsTabRefresh={refresh}
        />
      </TabPanel>
      <TabPanel
        value={'containers'}
        sx={mobileView ? { padding: '4px' } : undefined}
      >
        <BolLoadContainerList bol={bol} refresh={refresh} />
      </TabPanel>
      <TabPanel value={!mobileView ? 'picking' : ''}>
        <BolsPickList bol={bol} containerTabRefresh={refresh} />
      </TabPanel>
      <TabPanel value={!mobileView ? 'ecerts' : ''}>
        <ECerts />
      </TabPanel>
      <TabPanel value={!mobileView ? 'printaddresslabels' : ''}>
        <PrintAddressLabelForm bol={bol} />
      </TabPanel>
    </MobilePage>
  ) : (
    <BolContainer container={container!} bol={bol} refresh={refresh} />
  );
}
