import Api, {
  ServicePostMethod,
  ServiceGetMethod,
  ServiceDeleteMethod,
} from './Api';
import {
  BOLContainer,
  BOLContainerStatus,
  BOLContainerWithCharges,
} from '../Models/BOLContainer';
import { ASNStatus } from '../Models/ASN';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const setCompletedDates: ServicePostMethod<
  UpdateContainerRequest,
  BOLContainer
> = (data, options) =>
  Api.put('api/services/app/BOLContainer/SetCompletedDates', data, options);

const getContainersByStatuses: ServiceGetMethod<
  { statuses: BOLContainerStatus[]; warehouseId: number | null | undefined },
  BOLContainer[]
> = (options) =>
  Api.get(`api/services/app/BOLContainer/GetContainersByStatuses`, options);

const getChargesByContainerStatus: ServiceGetMethod<
  {
    warehouseId: number | null | undefined;
    statuses: BOLContainerStatus[];
    asnStatuses: ASNStatus[];
  },
  BOLContainerWithCharges[]
> = (options) =>
  Api.get(
    `api/services/app/BOLContainer/GetChargesByContainerStatuses`,
    options
  );

const updateContainerStatus: ServicePostMethod<
  { containerId: number; status: BOLContainerStatus },
  void
> = (data, options) =>
  Api.put(`api/services/app/BOLContainer/updateContainerStatus`, data, options);

const getContainersForBol: ServiceGetMethod<
  GetContainersByBolRequest,
  BOLContainer[]
> = (options) =>
  Api.get(`api/services/app/BOLContainer/GetContainersByBol`, options);

const addContainerToBol: ServicePostMethod<
  AddContainerToBolRequest,
  BOLContainer
> = (data, options) =>
  Api.post(`api/services/app/BOLContainer/AddContainerToBol`, data, options);

const updateContainerDetails: ServicePostMethod<
  UpdateContainerDetailsRequest,
  BOLContainer
> = (data, options) =>
  Api.put(
    `api/services/app/BOLContainer/UpdateContainerDetails`,
    data,
    options
  );

const updateContainerPriority: ServicePostMethod<
  UpdateContainerDetailsRequest,
  BOLContainer
> = (data, options) =>
  Api.put(
    `api/services/app/BOLContainer/UpdateContainerPriority`,
    data,
    options
  );

const assignCartonsToContainer: ServicePostMethod<
  AssignCartonsToContainerRequest,
  BOLContainer
> = (data, options) =>
  Api.post(
    `api/services/app/BOLContainer/AssignCartonsToContainer`,
    data,
    options
  );

const generateECertXML: ServiceGetMethod<
  { certificatePublicId: string },
  string
> = (options) =>
  Api.get('api/services/app/BOLContainer/GenerateECertXml', options);

const generateCartersNotePdf: ServicePostMethod<
  BOLContainerCartersNotePdfRequest,
  string
> = (data, options) =>
  Api.put(
    'api/services/app/BOLContainer/GenerateCartersNotePdf',
    data,
    options
  );

const generateProofOfDeliveryPdf: ServicePostMethod<
  BOLContainerProofOfDeliveryPdfRequest,
  string
> = (data, options) =>
  Api.put(
    'api/services/app/BOLContainer/GenerateProofOfDeliveryPdf',
    data,
    options
  );

const deleteContainer: ServiceDeleteMethod = (id, options) =>
  Api.deleteById(
    `api/services/app/BOLContainer/DeleteContainer?containerId=${id}`,
    options
  );

const closeContainer: ServicePostMethod<
  { bolContainerId: number },
  BOLContainer
> = (data, options) =>
  Api.put('api/services/app/BOLContainer/CloseContainer', data, options);

// export a default object so we can use FooService.method in our code - which makes it clear what we're getting
export default {
  setCompletedDates,
  getContainersByStatuses,
  getChargesByContainerStatus,
  updateContainerStatus,
  getContainersForBol,
  addContainerToBol,
  updateContainerDetails,
  updateContainerPriority,
  assignCartonsToContainer,
  generateECertXML,
  generateCartersNotePdf,
  generateProofOfDeliveryPdf,
  deleteContainer,
  closeContainer,
};

export interface UpdateContainerRequest {
  id: number;
  billingComplete?: boolean;
  loadingComplete?: boolean;
  checklistComplete?: boolean;
}

export interface GetContainersByBolRequest {
  billOfLadingId: number;
}

export interface AddContainerToBolRequest {
  billOfLadingId: number;
}

export interface UpdateContainerDetailsRequest {
  id: number;
  code?: string;
  type?: string;
  location?: string;
  loadType?: string;
  priority?: number;
}

export interface AssignCartonsToContainerRequest {
  containerId: number;
  softAssign: boolean;
  warehouseId?: number | null | undefined;
  billOfLadingLineId: number;
  stockOnHandPallet: StockOnHandPallet[];
}

export interface StockOnHandPallet {
  PalletId: number;
  ProductCode: number;
  BatchNumber: string;
}

export interface BOLContainerCartersNotePdfRequest {
  bolContainerId: number;
}

export interface BOLContainerProofOfDeliveryPdfRequest {
  bolContainerId: number;
}
