import { Autocomplete, Box, Button, MenuItem, TextField } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import useApiGet from '../../../Hooks/useApiGet';
import { BillOfLadingLine } from '../../../Models/BillOfLadingLine';
import { productSelectItemsByCode } from '../../../Models/Product';
import { requiredTempStatesForBolLine } from '../../../Models/RequiredTempStates';
import { CreateUpdateBolLineRequest } from '../../../Services/BillOfLadingService';
import ProductService from '../../../Services/ProductService';
import UserDefinedCodeService from '../../../Services/UserDefinedCodeService';
import { CODE_GROUPS } from '../../../Models/UserDefinedCode';
import { SelectItem } from '../../../Components/Forms/FormField';

/**
 * Represents a row in the save BOL line grid.
 * Every line needs a unique id to be able to identify it as per MUI grid requirements.
 * The rest of the fields are the same as the CreateBolLineRequest.
 * We prepare array of CreateBolLineGridRow objects to later turn them into CreateBolLineRequest objects.
 */
interface CreateBolLineGridRow extends CreateUpdateBolLineRequest {
  id: number;
}

/**
 * Checks if the provided array of `CreateBolLineGridRow` objects is valid.
 *
 * @param bolLineGridRows - The array of `CreateBolLineGridRow` objects to validate.
 * @returns A boolean indicating whether all rows are valid.
 */
const isValidBolLineGridRows = (
  bolLineGridRows: CreateBolLineGridRow[]
): boolean => {
  if (bolLineGridRows.length === 0) return false;

  const result = bolLineGridRows.every((line) => isValidBolLineGridRow(line));
  return result;
};

/**
 * Checks if a given BolLineGridRow is valid.
 *
 * @param bolLineGridRow - The BolLineGridRow to validate.
 * @returns True if the BolLineGridRow is valid, false otherwise.
 */
const isValidBolLineGridRow = (bolLineGridRow: CreateBolLineGridRow): boolean =>
  bolLineGridRow.productCode !== '' &&
  bolLineGridRow.cartonsRequired > 0 &&
  bolLineGridRow.requiredTempState !== '';

const initializeBolLineGridRow = (): CreateBolLineGridRow => ({
  id: 0,
  bolLineId: 0,
  productCode: null,
  batch: '',
  cartonsRequired: 0,
  weightRequired: '',
  instructions: '',
  highDate: null,
  lowDate: null,
  plantCode: '',
  requiredTempState: '',
  shipMarks: '',
  uom: '',
});

interface Props {
  disableEdit: boolean;
  bolLines: BillOfLadingLine[] | undefined;
  onValidBolLines: (
    bolLines: CreateUpdateBolLineRequest[],
    containsInvalidGridRow: boolean
  ) => void;
}

/**
 * Renders a grid for saving Bol line data.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {boolean} props.disableEdit - Indicates whether editing is disabled.
 * @param {CreateBolLineGridRow[]} props.bolLines - An array of Bol line data.
 * @param {Function} props.onValidBolLines - A callback function to handle valid Bol lines.
 */
function SaveBolLineGrid({ disableEdit, bolLines, onValidBolLines }: Props) {
  const [bolLineGridRows, setBolLineGridRows] = useState<
    CreateBolLineGridRow[]
  >([]);

  const { data: products, loading: loadingProducts } = useApiGet(
    ProductService.getAllProductNames
  );
  const productCodeSelectItems = useMemo(
    () => productSelectItemsByCode(products || []),
    [products]
  );

  const { data: unitOfMeasures } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.UNIT_OF_MEASURE,
      },
    }
  );
  const unitOfMeasureSelectItems = useMemo(
    () =>
      unitOfMeasures?.userDefinedCodes?.map<SelectItem>((x) => {
        return {
          id: x.code,
          label: x.description,
        };
      }) || [],
    [unitOfMeasures]
  );

  /**
   * Handles the valid Bol lines and call onValidBolLines to pass valid BOL lines to parent.
   * Whenever any line of the grid changes, we call this method to notify the parent component.
   *
   * @param bolLineGridRows - An array of CreateBolLineGridRow objects representing the Bol line grid rows.
   */
  const handleOnValidBolLines = useCallback(
    (bolLineGridRows: CreateBolLineGridRow[]) => {
      // map all valid CreateBolLineGridRows to CreateBolLineRequests
      const validBolLines = bolLineGridRows
        .filter((line) => isValidBolLineGridRow(line))
        // ignore the id key as its not in the CreateBolLineRequest.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ id, ...line }) => ({ ...line } as CreateUpdateBolLineRequest));

      onValidBolLines(validBolLines, !isValidBolLineGridRows(bolLineGridRows));
    },
    [onValidBolLines]
  );

  /**
   * Handles the change of a line in the BolLineGrid.
   *
   * @param rowId - The ID of the row being changed.
   * @param field - The field being updated in the row.
   * @param value - The new value for the field.
   */
  const handleLineChange = (
    rowId: number,
    field: keyof CreateUpdateBolLineRequest,
    value: string | number
  ) => {
    const bolLineGridRowsCopy = [...bolLineGridRows];

    const updatedBolLineGridRows = bolLineGridRowsCopy.map((line) =>
      line.id === rowId ? { ...line, [field]: value } : line
    );

    setBolLineGridRows(updatedBolLineGridRows);

    // every time a line is changed, call handleOnValidBolLines to notify the parent component.
    handleOnValidBolLines(updatedBolLineGridRows);
  };

  /**
   * Adds a new BOL line to the BOL line grid.
   */
  const handleAddBolLine = () => {
    const bolLineToAdd = {
      ...initializeBolLineGridRow(),
      id:
        bolLineGridRows
          .map((line) => line.id)
          .reduce((a, b) => Math.max(a, b), 0) + 1,
    } as CreateBolLineGridRow;

    const updatedBolLineGridRows = [...bolLineGridRows, bolLineToAdd];

    setBolLineGridRows(updatedBolLineGridRows);

    // every time a line is added, call handleOnValidBolLines to notify the parent component.
    handleOnValidBolLines(updatedBolLineGridRows);
  };

  /**
   * Handles the deletion of a row in the BolLineGrid.
   *
   * @param id - The ID of the row to be deleted.
   */
  const handleDeleteBolLineGridRow = useCallback(
    (id: number) => {
      const bolLineGridRowsCopy = [...bolLineGridRows];

      const updatedBolLineGridRows = bolLineGridRowsCopy.filter(
        (line) => line.id !== id
      );

      if (updatedBolLineGridRows.length === 0) {
        updatedBolLineGridRows.push(initializeBolLineGridRow());
      }

      setBolLineGridRows(updatedBolLineGridRows);

      handleOnValidBolLines(updatedBolLineGridRows);
    },
    [bolLineGridRows, handleOnValidBolLines]
  );

  /**
   * Renders the actions for each row in the grid.
   *
   * @param params - The parameters for the grid row.
   * @returns An array of actions for the grid row.
   */
  const renderActions = useMemo(
    () => (params: GridRowParams<CreateBolLineGridRow>) => {
      const actions = [];

      const markAsLoadedBtn = (
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteBolLineGridRow(params.row.id)}
          showInMenu
          disabled={disableEdit}
        />
      );
      actions.push(markAsLoadedBtn);
      return actions;
    },
    [disableEdit, handleDeleteBolLineGridRow]
  );

  const columns: GridColDef<CreateBolLineGridRow>[] = [
    {
      field: 'productCode',
      headerName: 'Product',
      minWidth: 300,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) =>
        productCodeSelectItems.length > 0 && (
          <Autocomplete
            disabled={disableEdit}
            data-testid={'product-code-selector'}
            id="product-code-selector"
            options={productCodeSelectItems}
            fullWidth
            size={'small'}
            loading={loadingProducts}
            noOptionsText={'No Product found'}
            renderInput={(params) => <TextField {...params} label="Product" />}
            value={
              productCodeSelectItems.find((x) => x.id === params.value) || null
            }
            onChange={(_, newValue) => {
              handleLineChange(
                params.id as number,
                'productCode',
                newValue?.id || ''
              );
            }}
          />
        ),
    },
    {
      field: 'batch',
      headerName: 'Batch',
      minWidth: 140,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(params.id as number, 'batch', e.target.value)
          }
        />
      ),
    },
    {
      field: 'cartonsRequired',
      headerName: 'Cartons',
      minWidth: 90,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          data-testid={'cartonsRequired'}
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(
              params.id as number,
              'cartonsRequired',
              e.target.value
            )
          }
        />
      ),
    },
    {
      field: 'weightRequired',
      headerName: 'Weight (KG)',
      minWidth: 140,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(
              params.id as number,
              'weightRequired',
              e.target.value
            )
          }
        />
      ),
    },
    {
      field: 'instructions',
      headerName: 'Instructions',
      minWidth: 170,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(
              params.id as number,
              'instructions',
              e.target.value
            )
          }
        />
      ),
    },
    {
      field: 'lowDate',
      headerName: 'Low Date',
      minWidth: 170,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <DatePicker
          disabled={disableEdit}
          value={params.value ? parseISO(params.value) : null}
          slotProps={{ textField: { size: 'small' } }}
          onChange={(value) => {
            if (value) {
              handleLineChange(
                params.id as number,
                'lowDate',
                format(value, 'yyyy-MM-dd')
              );
            }
          }}
        />
      ),
    },
    {
      field: 'highDate',
      headerName: 'High Date',
      minWidth: 170,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <DatePicker
          disabled={disableEdit}
          value={params.value ? parseISO(params.value) : null}
          slotProps={{ textField: { size: 'small' } }}
          onChange={(value) => {
            if (value) {
              handleLineChange(
                params.id as number,
                'highDate',
                format(value, 'yyyy-MM-dd')
              );
            }
          }}
        />
      ),
    },
    {
      field: 'plantCode',
      headerName: 'Plant Code',
      headerClassName: 'word-wrap-column-header',
      minWidth: 120,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(params.id as number, 'plantCode', e.target.value)
          }
        />
      ),
    },
    {
      field: 'requiredTempState',
      headerName: 'Required Temp State',
      headerClassName: 'word-wrap-column-header',
      minWidth: 120,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          data-testid={'requiredTempState'}
          role="textbox"
          fullWidth
          size="small"
          select
          disabled={disableEdit}
          value={
            requiredTempStatesForBolLine.find((x) => x.value === params.value)
              ?.value || ''
          }
          onChange={(e) =>
            handleLineChange(
              params.id as number,
              'requiredTempState',
              e.target.value
            )
          }
        >
          {requiredTempStatesForBolLine.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: 'uom',
      headerName: 'UOM',
      minWidth: 70,
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          data-testid={'uom'}
          fullWidth
          size="small"
          select
          disabled={disableEdit}
          value={
            unitOfMeasureSelectItems.find((x) => x.id === params.value)?.id ||
            ''
          }
          onChange={(e) =>
            handleLineChange(params.id as number, 'uom', e.target.value)
          }
        >
          {unitOfMeasureSelectItems.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      ),
    },
    {
      field: 'shipMarks',
      headerName: 'Shipping Marks',
      headerClassName: 'word-wrap-column-header',
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params: GridRenderCellParams<CreateBolLineGridRow>) => (
        <TextField
          disabled={disableEdit}
          fullWidth
          value={params.value}
          size="small"
          onChange={(e) =>
            handleLineChange(params.id as number, 'shipMarks', e.target.value)
          }
        />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 70,
      getActions: renderActions,
    },
  ];

  useEffect(() => {
    handleOnValidBolLines(bolLineGridRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bolLines) {
      setBolLineGridRows(
        bolLines.map((line) => ({
          id: line.id,
          bolLineId: line.id,
          productCode: line.basisProductId.toString(),
          batch: line.batch,
          cartonsRequired: line.cartonsRequired,
          weightRequired: line.weightRequired,
          instructions: line.instructions,
          highDate: line.highDate || null,
          lowDate: line.lowDate || null,
          plantCode: line.plant || '',
          requiredTempState: line.requiredTempState,
          shipMarks: line.shipMarks || '',
          uom: line.uom || '',
        }))
      );
    } else {
      setBolLineGridRows([initializeBolLineGridRow()]);
    }
  }, [bolLines]);

  return (
    <Box
      sx={{
        '& .word-wrap-column-header .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          lineHeight: '1.3',
          textAlign: 'center',
        },
      }}
    >
      <Button
        variant="contained"
        color="success"
        disabled={!isValidBolLineGridRows(bolLineGridRows) || disableEdit}
        onClick={handleAddBolLine}
        sx={{ marginBottom: 1 }}
      >
        Add Bol Line
      </Button>
      <StandardDataGrid
        columns={columns}
        rows={bolLineGridRows}
        hideFooter={true}
        hideToolbar={true}
        noRowsMessage="Please add a BOL Line."
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        getRowId={(row) => row.id}
      />
    </Box>
  );
}

export default SaveBolLineGrid;
