import { Box, Grid, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useApiForm from '../../../Hooks/useApiForm';
import PalletService from '../../../Services/PalletService';
import { PalletForCustomer } from '../../../Models/Pallet';
import FormField from '../../../Components/Forms/FormField';
import { PalletDetailsForCustomer } from './PalletDetailsForCustomer';
import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';
import CardTitle from '../../../Components/CardTitle';

export interface PalletSearchProps {
  palletCode: string;
  onGotPalletEnquiryInfo?: (pallet: PalletForCustomer) => void;
}

export default function ({
  palletCode,
  onGotPalletEnquiryInfo,
}: PalletSearchProps) {
  const [palletId, setPalletId] = useState(0);
  const onGotPalletInfo = useCallback(
    (pallet: PalletForCustomer) => {
      if (onGotPalletEnquiryInfo != undefined) {
        onGotPalletEnquiryInfo!(pallet);
      }
    },
    [onGotPalletEnquiryInfo]
  );

  const getPalletForm = useApiForm(
    PalletService.getByCodeForCustomer,
    {
      code: '',
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result) => {
        setPalletId(result.id);
      },
    }
  );

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    if (palletCode != '') {
      getPalletForm.setData('code', palletCode);
      getPalletForm.submit();
    }
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPallet = (e: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    getPalletForm.submit();
    return false;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CardTitle sx={{ paddingTop: 1 }} title={`Search Pallet/Carton`} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box component="form" onSubmit={fetchPallet} noValidate>
              <Stack direction="column" alignItems="stretch">
                <Stack direction="row" gap={1} alignItems="flex-start">
                  <FormField
                    form={getPalletForm}
                    id="code"
                    type="text"
                    label="Pallet/Carton Code"
                    required
                  />

                  <LoadingButton
                    loading={getPalletForm.processing}
                    variant="contained"
                    size="large"
                    data-testid="search-palletcode-button"
                    aria-label="Search"
                    sx={{ mt: 3 }}
                    type="submit"
                  >
                    <Search />
                  </LoadingButton>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {palletId !== 0 && (
              <Stack sx={{ paddingTop: 1 }}>
                <PalletDetailsForCustomer
                  palletId={palletId}
                  onGotPalletDetails={onGotPalletInfo}
                ></PalletDetailsForCustomer>
              </Stack>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
