import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import SaveBolDetails from '../../CreateUpdateBol/SaveBolDetails';
import { CreateUpdateBolResponse } from '../../../../Services/BillOfLadingService';
import { confirmModalDialog } from '../../../../Components/ModalDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  onSaveBolDetails: (res: CreateUpdateBolResponse) => void;
}

function SaveBolDialog({ open, onClose, onSaveBolDetails }: Props) {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>Create BOL</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={async () => {
          await confirmModalDialog({
            title: 'Cancel Edit',
            content: 'Are you sure? All unsaved changes will be lost.',
            onAccept: () => {
              onClose();
            },
          });
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <SaveBolDetails onSaveBolDetails={onSaveBolDetails} />
      </DialogContent>
    </Dialog>
  );
}

export default SaveBolDialog;
