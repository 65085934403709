import React, { useEffect, useState } from 'react';
import MobilePage, { MobilePageTab } from '../../Components/MobilePage';
import InfoIcon from '@mui/icons-material/Info';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DollarIcon from '@mui/icons-material/AttachMoney';
import ForkliftIcon from '../../Icons/Forklift';
import { TabPanel } from '@mui/lab';
import ASNInstructions from './ASNInstructions';
import useApiGet from '../../Hooks/useApiGet';
import AsnService from '../../Services/AsnService';
import { Button, CircularProgress } from '@mui/material';
import ASNReceive from './ASNReceive';
import EntityBilling from '../../Components/Billing/EntityBilling';
import usePermissions from '../../Hooks/usePermissions';
import { FactCheckOutlined } from '@mui/icons-material';
import ASNChecklist from './ASNChecklist';
import { ASN } from '../../Models/ASN';
import { confirmModalDialog } from '../../Components/ModalDialog';
import useApiForm from '../../Hooks/useApiForm';

const tabLists = ['instructions', 'receive', 'billing', 'checklist'];
const defaultTab = 'receive';
type Tab = typeof tabLists[number];
export const ASN_ENTITY_NAME = 'ASNs.ASN';

export default function () {
  const { asnNumber } = useParams<{ asnNumber: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  // remove the hash key
  const hash = location.hash.substring(1);
  const [canEditBilling] = usePermissions(['ASN.Billing.Edit']);
  const [canEditFullBilling] = usePermissions(['ASN.Billing.FullEdit']);
  const [isBillingCompleted, setIsBillingComplete] = useState(true);
  const {
    data: asn,
    loading,
    refresh,
  } = useApiGet(AsnService.getASNDetail, {
    params: {
      asnNumber: asnNumber!,
    },
    onSuccess: (result) => {
      setIsBillingComplete(!!result.billingCompletedDate);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (asn) {
      const tab = tabLists.includes(hash)
        ? hash
        : asn?.instructions?.length
        ? 'instructions'
        : defaultTab;
      setTab(tab);
    }
  }, [asn, hash]);
  // if the url contains a #tab we set the default tab to it
  const [tab, setTab] = useState<Tab>(
    tabLists.includes(hash)
      ? hash
      : asn?.instructions?.length
      ? 'instructions'
      : defaultTab
  );

  const onTabChange = (tab: Tab) => {
    // add the hash to the url, so we can redirect to the tab if needed
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };
  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'instructions',
      icon: InfoIcon,
      complete: false,
    },
    {
      id: 'receive',
      icon: ForkliftIcon,
      complete: false,
    },
    {
      id: 'billing',
      icon: DollarIcon,
      complete: !!asn?.billingCompletedDate,
    },
    {
      id: 'checklist',
      icon: FactCheckOutlined,
      complete: !!asn?.checklistCompletedDate,
    },
  ];
  const completeBillingForm = useApiForm(
    AsnService.setCompletedDates,
    {
      asnId: 0,
      billingCompleted: false,
    },
    {
      onSuccess: () => {
        setIsBillingComplete(true);
        refresh();
      },
    }
  );
  const closeOffBilling = async () => {
    const confirmed = await confirmModalDialog({
      title: 'Complete Billing',
      content: `Are you sure you want to close off billing for this ASN? ${
        canEditBilling ? 'You will be unable to make further changes.' : ''
      }`,
    });
    if (confirmed) {
      completeBillingForm.setData('asnId', asn!.id);
      completeBillingForm.setData('billingCompleted', true);
      completeBillingForm.submit();
    }
  };
  return (
    <MobilePage
      maxWidth={false}
      tabs={tabs}
      tab={tab}
      onTabChange={onTabChange}
    >
      {loading && !asn && <CircularProgress></CircularProgress>}
      <TabPanel value="instructions" sx={{ padding: 0 }}>
        <ASNInstructions instructionContent={asn?.instructions ?? ''} />
      </TabPanel>
      <TabPanel value="receive" sx={{ padding: 0 }}>
        <ASNReceive asn={asn} asnRefresh={refresh} />
      </TabPanel>
      <TabPanel value="billing" sx={{ padding: 0 }}>
        {asn && (
          <>
            <EntityBilling
              entity={asn}
              customerId={asn.customer.id}
              canEditBilling={canEditBilling}
              canEditFullBilling={canEditFullBilling}
            />
            <Button
              variant="outlined"
              data-testid={'billing-complete-button'}
              onClick={() => closeOffBilling()}
              // if they have the 'BOL.Billing.FullEdit' permission and the billing is not yet complete
              // if they have the 'BOL.Billing.Edit' permission and the billing is not yet complete
              // otherwise, if no permissions, disable the button
              disabled={
                isBillingCompleted || !(canEditBilling || canEditFullBilling)
              }
            >
              {isBillingCompleted ? 'Billing Completed' : 'Complete Billing'}
            </Button>
          </>
        )}
      </TabPanel>
      <TabPanel value="checklist" sx={{ padding: 0 }}>
        {asn && <ASNChecklist asn={asn as ASN} refresh={refresh} />}
      </TabPanel>
    </MobilePage>
  );
}
