import React from 'react';
import MobilePage from '../../Components/MobilePage';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import CardTitle from '../../Components/CardTitle';

export default function () {
  return (
    <MobilePage showTitle={false} maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardTitle
            title={`WELCOME TO COOLTRAK from COOLPAK COOLSTORES LTD`}
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ height: '100%' }}>
            <CardContent
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack spacing={3}>
                <Typography variant="body1">
                  Our Cooltrak site will provide you with real-time inventory
                  reports showing your current stock levels, product location at
                  our stores and the progressive status of currently loading or
                  receiving orders.
                </Typography>

                <Typography variant="body1">
                  Reviews and improvements to this valuable inventory tool are
                  ongoing and we welcome your enquiries and suggestions.
                </Typography>

                <Typography variant="body1">
                  Please contact us on 03 347 9469 should you experience any
                  difficulties or require any assistance.
                </Typography>

                <Typography alignSelf="center">
                  <img
                    src="/coolpak-office.png"
                    alt="Coolpak"
                    width="700"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      borderRadius: '6px',
                    }}
                  />
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MobilePage>
  );
}
