import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import CardTitle from '../../../Components/CardTitle';
import useApiGet from '../../../Hooks/useApiGet';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import MobilePage from '../../../Components/MobilePage';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import PalletService from '../../../Services/PalletService';
import { tryFormatDateStr } from '../../../Lib/utils';
import AsnService from '../../../Services/AsnService';
import { PalletByProductASNForCustomer } from '../../../Models/Pallet';

export default function () {
  // The asnNumber is passed in from the router
  const { asnNumber } = useParams<{ asnNumber: string }>();
  const [searchParams] = useSearchParams();

  // Setup the two filters, products and containers based on the querystring parameters
  const [productFilter, setProductFilter] = React.useState<string | null>(
    searchParams.get('productId')
  );

  // Two API calls, one for the BOL details and one for the specific pallets based on the filters
  const { data } = useApiGet(PalletService.getPalletsByASNProduct, {
    params: {
      asnNumber: asnNumber || '',
      productId: productFilter || '',
    },
  });

  const { data: asnData } = useApiGet(AsnService.getASNDetailForCustomer, {
    params: {
      asnNumber: asnNumber || '',
    },
  });

  // Filter arrays used to populate the dropdowns for products and containers
  const dropdownFilterProducts = useMemo(() => {
    const products =
      asnData?.cartonsByProduct?.map((item) => {
        return { productCode: item.productCode, productId: item.productId };
      }) || [];
    return {
      products: Array.from(new Set(products)),
    };
  }, [asnData]);

  // Data grid columns
  const palletColumns: GridColDef<PalletByProductASNForCustomer>[] = [
    {
      field: 'palletCode',
      headerName: 'Pallet Code',
      minWidth: 50,
      maxWidth: 150,
      renderCell: (
        params: GridRenderCellParams<PalletByProductASNForCustomer>
      ) => <Link to={`${params.row.palletCode}`}>{params.row.palletCode}</Link>,
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      minWidth: 250,
      maxWidth: 350,
    },
    {
      field: 'qty',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'verifiedBy',
      headerName: 'Verified By',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'verifiedDateTime',
      headerName: 'Verified Date',
      minWidth: 100,
      maxWidth: 150,
      renderCell: (params) =>
        tryFormatDateStr(params.row.verifiedDateTime, 'dd/MM/yyyy'),
    },
  ];

  return (
    <MobilePage
      showTitle={false}
      maxWidth={false}
      showOnDesktop
      title={`Pallets for inbound order on ${asnNumber}`}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!data && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}

          {data && (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardTitle title="Pallets" />

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">
                          Pallets Expected
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography variant="subtitle1">
                          {asnData?.totalPallets}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">
                          Pallets Received
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography variant="subtitle1">
                          {asnData?.verifiedPallets}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">
                          Pallets Not Received
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography variant="subtitle1">
                          {asnData?.notReceivedPallets}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">Pallets New</Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography variant="subtitle1">
                          {asnData?.newPallets}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} md={2}>
                        <Typography variant="subtitle1">
                          Pallets Put Away
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <Typography variant="subtitle1">
                          {asnData?.putAwayPallets} /{' '}
                          {(asnData?.verifiedPallets ?? 0) +
                            (asnData?.newPallets ?? 0)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {dropdownFilterProducts.products.length > 1 && (
                      <Grid container spacing={2}>
                        {dropdownFilterProducts.products.length > 1 && (
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <h3>Products</h3>
                              <Select
                                displayEmpty
                                data-testid="product-filter"
                                onChange={(e) =>
                                  setProductFilter(e.target.value as string)
                                }
                                value={productFilter ?? ''}
                                defaultValue={''}
                              >
                                <MenuItem value="">All</MenuItem>
                                {dropdownFilterProducts.products.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={'product-' + index}
                                      value={option.productId}
                                    >
                                      {option.productCode}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    <Box sx={{ minHeight: '20vh', paddingTop: '10px' }}>
                      <StandardDataGrid
                        testId="data-grid-pallets-table"
                        rows={data || []}
                        columns={palletColumns}
                        getRowId={(row) => row.palletId}
                        disableRowSelectionOnClick={true}
                        noRowsMessage="No pallets found"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MobilePage>
  );
}
