import React, { useContext, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import {
  Badge,
  Box,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Providers/AuthProvider';
import { ExpandLess, ExpandMore, Logout } from '@mui/icons-material';
import useAuthorizedRoutes from '../../Hooks/useAuthorizedRoutes';
import { MenuGroup, MenuRoute } from '../../routes';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import usePwaButton from '../../Hooks/usePwaButton';

export const drawerWidth = 240;

interface AppDrawerProps {
  mobile?: boolean;
  open?: boolean;
  onClose?: () => void;
}

export default function ({
  mobile = false,
  open = true,
  onClose,
}: AppDrawerProps) {
  const authContext = useContext(AuthContext);
  const { mobileView } = useContext(ResponsiveContext);
  const { routeMenuItems, groups } = useAuthorizedRoutes();
  const pwa = usePwaButton();

  // last digit of version will always be zero, so remove it
  const displayVersion =
    authContext.application?.version.split('.').slice(0, 3).join('.') ?? '';

  return (
    <Box
      component="nav"
      sx={{ width: drawerWidth, flexShrink: 0 }}
      data-testid="drawer-nav"
    >
      <Drawer
        variant={mobile ? 'temporary' : 'permanent'}
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {!mobileView && (
          <>
            <Divider />
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: [1],
              }}
            >
              <img
                src="/coolpak.png"
                alt="Coolpak Logo"
                title="Coolpak Logo"
                height={'34px'}
              />
            </Toolbar>
          </>
        )}
        {authContext.user && (
          <>
            <List component="nav" sx={{ flexGrow: 1 }}>
              {routeMenuItems.map(
                (route) =>
                  !(mobileView && route.hideOnMobile) && (
                    <MenuItem route={route} key={route.path} />
                  )
              )}
              {groups.map(
                (group) =>
                  !(mobileView && group.hideOnMobile) && (
                    <MenuGroupItem group={group} key={group.name} />
                  )
              )}
            </List>
            <List>
              {pwa.show && (
                <ListItemButton onClick={pwa.onClick}>
                  <ListItemIcon>
                    <pwa.Icon />
                  </ListItemIcon>
                  <ListItemText primary="Install" />
                </ListItemButton>
              )}
              <ListItemButton onClick={() => authContext.logout()}>
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </List>
          </>
        )}
        <Box textAlign="right" p={1}>
          <Typography variant="caption">Version {displayVersion}</Typography>
        </Box>
      </Drawer>
    </Box>
  );
}

const MenuItem = ({ route }: { route: MenuRoute }) => {
  const [badge, setBadge] = useState(route.badge ?? '');
  route.setBadge = setBadge;
  return (
    <ListItemButton
      component={Link}
      to={route.path.split('/:')[0]}
      data-testid={route.testId}
    >
      <ListItemIcon>
        <route.Icon />
      </ListItemIcon>
      <Badge
        data-testid={`menu-badge-content-${route.Component.name}`}
        badgeContent={badge ?? ''}
        color="error"
        max={99}
        invisible={!badge || badge.toString().length === 0}
      >
        <ListItemText primary={route.title} />
      </Badge>
    </ListItemButton>
  );
};

const MenuGroupItem = ({ group }: { group: MenuGroup }) => {
  const [open, setOpen] = useState(group.open ?? false);
  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <group.Icon />
        </ListItemIcon>
        <ListItemText primary={group.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ pt: 0, pl: 2 }}>
          {group.items.map((route) => (
            <MenuItem route={route} key={route.path} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
