import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MobilePage from '../../Components/MobilePage';
import useApiForm from '../../Hooks/useApiForm';
import { Pallet } from '../../Models/Pallet';
import { outputError } from '../../Services/Api';
import PalletService from '../../Services/PalletService';
import CartonCards from './Components/CartonCards';
import SourcePalletForm from './Components/SourcePalletForm';
import TargetPalletForm from './Components/TargetPalletForm';
import { alertModalDialog } from '../../Components/ModalDialog';

interface SplitDetails {
  sourcePallet: Pallet | null;
  cartonToSplitId: number;
  splitQty: number;
  targetPalletBarcode: string;
}

const initialState: SplitDetails = {
  sourcePallet: null,
  cartonToSplitId: 0,
  splitQty: 0,
  targetPalletBarcode: '',
};

export default function SplitPallet() {
  const [splitDetails, setSplitDetails] = useState<SplitDetails>(initialState);

  const navigate = useNavigate();

  const splitPalletForm = useApiForm(
    PalletService.splitPallet,
    {
      sourcePalletId: splitDetails?.sourcePallet?.id ?? 0,
      sourceCartonId: splitDetails?.cartonToSplitId ?? 0,
      splitQty: splitDetails?.splitQty ?? 0,
      targetPalletBarcode: splitDetails?.targetPalletBarcode ?? '',
      isDamaged: false,
    },
    {
      onError: (_, error) => {
        outputError(error);
        splitPalletForm.setData('targetPalletBarcode', '');
      },
      onSuccess: async () => {
        setSplitDetails(initialState);
        splitPalletForm.reset();

        alertModalDialog({
          title: 'Success',
          content: 'Pallet split successfully',
        });
      },
    }
  );

  return (
    <MobilePage
      showOnDesktop
      title="Split Pallet"
      onBack={() => {
        navigate(-1);
      }}
    >
      <Stack spacing={1}>
        {(splitDetails?.sourcePallet === null ||
          splitDetails?.splitQty === 0) && (
          <SourcePalletForm
            onSuccess={(pallet) => {
              setSplitDetails((prevState) => ({
                ...prevState,
                sourcePallet: pallet,
              }));
              splitPalletForm.setData('sourcePalletId', pallet.id);
            }}
          />
        )}

        {splitDetails &&
          splitDetails.sourcePallet &&
          splitDetails.splitQty > 0 && (
            <TargetPalletForm
              splitPalletForm={splitPalletForm}
              onSuccess={(code) => {
                setSplitDetails((prevState) => ({
                  ...prevState,
                  targetPalletBarcode: code,
                }));

                splitPalletForm.setData('targetPalletBarcode', code);
                splitPalletForm.submit();
              }}
            />
          )}

        {splitDetails && splitDetails.sourcePallet && (
          <CartonCards
            sourcePallet={splitDetails.sourcePallet}
            selectedCartonId={splitDetails?.cartonToSplitId ?? 0}
            selectedCartonQty={splitDetails?.splitQty ?? 0}
            onSplitQtyChange={(carton, qty) => {
              setSplitDetails((prevState) => ({
                ...prevState,
                cartonToSplitId: carton.id,
                splitQty: qty,
              }));

              splitPalletForm.setData('sourceCartonId', carton.id);
              splitPalletForm.setData('splitQty', qty);
            }}
          />
        )}
      </Stack>
    </MobilePage>
  );
}
