import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import useApiForm from '../../Hooks/useApiForm';
import ProductService from '../../Services/ProductService';
import FormField, { SelectItem } from '../../Components/Forms/FormField';
import { Product } from '../../Models/Product';
import { UserDefinedCodeGroup } from '../../Models/UserDefinedCode';

interface Props {
  product: Product | null;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  unitOfMeasures: UserDefinedCodeGroup | undefined;
  packageTypes: UserDefinedCodeGroup | undefined;
  species: UserDefinedCodeGroup | undefined;
  billGroups: UserDefinedCodeGroup | undefined;
}

function EditProduct({
  product,
  open,
  onClose,
  onSuccess,
  unitOfMeasures,
  packageTypes,
  species,
  billGroups,
}: Props) {
  const [value, setValue] = React.useState(
    product?.eCertExempt == true ? 'Yes' : 'No'
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setData('eCertExempt', event.target.value == 'Yes');
    setValue((event.target as HTMLInputElement).value);
  };

  const form = useApiForm(
    ProductService.createOrUpdateProduct,
    {
      id: product?.id || 0,
      code: product?.code || '',
      eCertCode: product?.eCertCode || '',
      description: product?.description || '',
      eCertDesc: product?.eCertDesc || '',
      hsc: product?.hsc || '',
      unitOfMeasure: product?.unitOfMeasure || '',
      packageType: product?.packageType || '',
      weight: product?.weight || null,
      packWeight: product?.packWeight || null,
      palletStandardConfiguration: product?.palletStandardConfiguration || null,
      billGroup: product?.billGroup || '',
      species: product?.species || '',
      brand: product?.brand || '',
      syncStatus: product?.syncStatus || 3,
      eCertExempt: false,
    },
    { onSuccess }
  );

  return (
    <Dialog open={open}>
      <Box
        component="form"
        onSubmit={form.submit}
        data-testid="edit-edit-form"
        noValidate
      >
        <DialogTitle>
          {product ? 'Update Product' : 'Create Product'}
        </DialogTitle>
        <DialogContent>
          <FormField
            form={form}
            id="code"
            type="text"
            label="Code"
            required
            inputProps={{ 'data-lpignore': true }}
            disabled={form.data.id > 0}
          />
          <FormField
            form={form}
            id="description"
            type="text"
            label="Description"
            required
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="eCertDesc"
            type="text"
            label="ECert Description"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="hsc"
            type="text"
            label="HSC"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="unitOfMeasure"
            type="select"
            label="Unit Of Measure"
            options={(
              unitOfMeasures?.userDefinedCodes?.sort(function (a, b) {
                if (a.description < b.description) {
                  return -1;
                }
                if (a.description > b.description) {
                  return 1;
                }
                return 0;
              }) || []
            ).map<SelectItem>((p) => {
              return {
                id: p.code,
                label: p.description,
              };
            })}
          />
          <FormField
            form={form}
            id="packageType"
            type="select"
            label="Package Type"
            inputProps={{ 'data-lpignore': true }}
            options={(
              packageTypes?.userDefinedCodes?.sort(function (a, b) {
                if (a.description < b.description) {
                  return -1;
                }
                if (a.description > b.description) {
                  return 1;
                }
                return 0;
              }) || []
            ).map<SelectItem>((p) => {
              return {
                id: p.code,
                label: p.description,
              };
            })}
          />
          <FormField
            form={form}
            id="packWeight"
            type="number"
            label="Pack Weight"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="palletStandardConfiguration"
            type="text"
            label="Pallet Standard Configuration"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="weight"
            type="text"
            label="Weight"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="species"
            type="select"
            label="Species"
            inputProps={{ 'data-lpignore': true }}
            options={(
              species?.userDefinedCodes?.sort(function (a, b) {
                if (a.description < b.description) {
                  return -1;
                }
                if (a.description > b.description) {
                  return 1;
                }
                return 0;
              }) || []
            ).map<SelectItem>((p) => {
              return {
                id: p.code,
                label: p.description,
              };
            })}
          />
          <FormField
            form={form}
            id="brand"
            type="text"
            label="Brand"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="eCertCode"
            type="text"
            label="ECert Code"
            inputProps={{ 'data-lpignore': true }}
          />
          <FormField
            form={form}
            id="billGroup"
            type="autocomplete"
            label="Bill Group"
            options={(
              billGroups?.userDefinedCodes?.sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              }) || []
            ).map<SelectItem>((p) => {
              return {
                id: p.code,
                label: p.name,
              };
            })}
          />
          <FormLabel>ECert Exempt</FormLabel>
          <RadioGroup
            aria-labelledby="response"
            name="response"
            value={value}
            onChange={handleChange}
            row
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => onClose()}
            disabled={form.processing}
            tabIndex={2}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" loading={form.processing} tabIndex={1}>
            Save
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default EditProduct;
