import React from 'react';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { exportSalesOrdersToNetsuite } from '../../Services/BillingService';
import { BillingCycle } from '../../Models/Customer';
import { confirmModalDialog } from '../ModalDialog';

export interface NetsuiteExportButtonsProps {
  onFinished?: () => void;
}

export default function ({ onFinished }: NetsuiteExportButtonsProps) {
  // Ideally we would have a way to persist the loading state across page loads, or have a server-side API to poll to get progress updates
  const [loading, setLoading] = React.useState(false);

  async function exportOrdersForCycle(billingCycle: BillingCycle) {
    const confirmed = await confirmModalDialog({
      title: '',
      acceptButtonLabel: 'Yes',
      declineButtonLabel: 'No',
      content:
        'Are you sure you want to submit WEEKLY/MONTHLY billing data to Netsuite?',
      acceptButtonCountdown: 3,
    });
    if (confirmed) {
      setLoading(true);
      await exportSalesOrdersToNetsuite({ billingCycle: billingCycle });
      setLoading(false);

      // Let the parent component know we are ready to refresh
      if (onFinished != undefined) onFinished();
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <span style={{ fontSize: '14px', margin: 'auto 0' }}>Export:</span>
      <LoadingButton
        loading={loading}
        data-testid={'netsuiteMonthlyExport'}
        onClick={async () => await exportOrdersForCycle('Monthly')}
        variant="contained"
      >
        Monthly
      </LoadingButton>
      <LoadingButton
        loading={loading}
        data-testid={'netsuiteWeeklyExport'}
        onClick={async () => await exportOrdersForCycle('Weekly')}
        variant="contained"
      >
        Weekly
      </LoadingButton>
    </Stack>
  );
}
