import React from 'react';
import PalletMovement from '../../../Components/PalletMovement/PalletMovement';
import useApiGet from '../../../Hooks/useApiGet';
import PalletService from '../../../Services/PalletService';

export interface PalletMovementForCustomerProps {
  palletId: number | null;
}

export default function ({ palletId }: PalletMovementForCustomerProps) {
  const { data, loading } = useApiGet(
    PalletService.getPalletMovementByPalletIdForCustomer,
    {
      params: {
        palletId: palletId,
      },
    }
  );

  return <PalletMovement palletMovementHistory={data} loading={loading} />;
}
