import React from 'react';
import { Box, Container, Paper } from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import ChangePasswordBase from './ChangePasswordBase';

/***
 * Change Password straight after login based on user.HasChangedPassword = true
 */
export default function () {
  return (
    <Container maxWidth="xs">
      <Paper
        sx={{
          p: 2,
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <CardTitle title="Change Password" />

          <ChangePasswordBase
            fullWidthButtons={true}
            showBackButton={false}
            showLogoutButton={true}
          />
        </Box>
      </Paper>
    </Container>
  );
}
