import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppProvider from './Layouts/AppLayout/AppProvider';

// If the environment is not 'ci' or 'development', we will initialize Sentry
if (
  process.env.REACT_APP_ENVIRONMENT !== 'ci' &&
  process.env.NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn: 'https://fd537d2096dc4319be519a2bd22b56a6@o4505557899214848.ingest.sentry.io/4505565462528000',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          'wms-test.coolpak.co.nz',
          'wms-stage.coolpak.co.nz',
          'wms.coolpak.co.nz',
          'wms-preprod.coolpak.co.nz',
        ],
      }),
      new Sentry.Replay(),
    ],
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
      ? +process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE
      : 1.0,
    replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
      ? +process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
      : 1.0,
    replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
      ? +process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
      : 1.0,
  });
} else {
  // Sentry is not initialised where we don't require it
  console.log(
    `Sentry is not initialized: REACT_APP_ENVIRONMENT=${process.env.REACT_APP_ENVIRONMENT} NODE_ENV=${process.env.NODE_ENV}`
  );
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

export default root.render(<AppProvider />);
