import {
  AppBar,
  Toolbar,
  Container,
  Box,
  useScrollTrigger,
  Tab,
  Tabs,
  Button,
  Stack,
  IconButton,
  ContainerProps,
  Typography,
} from '@mui/material';
import React, { ReactNode, useContext, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Check, SvgIconComponent } from '@mui/icons-material';
import AppDrawer, { drawerWidth } from '../Layouts/AppLayout/AppDrawer';
import { TabContext } from '@mui/lab';
import { ResponsiveContext } from '../Providers/ResponsiveProvider';
import useCurrentRoute from '../Hooks/useCurrentRoute';
import { TypographyProps } from '@mui/material/Typography';
import { getAppBarColour } from '../Lib/utils';

/**
 * This component provides a white top bar with:
 *   - Desktop only: back button (mobile devices have a hard back button)
 *   - Mobile only: hamburger icon with side-bar menu (desktop already has the top bar and side menu)
 *   - Tab bar (optional - on mobile this is integrated into the same bar to give more vertical space)
 *   - Right content (options)
 *
 *  NOTE - this component should only ever be in the DOM once!
 */

export interface MobilePageTab<T extends string> {
  id: T;
  icon: SvgIconComponent;
  complete?: boolean;
  hidden?: boolean;
  label?: string;
  labelComponent?: ReactNode;
}

export interface MobilePageProps<T extends string> extends ContainerProps {
  children: ReactNode;
  right?: ReactNode;
  tabs?: MobilePageTab<T>[];
  tab?: T;
  showOnDesktop?: boolean;
  onTabChange?: (tab: T) => void;
  onBack?: (navigate: NavigateFunction) => void;
  showTitle?: boolean; // true by default on mobile view
  // when set 'title' prop in containerProps, the title would be showed when hover on the container
  // To prevent this behavior, use pageTitle instead.
  pageTitle?: string; // title of current page
  pageTitleProps?: TypographyProps;
}

/**
 * This is the component
 */
export default function <T extends string>({
  children,
  right,
  tabs,
  tab,
  showOnDesktop,
  onTabChange,
  onBack,
  showTitle,
  pageTitle,
  pageTitleProps,
  ...containerProps
}: MobilePageProps<T>) {
  const navigate = useNavigate();
  const { mobileView } = useContext(ResponsiveContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const currentRoute = useCurrentRoute();

  // for the elevated scroll bar
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  // if we don't really need this mobile title bar, and we're explicitly told to show it on mobile, don't show it
  if (!mobileView && !showOnDesktop && !tabs?.length && !right) {
    return (
      <Container {...(containerProps || {})}>
        <Stack spacing={1}>{children}</Stack>
      </Container>
    );
  }

  return (
    <TabContext value={tab || ''}>
      <AppBar
        elevation={trigger ? 4 : 0}
        data-testid="mobile-appbar"
        sx={{
          background: !mobileView ? 'white' : getAppBarColour(),
          color: 'grey.600',
          // take the top-bar and side-menu into account on Desktop - just in case
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          top: { md: '64px' },
        }}
      >
        <Toolbar sx={{ minHeight: '48px !important', maxHeight: '48px' }}>
          {mobileView ? (
            <IconButton
              size="large"
              edge="start"
              sx={{
                color: 'white',
              }}
              aria-label="menu"
              onClick={() => setMenuOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Button
              size="large"
              color="inherit"
              data-testid="mobile-page-back-btn"
              onClick={() => (onBack ? onBack(navigate) : navigate(-1))}
              startIcon={<ChevronLeftIcon />}
              sx={{
                pl: 0,
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'normal',
              }}
            >
              Back
            </Button>
          )}
          {(showTitle ||
            (mobileView && !(showTitle === false)) ||
            (pageTitle && pageTitle.length > 0) ||
            containerProps?.title) && (
            <Typography
              data-testid="screen-title"
              variant="body1"
              sx={{
                color: !mobileView ? 'grey.900' : 'white',
              }}
              {...(pageTitleProps || {})}
            >
              {pageTitle || containerProps?.title || currentRoute?.title}
            </Typography>
          )}
          {tabs?.length && (
            <Tabs
              value={tab}
              onChange={(_, val) => onTabChange?.(val)}
              variant="fullWidth"
            >
              {tabs
                .filter((tab) => !tab.hidden)
                .map((tab) => (
                  <Tab
                    key={tab.id}
                    value={tab.id}
                    sx={{
                      minWidth: mobileView ? '50px' : '125px',
                      minHeight: '50px',
                      '&.Mui-selected': {
                        color: mobileView ? 'white' : 'inherit',
                      },
                    }}
                    icon={
                      <Box>
                        {tab.complete && (
                          <Check
                            fontSize="small"
                            sx={{
                              color: 'green',
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}
                          />
                        )}
                        {mobileView && <tab.icon />}
                      </Box>
                    }
                    label={
                      mobileView
                        ? undefined
                        : tab.labelComponent ?? tab.label ?? tab.id
                    }
                    data-testid={'tab-' + tab.id}
                  />
                ))}
            </Tabs>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {right}
        </Toolbar>
      </AppBar>
      <Container
        {...(containerProps || {})}
        sx={{
          // use top padding to push the content down below the app bar
          paddingTop: '38px', // tab bar height of 48px - 16px padding on the container + 8px padding
          px: '8px',
          ...(containerProps.sx || {}),
        }}
      >
        <Stack spacing={1}>{children}</Stack>
      </Container>
      {mobileView && (
        <AppDrawer mobile open={menuOpen} onClose={() => setMenuOpen(false)} />
      )}
    </TabContext>
  );
}
