import Api, { ServiceGetMethod, ServicePostMethod } from './Api';
import {
  DefinedMovementPallet,
  Pallet,
  PalletByProductASNForCustomer,
  PalletByProductContainerForCustomer,
  PalletDetails,
  PalletDetailsForCustomer,
  PalletForCustomer,
  PalletForStaging,
  PalletLoadedType,
} from '../Models/Pallet';
import { LocationEnquiry } from '../Models/LocationEnquiry';
import { PalletMovementHistory } from '../Models/PalletMovementHistory';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getByCode: ServicePostMethod<
  FetchPalletRequest & { detailed?: false },
  Pallet
> = (data, options) =>
  Api.put('api/services/app/Pallet/GetByCode', data, options);

// This API allows to get pallets with multiple customers.
const enquirePallet: ServicePostMethod<
  FetchPalletRequest & { detailed?: false },
  Pallet[]
> = (data, options) =>
  Api.put('api/services/app/Pallet/EnquirePallet', data, options);

const getByCodeForCustomer: ServicePostMethod<
  FetchPalletRequestForCustomer,
  PalletForCustomer
> = (data, options) =>
  Api.put('api/services/app/PalletExternal/GetByCode', data, options);

const getByCodeDetailed: ServicePostMethod<
  FetchPalletRequest & { detailed: true },
  PalletDetails
> = (data, options) =>
  Api.put('api/services/app/Pallet/GetByCode', data, options);

const markAsLoaded: ServicePostMethod<MarkAsLoadedRequest, void> = (
  data,
  options
) => Api.put(`api/services/app/Pallet/MarkAsLoaded`, data, options);

const markAsUnLoaded: ServicePostMethod<MarkAsUnLoadedRequest, void> = (
  data,
  options
) => Api.put(`api/services/app/Pallet/MarkAsUnloaded`, data, options);

const getPalletDetails: ServiceGetMethod<{ id: number }, PalletDetails> = (
  options
) => Api.get(`api/services/app/Pallet/GetPalletDetails`, options);

const getPalletDetailsForCustomer: ServiceGetMethod<
  { id: number },
  PalletDetailsForCustomer
> = (options) =>
  Api.get(`api/services/app/PalletExternal/GetPalletDetails`, options);

const linkPalletBarcode: ServicePostMethod<
  { palletId: number; linkedBarcode: string },
  boolean
> = ({ palletId, linkedBarcode }, options) =>
  Api.put(
    `api/services/app/Pallet/LinkPalletBarcode?palletId=${palletId}&linkedBarcode=${linkedBarcode}`,
    options
  );

const movePallets: ServicePostMethod<MovePalletsRequest, boolean> = (
  data,
  options
) => Api.put(`api/services/app/Pallet/MovePallets`, data, options);

const getLastPalletMovement: ServiceGetMethod<null, PalletDetails[]> = (
  options
) => Api.get(`api/services/app/Pallet/GetLastPalletMovement`, options);

const completeVerifying: ServicePostMethod<{ id: number }, void> = (
  data,
  options
) => Api.put(`api/services/app/Pallet/CompleteVerifying`, data, options);

const locationEnquiry: ServicePostMethod<
  { location: string },
  LocationEnquiry[]
> = ({ location }, options) =>
  Api.put(
    `api/services/app/Pallet/LocationEnquiry?location=${location}`,
    options
  );

const getPalletsReadyForStaging: ServiceGetMethod<
  { warehouseId: number | null | undefined },
  PalletForStaging[]
> = (options) =>
  Api.get('api/services/app/Pallet/GetPalletsReadyForStaging', options);

const getDefinedMovementPallets: ServiceGetMethod<
  { warehouseId: number | null | undefined },
  DefinedMovementPallet[]
> = (options) =>
  Api.get('api/services/app/Pallet/GetDefinedMovementPallets', options);

const markAsLost: ServicePostMethod<{ id: number }, void> = (data, options) =>
  Api.put(`api/services/app/Pallet/MarkAsLost`, data, options);

const splitPallet: ServicePostMethod<SplitPalletRequest, void> = (
  data,
  options
) => Api.put(`api/services/app/Pallet/SplitPallet`, data, options);

const getPalletMovementByPalletId: ServiceGetMethod<
  { palletId: number | null },
  PalletMovementHistory[]
> = (options) =>
  Api.get(`api/services/app/Pallet/GetPalletMovementByPalletId`, options);

const getPalletMovementByPalletIdForCustomer: ServiceGetMethod<
  { palletId: number | null },
  PalletMovementHistory[]
> = (options) =>
  Api.get(
    `api/services/app/PalletExternal/GetPalletMovementByPalletId`,
    options
  );

const getPalletsByBolProductContainer: ServiceGetMethod<
  GetPalletsByBolProductContainerRequest,
  PalletByProductContainerForCustomer[]
> = (options) =>
  Api.get(
    'api/services/app/PalletExternal/GetPalletsByBolProductContainer',
    options
  );

const getPalletsByASNProduct: ServiceGetMethod<
  GetPalletsByASNProductRequest,
  PalletByProductASNForCustomer[]
> = (options) =>
  Api.get('api/services/app/PalletExternal/GetPalletsByASNProduct', options);

// export a default object so we can use FooService.method in our code - which makes it clear what we're getting
export default {
  getByCode,
  getByCodeForCustomer,
  markAsLoaded,
  getPalletDetails,
  getByCodeDetailed,
  linkPalletBarcode,
  movePallets,
  getLastPalletMovement,
  completeVerifying,
  locationEnquiry,
  getPalletsReadyForStaging,
  markAsLost,
  markAsUnLoaded,
  splitPallet,
  getPalletMovementByPalletId,
  getPalletMovementByPalletIdForCustomer,
  getPalletsByBolProductContainer,
  enquirePallet,
  getPalletDetailsForCustomer,
  getDefinedMovementPallets,
  getPalletsByASNProduct,
};

export interface MarkAsLoadedRequest {
  palletId: number;
  loadedType: PalletLoadedType;

  // these are only here so they get logged in AbpAuditLogs
  palletCode: string;
  linkedBarcode: string;
  containerCode: string;
  bolNumber: number;
  usedScanner: boolean;
}

export interface MarkAsUnLoadedRequest {
  palletIds: number[];
  bolContainerId: number;
}

export interface FetchPalletRequest {
  code: string;
  containerCode?: string;
  billOfLadingId?: number;
  syncFromBasis?: boolean;
  detailed?: boolean;
  withCartons?: boolean;
  performPalletOnHandCheck?: boolean;
}

export interface FetchPalletRequestForCustomer {
  code: string;
}

export interface MovePalletsRequest {
  palletIds: number[];
  location: string;
}

export interface SplitPalletRequest {
  sourcePalletId: number;
  sourceCartonId: number;
  targetPalletBarcode: string;
  splitQty: number;
  isDamaged: boolean;
}

export interface GetPalletsByBolProductContainerRequest {
  productId?: string | null;
  containerId?: string | null;
  bolNumber: string;
}

export interface GetPalletsByASNProductRequest {
  productId: string | null;
  asnNumber: string;
}

export const PALLET_ERROR_CODES = {
  PALLET_NOT_FOUND: 2000, // Pallet does not exist
} as const;
