import React, { useState } from 'react';
import MobilePage from '../../Components/MobilePage';
import { Box, Button } from '@mui/material';
import CardTitle from '../../Components/CardTitle';
import StandardDataGrid from '../../Components/StandardDataGrid';
import useApiGet from '../../Hooks/useApiGet';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import ProductService from '../../Services/ProductService';
import EditProduct from './EditProduct';
import AddIcon from '@mui/icons-material/Add';
import UserDefinedCodeService from '../../Services/UserDefinedCodeService';
import { CODE_GROUPS } from '../../Models/UserDefinedCode';
import AuditLog from '../../Components/AuditLog/AuditLog';
import { Product } from '../../Models/Product';

export default function () {
  const { data: unitOfMeasures } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.UNIT_OF_MEASURE,
      },
    }
  );
  const { data: packageTypes } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.PACKAGE_TYPE,
      },
    }
  );
  const { data: species } = useApiGet(UserDefinedCodeService.getGroupDetails, {
    params: {
      name: CODE_GROUPS.SPECIES,
    },
  });
  const { data: billGroups } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.BILL_GROUP,
      },
    }
  );

  const [editProduct, setEditProduct] = useState<Product | null>(null);
  const { data, loading, refresh } = useApiGet(ProductService.getProducts, {
    params: {
      maxResultCount: 9999,
    },
  });

  const [openEditProductDialog, setOpenEditProductDialog] = useState(false);

  const renderActions = (params: GridRowParams) => {
    const actions = [];

    const editBtn = (
      <GridActionsCellItem
        testId="edit-product"
        label="Edit"
        onClick={() => {
          setOpenEditProductDialog(true);
          setEditProduct(params.row);
        }}
        showInMenu
      />
    );
    actions.push(editBtn);
    const auditLogBtn = (
      <AuditLog
        entityType="Coolpak.WMS.Products.Product"
        entityId={params.row.id}
        tabIndex={300}
      />
    );
    actions.push(auditLogBtn);

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
    },
    {
      field: 'eCertCode',
      headerName: 'ECert Code',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'eCertDesc',
      headerName: 'ECert Description',
      flex: 1,
    },
    {
      field: 'hsc',
      headerName: 'HSC',
      flex: 1,
    },
    {
      field: 'unitOfMeasure',
      headerName: 'Unit Of Measure',
      flex: 1,
      renderCell: (params) =>
        unitOfMeasures?.userDefinedCodes?.find(
          ({ code }) => code === params.row.code
        )?.description,
    },
    {
      field: 'packageType',
      headerName: 'Package Type',
      flex: 1,
      renderCell: (params) =>
        packageTypes?.userDefinedCodes?.find(
          ({ code }) => code === params.row.code
        )?.description,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      flex: 1,
    },
    {
      field: 'packWeight',
      headerName: 'Pack Weight',
      flex: 1,
    },
    {
      field: 'palletStandardConfiguration',
      headerName: 'Pallet Standard Configuration',
      flex: 1,
    },
    {
      field: 'billGroup',
      headerName: 'Bill Group',
      flex: 1,
      renderCell: (params) =>
        billGroups?.userDefinedCodes?.find(
          ({ code }) => code === params.row.code
        )?.name,
    },
    {
      field: 'species',
      headerName: 'Species',
      flex: 1,
      renderCell: (params) =>
        species?.userDefinedCodes?.find(({ code }) => code === params.row.code)
          ?.description,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      flex: 1,
    },
    {
      field: 'eCertExempt',
      headerName: 'ECert Exempt',
      flex: 1,
      renderCell: (params) => (params.row.eCertExempt ? 'Yes' : 'No'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: renderActions,
    },
  ];

  return (
    <MobilePage maxWidth={false}>
      <Box sx={{ height: '82vh' }}>
        <CardTitle
          title="Product Maintenance"
          rightChild={
            <Box>
              <Button
                data-testid="create-product-button"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setOpenEditProductDialog(true);
                  setEditProduct(null);
                }}
              >
                Add Product
              </Button>
            </Box>
          }
        />
        <StandardDataGrid
          testId="data-grid-products-table"
          loading={loading}
          rows={data?.items || []}
          columns={columns}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick={true}
        />
      </Box>
      {openEditProductDialog && (
        <EditProduct
          unitOfMeasures={unitOfMeasures}
          packageTypes={packageTypes}
          species={species}
          billGroups={billGroups}
          product={editProduct}
          open={openEditProductDialog}
          test-id="edit-product-component"
          onClose={() => {
            setOpenEditProductDialog(false);
            setEditProduct(null);
          }}
          onSuccess={() => {
            setOpenEditProductDialog(false);
            refresh();
          }}
        />
      )}
    </MobilePage>
  );
}
