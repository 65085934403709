import React, { useMemo } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CardTitle from '../../../Components/CardTitle';
import useApiGet from '../../../Hooks/useApiGet';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import MobilePage from '../../../Components/MobilePage';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import PalletService from '../../../Services/PalletService';
import { tryFormatDateStr } from '../../../Lib/utils';

export default function () {
  // The bolNumber is passed in from the router
  const { bolNumber } = useParams<{ bolNumber: string }>();
  const [searchParams] = useSearchParams();

  // Setup the two filters, products and containers based on the querystring parameters
  const [productFilter, setProductFilter] = React.useState<string | null>(
    searchParams.get('productId')
  );
  const [containerFilter, setContainerFilter] = React.useState<string | null>(
    searchParams.get('containerId')
  );

  // Two API calls, one for the BOL details and one for the specific pallets based on the filters
  const { data } = useApiGet(PalletService.getPalletsByBolProductContainer, {
    params: {
      bolNumber: bolNumber || '',
      productId: productFilter || '',
      containerId: containerFilter || '',
    },
  });

  const { data: bolData } = useApiGet(
    BillOfLadingService.getBillOfLadingByIdForCustomer,
    {
      params: {
        bolNumber: bolNumber || '',
      },
    }
  );

  // Filter arrays used to populate the dropdowns for products and containers
  const dropdownFilterProducts = useMemo(() => {
    const products =
      bolData?.cartonsByProduct.map((item) => {
        return { productCode: item.productCode, productId: item.productId };
      }) || [];
    return {
      products: Array.from(new Set(products)),
    };
  }, [bolData]);

  const dropdownFilterContainers = useMemo(() => {
    const containers =
      bolData?.containers.map((item) => {
        return {
          containerCode: item.containerCode,
          containerId: item.containerId,
        };
      }) || [];
    return {
      containers: Array.from(new Set(containers)),
    };
  }, [bolData]);

  // Data grid columns
  const palletColumns: GridColDef[] = [
    {
      field: 'palletCode',
      headerName: 'Pallet Code',
      minWidth: 50,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Link to={`${params.row.palletCode}`}>{params.row.palletCode}</Link>
      ),
    },
    {
      field: 'productCode',
      headerName: 'Product Code',
      minWidth: 50,
      maxWidth: 150,
    },
    {
      field: 'productDescription',
      headerName: 'Product Description',
      minWidth: 250,
      maxWidth: 350,
    },
    {
      field: 'containerCode',
      headerName: 'Container Code',
      minWidth: 250,
      maxWidth: 350,
    },
    {
      field: 'qty',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'loadedDate',
      headerName: 'Loaded Date',
      minWidth: 250,
      maxWidth: 350,
      renderCell: (params) =>
        tryFormatDateStr(params.row.loadedDateTime, 'dd/MM/yyyy'),
    },
  ];

  return (
    <MobilePage
      showTitle={false}
      maxWidth={false}
      showOnDesktop
      title={`Pallets for outbound orders on ${bolNumber}`}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {!data && (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          )}

          {data && (
            <Grid container spacing={1}>
              <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
                <Card variant="outlined" sx={{ height: '100%' }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <CardTitle title="Pallets" />

                    {(dropdownFilterProducts.products.length > 1 ||
                      dropdownFilterContainers.containers.length > 1) && (
                      <Grid container spacing={2}>
                        {dropdownFilterProducts.products.length > 1 && (
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <h3>Products</h3>
                              <Select
                                displayEmpty
                                data-testid="product-filter"
                                onChange={(e) =>
                                  setProductFilter(e.target.value as string)
                                }
                                value={productFilter ?? ''}
                                defaultValue={''}
                              >
                                <MenuItem value="">All</MenuItem>
                                {dropdownFilterProducts.products.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={'product-' + index}
                                      value={option.productId}
                                    >
                                      {option.productCode}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Stack>
                          </Grid>
                        )}

                        {dropdownFilterContainers.containers.length > 1 && (
                          <Grid item xs={12} md={6}>
                            <Stack>
                              <h3>Containers</h3>
                              <Select
                                displayEmpty
                                data-testid="container-filter"
                                onChange={(e) =>
                                  setContainerFilter(e.target.value as string)
                                }
                                value={containerFilter ?? ''}
                                defaultValue={''}
                              >
                                <MenuItem value="">All</MenuItem>
                                {dropdownFilterContainers.containers.map(
                                  (option, index) => (
                                    <MenuItem
                                      key={'container-' + index}
                                      value={option.containerId}
                                    >
                                      {option.containerCode}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    )}

                    <Box sx={{ minHeight: '20vh', paddingTop: '10px' }}>
                      <StandardDataGrid
                        testId="data-grid-pallets-table"
                        rows={data || []}
                        columns={palletColumns}
                        getRowId={(row) => row.palletId}
                        disableRowSelectionOnClick={true}
                        noRowsMessage="No pallets found"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </MobilePage>
  );
}
