import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DefinedMovementPallet, PalletForStaging } from '../../Models/Pallet';
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { getShortenBarcode } from '../../Models/Carton';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import theme from '../../theme';
import { toast } from '../Toast';
import { FileCopy } from '@mui/icons-material';
import { digitsDisplayedForPalletCode } from './PalletPickList';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import StandardDataGrid from '../StandardDataGrid';
import { AuthContext } from '../../Providers/AuthProvider';
import usePermissions from '../../Hooks/usePermissions';
import PalletLost from '../../Icons/PalletLost';
import { confirmModalDialog } from '../ModalDialog';
import PalletService from '../../Services/PalletService';

interface DefinedMovementProps {
  palletsWithDefinedMovement: DefinedMovementPallet[];
  refreshPalletTable: () => void;
  refreshDefinedMovementTable: () => void;
  loading?: boolean;
}
export default function ({
  palletsWithDefinedMovement,
  refreshPalletTable,
  refreshDefinedMovementTable,
  loading = false,
}: DefinedMovementProps) {
  const { user } = useContext(AuthContext);
  const { mobileView } = useContext(ResponsiveContext);
  const navigate = useNavigate();
  const [palletData, setPalletData] = useState<DefinedMovementPallet[]>();
  const [roomFilterValue, setRoomFilterValue] = useState<string | null>('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [canSearchPallet] = usePermissions(['BOL.Pallet.Search']);
  const canNavigateToSearchPallet = !mobileView && canSearchPallet;
  type TableFilters = {
    room?: string | null;
  };
  type TableFilterObj = { filterBy: null | TableFilters };
  const [tableFilter, setTableFilter] = useState<TableFilters>({
    room: null,
  });
  const dropdownFilterOptions = useMemo(() => {
    const filterOptions: {
      rooms: string[];
    } = {
      rooms: [],
    };
    if (!palletData) return filterOptions;

    for (let i = 0; i < palletData?.length; i++) {
      if (filterOptions.rooms.indexOf(palletData[i]?.storeCode) == -1)
        filterOptions.rooms.push(palletData[i]?.storeCode);
    }

    return filterOptions;
  }, [palletData]);

  const filteredPalletData: DefinedMovementPallet[] = useMemo(() => {
    if (!palletData) return [];
    setRoomFilterValue('');
    return tableFilter?.room
      ? palletData.filter((pallet) => tableFilter.room == pallet.storeCode)
      : palletData;
  }, [palletData, tableFilter]);
  // submit the form if the url contains a query parameter code
  useEffect(() => {
    setPalletData(palletsWithDefinedMovement);
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [palletsWithDefinedMovement]);

  const confirmLostDialogue = useCallback(
    (pallet: PalletForStaging) => {
      return confirmModalDialog({
        title: 'Lost Pallet',
        acceptButtonLabel: 'Confirm',
        declineButtonLabel: 'Cancel',
        acceptButtonCountdown: 3,
        declineButtonCountdown: 0,
        content: (
          <Box textAlign={'center'}>
            <h3>Are you sure you want to mark pallet {pallet.code} as lost?</h3>
          </Box>
        ),
        onAccept: () => {
          PalletService.markAsLost({ id: pallet.id }).then(() => {
            refreshPalletTable();
            refreshDefinedMovementTable();
          });
        },
      });
    },
    [refreshPalletTable, refreshDefinedMovementTable]
  );

  const statusIcon = useCallback(
    (palletRow: PalletForStaging): ReactNode => {
      return (
        <IconButton
          sx={{ color: '#2273ab' }}
          data-testid={`declare-lost-pallet-${palletRow.id}`}
          onClick={() => confirmLostDialogue(palletRow)}
        >
          <PalletLost></PalletLost>
        </IconButton>
      );
    },
    [confirmLostDialogue]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'code',
        headerName: 'Code',
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<PalletForStaging>) => {
          const fullPalletCode = params.row.code;
          let displayPalletCode = fullPalletCode;

          if (mobileView) {
            const shortenPalletCode = getShortenBarcode(
              params.row.code,
              digitsDisplayedForPalletCode
            );

            displayPalletCode = `${
              params.row.code.length > digitsDisplayedForPalletCode ? '...' : ''
            }${shortenPalletCode}`;
          }

          return (
            <>
              <Link
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
                onClick={() =>
                  canNavigateToSearchPallet
                    ? navigate(`/barcode-search?code=${fullPalletCode}`)
                    : undefined
                }
              >
                {displayPalletCode}
              </Link>
              <IconButton
                data-testid={`copy-pallet-code-${params.row.id}`}
                onClick={() => {
                  navigator.clipboard
                    .writeText(fullPalletCode)
                    .then(() => toast('Barcode copied'));
                }}
              >
                <FileCopy sx={{ fontSize: '12px' }} />
              </IconButton>
            </>
          );
        },
      },
      {
        field: 'location',
        headerName: 'Location',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<PalletForStaging>) =>
          statusIcon(params.row),
      },
      {
        field: 'productCode',
        headerName: 'Product Code',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'requiredTempState',
        headerName: 'Required Temp State',
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'actualTempState',
        headerName: 'Actual Temp State',
        sortable: true,
        minWidth: 100,
      },
    ],
    [mobileView, canNavigateToSearchPallet, navigate, statusIcon]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'location',
      sort: 'asc',
    },
  ];

  const closeModalDialog = async ({ filterBy = null }: TableFilterObj) => {
    setFilterModalOpen(false);
    if (filterBy) {
      setTableFilter(filterBy);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="left">
        <Box>
          <Stack direction={{ xs: 'row', sm: 'row' }}>
            {tableFilter.room && (
              <Chip
                data-testid="room-filter-chip"
                sx={{ width: '100px' }}
                label={'Room ' + tableFilter.room}
                onDelete={() => setTableFilter({ ...tableFilter, room: null })}
              />
            )}
            {!user?.currentWarehouseId && (
              <h4>Warehouse must be selected before viewing pallets</h4>
            )}
          </Stack>
        </Box>
        <Box flex={1}></Box>
        <Box alignContent={'flex-end'}>
          <Button
            sx={{ width: '100%', marginY: 1 }}
            data-testid={'pallet-defined-movements-filter-button'}
            onClick={() => setFilterModalOpen(true)}
            variant="contained"
          >
            Filter
          </Button>
        </Box>
      </Stack>
      {filterModalOpen && (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          data-testid="modal-dialog-filters"
          scroll="paper"
          onClose={closeModalDialog}
        >
          <DialogTitle data-testid="modal-dialog-title">
            Filter Pallets
            <IconButton
              aria-label="close"
              data-testid="modal-close"
              onClick={() => closeModalDialog({ filterBy: null })}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent data-testid="defined-movements-filters" dividers>
            <Box>
              <Stack>
                <h3>Filter by room:</h3>
                <Select
                  data-testid="pallet-room-filter"
                  onChange={(e) => setRoomFilterValue(e.target.value as string)}
                  value={roomFilterValue ?? ''}
                  defaultValue={''}
                >
                  <MenuItem value="">All</MenuItem>
                  {dropdownFilterOptions.rooms.map((option, index) => (
                    <MenuItem key={'room-' + index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModalDialog({ filterBy: null })}>
              Cancel
            </Button>
            <Button
              data-testid="apply-filters-button"
              onClick={async () => {
                const filterBy: TableFilterObj = {
                  filterBy: {
                    room: roomFilterValue == '' ? null : roomFilterValue,
                  },
                };
                await closeModalDialog(filterBy);
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          height: {
            xs: 'calc(100vh - 185px)',
            sm: 'calc(100vh - 200px)',
            md: 'calc(100vh - 270px)',
          },
          width: '100%',
        }}
      >
        <StandardDataGrid
          testId="defined-movements-table"
          loading={loading}
          columns={columns}
          rows={filteredPalletData}
          getRowId={(row) => row.id}
          initialSortModel={initialSortModel}
          disableRowSelectionOnClick={true}
          hideToolbar={mobileView}
        />
      </Box>
    </>
  );
}
