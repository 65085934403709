import React from 'react';
import FormField from '../../Components/Forms/FormField';
import {
  Alert,
  Box,
  Button,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import useApiForm from '../../Hooks/useApiForm';
import LoadingButton from '@mui/lab/LoadingButton';
import CurrentUserService from '../../Services/CurrentUserService';
import CardTitle from '../../Components/CardTitle';

/**
 * Reset Password page
 */
const ResetPassword = () => {
  const [success, setSuccess] = React.useState(false);

  const handleCancel = () => {
    // redirect back to the previous page
    window.history.back();
  };

  const onSubmit = (event?: React.FormEvent) => {
    setSuccess(false);
    if (event) {
      event.preventDefault();
    }

    form.submit();
  };

  const form = useApiForm(
    CurrentUserService.resetPassword,
    {
      usernameOrEmail: '',
    },
    {
      // pass the success to the parent event handler which will close the modal and refresh its data
      onSuccess: () => setSuccess(true),
    }
  );

  return (
    <Container maxWidth="xs">
      <Paper
        sx={{
          p: 2,
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box>
          <CardTitle title="Change Password" />
          <Box
            component="form"
            onSubmit={onSubmit}
            data-testid="reset-password-form"
            noValidate
          >
            <Typography variant="body1" gutterBottom sx={{ paddingBottom: 1 }}>
              To reset your password, please enter your username or email
              address. A email will be sent to you to reset your password.
            </Typography>

            {success && (
              <Alert severity="success">
                If you provided a valid username or email address, you will
                receive a email with a link to reset your password.
              </Alert>
            )}

            <FormField
              form={form}
              id="usernameOrEmail"
              type="text"
              label="Username or Email"
              required
            />

            <Box sx={{ mt: 1 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={handleCancel}
                disabled={form.processing}
                tabIndex={1}
                data-testid="change-password-back"
                sx={{ mb: 2 }}
              >
                Back
              </Button>

              <LoadingButton
                variant="contained"
                fullWidth
                type="submit"
                loading={form.processing}
                data-testid="change-password-save"
                tabIndex={3}
              >
                Reset
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
