import { SelectItem } from '../Components/Forms/FormField';

export interface Consignee {
  id: number;
  code: string;
  name: string;
  internal?: boolean;
}

export function consigneeSelectItems(consignees: Consignee[]) {
  return consignees
    .sort((a, b) => a.name.localeCompare(b.name))
    .map<SelectItem>((consignee) => ({
      id: consignee.code,
      label: consignee.name,
    }));
}
