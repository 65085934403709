import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import { SelectItem } from '../Components/Forms/FormField';
import { UserDefinedCode } from './UserDefinedCode';
import StatusChip, {
  StatusChipColors,
  StatusChipProps,
} from '../Components/StatusChip';
import React from 'react';

export interface StockOnHandCustomerCount {
  // customer
  customerId: number;

  // unique pallets
  totalPalletCount: number;

  // soh pallets grouped by product, customer
  stockOnHandCount: StockOnHandCount[];
}

export interface StockOnHandCount {
  id: string; // this is a concatenation of productId-customerId to make it unique

  // product
  productId: number;
  //productCode?: string; // not returned from server
  //productDescription?: string; // not returned from server

  // customer
  customerId: number;
  //customerName?: string; // not returned from server
  batchNumbers: string[];
  inTransit: boolean;
  // pallets
  palletCount: number;
  qty: number;
  totalWeight: number;
  availableQty: number;
  availableWeight: number;
  holdQty: number;
  holdWeight: number;
  allocatedQty: number;
  allocatedWeight: number;
}

export interface StockOnHandCarton {
  palletId: number;
  palletPrefix: string;
  palletCode: string;
  palletStatusId?: number;
  palletStatusCode?: string;

  id: number;
  batchNumber: string;
  batchCode: number;
  plantCode: string;
  qty: number;
  weight: number;
  lot: number;
  cartonBarcode: string;
  plannedContainerCode?: string;
  asn: number;
  halalStatus: string;
  expDate?: string;
  dateIn?: string;
  lowDate?: string;
  highDate?: string;
  packDate?: string;
  transferDateTime?: string;
  location?: string;
  palletDepth?: number;
  sourceEdNumber?: string;
  cypher?: string;
  eligibility?: string;
  requiredTempState?: string;
  actualTempState?: string;
  containerCode?: string;
  bolNumber?: string;
  plannedBolNumber?: string;
  holdStatusId?: number;
  holdStatusCode?: string;
  eCertPending?: boolean;
  productCode?: string;
  warehouseId?: number;
  customerCode?: number;
  declarations?: string;
  isLostCarton?: boolean;
}

export interface Carton {
  id: number;
  palletCode: string;
  batchNumber: string;
  batchCode: number;
  qty: number;
  weight: number;
  lot: number;
  cartonBarcode: string;
  asn: number;
  halalStatus: string;
  expDate?: string;
  dateIn?: string;
  dateOut?: string;
  lowDate?: string;
  highDate?: string;
  packDate?: string;
  location?: string;
  palletDepth?: number;
  sourceEdNumber?: string;
  cypher?: string;
  eligibility?: string;
  productDescription: string;
  productCode: string;
  status: CartonStatus;
  plantCode?: string;
  bolContainerId?: number;
  plannedBOLContainerId?: number;
  plannedBillOfLadingId?: number;
  asnId?: number;
  asnLineId?: number;
  eCertPending?: boolean;
  declarations?: string;
  requiredTempState?: string;
  holdStatusDescription?: string;
  notes?: string;
  isOnHand?: boolean;
}

export enum CartonStatus {
  expected,
  verified,
  new,
  lost,
  pending,
  unexpected,
  notArrived,
}

export const CARTON_STATUS_LABELS = [
  'Expected',
  'Verified',
  'New',
  'Lost',
  'Pending',
  'Unexpected',
  'Not Arrived',
] as const;

export type CartonStatusLabel = typeof CARTON_STATUS_LABELS[number];

export const CARTON_STATUS_COLORS: StatusChipColors<CartonStatusLabel> = {
  Expected: { bg: '#FFFFFF' },
  Verified: { bg: '#C0D8C1' }, // lighten(theme.palette.success.main, 0.7)
  New: { bg: '#F9D2B3' }, // lighten(theme.palette.warning.main, 0.7)
  Lost: { bg: '#F08E8D' },
  Pending: { bg: '#808080' },
  Unexpected: { bg: '#808080' },
  'Not Arrived': { bg: '#808080' },
};

export interface CartonEligibility {
  code: string;
  description: string;
}
export function cartonEligibilitySelectItems(eligibilities: UserDefinedCode[]) {
  const list = [
    ...eligibilities,
    { code: '', description: 'All Eligibilities' },
  ];

  return list
    .sort((a, b) => a.code?.localeCompare(b.code))
    .map<SelectItem>(({ code, description }) => ({
      id: code,
      label: (code ? code + ' - ' : '') + description,
    }));
}

// renders the last nbChars digits of a barcode
export const getShortenBarcode = (barcode: string, nbChars?: number) => {
  const limitDigits = nbChars || digitsDisplayedForCartonBarcode;
  // for some reason .replaceAll() doesn't exists when running in tests...
  return barcode.replace(/ /g, '').slice(-limitDigits);
};

export const digitsDisplayedForCartonBarcode = 10;

export const CartonStatusChip = ({
  status,
  ...props
}: { status: CartonStatus } & Omit<
  StatusChipProps<number>,
  'label' | 'colors'
>) => {
  return (
    <StatusChip
      {...props}
      label={CARTON_STATUS_LABELS[status]}
      colors={CARTON_STATUS_COLORS}
      width="6em"
      sx={{
        p: '2px 4px',
        paddingY: '4px',
      }}
    />
  );
};

export const cartonFactory = Factory.define<Carton>(() => ({
  id: faker.datatype.number(),
  palletCode: faker.datatype.string(),
  batchNumber: faker.datatype.string(),
  batchCode: faker.datatype.number(),
  qty: faker.datatype.number(),
  weight: faker.datatype.number(),
  lot: faker.datatype.number(),
  cartonBarcode: faker.datatype.string(),
  asn: faker.datatype.number(),
  halalStatus: faker.datatype.string(),
  productDescription: faker.datatype.string(),
  productCode: faker.datatype.string(),
  expDate: faker.datatype.datetime().toISOString(),
  dateIn: faker.datatype.datetime().toISOString(),
  dateOut: faker.datatype.datetime().toISOString(),
  lowDate: faker.datatype.datetime().toISOString(),
  highDate: faker.datatype.datetime().toISOString(),
  packDate: faker.datatype.datetime().toISOString(),
  location: faker.datatype.string(),
  status: CartonStatus.expected,
  declarations: faker.datatype.string(),
  requiredTempState: faker.datatype.string(),
  holdStatusDescription: faker.datatype.string(),
  notes: faker.datatype.string(),
}));

export const stockOnHandCountFactory = Factory.define<StockOnHandCount>(() => ({
  id: faker.datatype.string(),
  productId: faker.datatype.number(),
  productCode: faker.datatype.string(),
  productDescription: faker.datatype.string(),
  customerId: faker.datatype.number(),
  customerName: faker.datatype.string(),
  palletCount: faker.datatype.number(),
  qty: faker.datatype.number(),
  totalWeight: faker.datatype.number(),
  availableQty: faker.datatype.number(),
  availableWeight: faker.datatype.number(),
  holdQty: faker.datatype.number(),
  holdWeight: faker.datatype.number(),
  allocatedQty: faker.datatype.number(),
  allocatedWeight: faker.datatype.number(),
  batchNumbers: [],
  inTransit: false,
}));

export const stockOnHandCustomerCountFactory =
  Factory.define<StockOnHandCustomerCount>(() => ({
    customerId: faker.datatype.number(),
    totalPalletCount: faker.datatype.number(),
    stockOnHandCount: [],
  }));

export const stockOnHandCartonFactory = Factory.define<StockOnHandCarton>(
  () => ({
    palletId: faker.datatype.number(),
    palletPrefix: faker.datatype.string(),
    palletCode: faker.datatype.string(),
    palletStatusId: faker.datatype.number(),
    palletStatusCode: faker.datatype.string(),
    id: faker.datatype.number(),
    batchNumber: faker.datatype.string(),
    batchCode: faker.datatype.number(),
    plantCode: faker.datatype.string(),
    qty: faker.datatype.number(),
    weight: faker.datatype.number(),
    lot: faker.datatype.number(),
    cartonBarcode: faker.datatype.string(),
    asn: faker.datatype.number(),
    halalStatus: faker.datatype.string(),
    sourceEdNumber: faker.random.alpha(6),
    palletDepth: faker.datatype.number(),
    packDate: faker.datatype.datetime().toISOString(),
    cypher: faker.datatype.string(),
    eligibility: faker.datatype.string(),
    isLostCarton: faker.datatype.boolean(),
  })
);
