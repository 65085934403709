import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import PalletService from '../../Services/PalletService';

import { AuthContext } from '../../Providers/AuthProvider';
import { PalletForStaging } from '../../Models/Pallet';
import PalletLost from '../../Icons/PalletLost';
import CloseIcon from '@mui/icons-material/Close';
import { confirmModalDialog } from '../ModalDialog';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import { getShortenBarcode } from '../../Models/Carton';
import {
  GridColDef,
  GridRenderCellParams,
  GridSortCellParams,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import StandardDataGrid from '../StandardDataGrid';
import theme from '../../theme';
import { FileCopy } from '@mui/icons-material';
import { toast } from '../Toast';
import usePermissions from '../../Hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import PalletLocationsSummaryCard from './PalletLocationsSummaryCard';

export const digitsDisplayedForPalletCode = 10;
interface PalletPickListProps {
  pickingPalletData: PalletForStaging[];
  refreshPalletTable: () => void;
}
export const PalletPickList = ({
  pickingPalletData,
  refreshPalletTable,
}: PalletPickListProps) => {
  const { user } = useContext(AuthContext);
  const { mobileView } = useContext(ResponsiveContext);
  const navigate = useNavigate();

  type TableFilters = {
    room?: string | null;
    bolNumber?: number | null;
    container?: string | null;
  };
  type TableFilterObj = { filterBy: null | TableFilters };

  const [palletData, setPalletData] = useState<PalletForStaging[]>();
  const [tableFilter, setTableFilter] = useState<TableFilters>({
    room: null,
    bolNumber: null,
    container: null,
  });
  const [canSearchPallet] = usePermissions(['BOL.Pallet.Search']);
  const canNavigateToSearchPallet = !mobileView && canSearchPallet;

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [loadingId, setLoadingId] = useState(0);

  const [roomFilterValue, setRoomFilterValue] = useState<string | null>('');
  const [bolNumberFilterValue, setBolNumberFilterValue] = useState<
    string | null
  >('');
  const [containerFilterValue, setContainerFilterValue] = useState<
    string | null
  >('');

  // submit the form if the url contains a query parameter code
  useEffect(() => {
    setPalletData(pickingPalletData);
    // should happen only when loading the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickingPalletData]);

  const dropdownFilterOptions = useMemo(() => {
    const filterOptions: {
      rooms: string[];
      bolNumbers: number[];
      containers: string[];
    } = {
      rooms: [],
      bolNumbers: [],
      containers: [],
    };
    if (!palletData) return filterOptions;

    for (let i = 0; i < palletData?.length; i++) {
      if (filterOptions.rooms.indexOf(palletData[i]?.storeCode) == -1)
        filterOptions.rooms.push(palletData[i]?.storeCode);

      if (filterOptions.bolNumbers.indexOf(palletData[i]?.bolNumber) == -1)
        filterOptions.bolNumbers.push(palletData[i]?.bolNumber);

      if (filterOptions.containers.indexOf(palletData[i]?.containerCode) == -1)
        filterOptions.containers.push(palletData[i]?.containerCode);
    }

    return filterOptions;
  }, [palletData]);

  const filteredPalletData: PalletForStaging[] = useMemo(() => {
    if (!palletData) return [];
    setRoomFilterValue('');
    setBolNumberFilterValue('');
    setContainerFilterValue('');
    return palletData.filter((pallet) => {
      // NOTE: filtering on BOL, Room, and Container is an AND operation
      let valid = true;
      if (tableFilter?.bolNumber)
        valid = valid && tableFilter?.bolNumber == pallet.bolNumber;
      if (tableFilter?.room)
        valid = valid && tableFilter?.room == pallet.storeCode;
      if (tableFilter?.container)
        valid = valid && tableFilter?.container == pallet.containerCode;
      return valid;
    });
  }, [palletData, tableFilter]);

  const confirmLostDialogue = useCallback(
    (pallet: PalletForStaging) => {
      return confirmModalDialog({
        title: 'Lost Pallet',
        acceptButtonLabel: 'Confirm',
        declineButtonLabel: 'Cancel',
        acceptButtonCountdown: 3,
        declineButtonCountdown: 0,
        content: (
          <Box textAlign={'center'}>
            <h3>Are you sure you want to mark pallet {pallet.code} as lost?</h3>
          </Box>
        ),
        onAccept: () => {
          PalletService.markAsLost({ id: pallet.id }).then(() => {
            refreshPalletTable();
          });
        },
      });
    },
    [refreshPalletTable]
  );

  const statusIcon = useCallback(
    (palletRow: PalletForStaging): ReactNode => {
      return (
        <IconButton
          sx={{ color: '#2273ab' }}
          data-testid={`declare-lost-pallet-${palletRow.id}`}
          onClick={() => confirmLostDialogue(palletRow)}
        >
          <PalletLost></PalletLost>
        </IconButton>
      );
    },
    [confirmLostDialogue]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'code',
        headerName: 'Code',
        sortable: true,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<PalletForStaging>) => {
          const fullPalletCode = params.row.code;
          let displayPalletCode = fullPalletCode;

          if (mobileView) {
            const shortenPalletCode = getShortenBarcode(
              params.row.code,
              digitsDisplayedForPalletCode
            );

            displayPalletCode = `${
              params.row.code.length > digitsDisplayedForPalletCode ? '...' : ''
            }${shortenPalletCode}`;
          }

          return (
            <>
              <Link
                sx={{
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                }}
                onClick={() =>
                  canNavigateToSearchPallet
                    ? navigate(`/barcode-search?code=${fullPalletCode}`)
                    : undefined
                }
              >
                {displayPalletCode}
              </Link>
              <IconButton
                data-testid={`copy-pallet-code-${params.row.id}`}
                onClick={() => {
                  navigator.clipboard
                    .writeText(fullPalletCode)
                    .then(() => toast('Barcode copied'));
                }}
              >
                <FileCopy sx={{ fontSize: '12px' }} />
              </IconButton>
            </>
          );
        },
      },
      {
        field: 'location',
        headerName: 'Location',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<PalletForStaging>) =>
          statusIcon(params.row),
      },
      {
        field: 'bolNumber',
        headerName: 'BOL',
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'containerCode',
        headerName: 'Container',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'depth',
        headerName: 'Depth',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'priority',
        headerName: 'Priority',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
        sortComparator: (
          v1: number | null | string,
          v2: number | null | string,
          params: GridSortCellParams
        ): number => {
          const sortModel: GridSortModel = params.api.getSortModel();
          const sortDirection = sortModel.find(
            (model: GridSortItem) => model.field === params.field
          )?.sort;

          // If both values are null or empty strings, they are considered equal
          if ((v1 === null || v1 === '') && (v2 === null || v2 === '')) {
            return 0;
          }

          if (v1 === null || v1 === '') {
            return sortDirection === 'asc' ? 1 : -1;
          }

          if (v2 === null || v2 === '') {
            return sortDirection === 'asc' ? -1 : 1;
          }
          // Both values are numbers, so we can compare them directly
          return (v1 as number) - (v2 as number);
        },
      },
      {
        field: 'batch',
        headerName: 'Batch',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
      {
        field: 'productCode',
        headerName: 'Product Code',
        sortable: true,
        minWidth: 100,
        hide: mobileView,
      },
    ],
    [mobileView, canNavigateToSearchPallet, navigate, statusIcon]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'priority',
      sort: 'asc',
    },
    {
      field: 'location',
      sort: 'asc',
    },
    {
      field: 'depth',
      sort: 'asc',
    },
  ];

  const closeModalDialog = async ({ filterBy = null }: TableFilterObj) => {
    setFilterModalOpen(false);
    if (filterBy) {
      setLoadingId(1);
      setTableFilter(filterBy);
    }
    setLoadingId(0);
  };
  return (
    <>
      <Stack direction="row" alignItems="left">
        <Box width={'100%'}>
          <PalletLocationsSummaryCard data={filteredPalletData} />
          <Stack
            direction={{ xs: 'row', sm: 'row' }}
            justifyContent={'center'}
            padding={2}
          >
            {!user?.currentWarehouseId && (
              <h4>Warehouse must be selected before viewing pallets</h4>
            )}
          </Stack>
          <Stack
            direction={{ sm: 'row' }}
            justifyContent={'center'}
            padding={2}
          >
            {tableFilter.bolNumber && (
              <Chip
                data-testid="bol-filter-chip"
                sx={{ width: 'fit-content' }}
                label={'Bol # ' + tableFilter.bolNumber}
                onDelete={() =>
                  setTableFilter({ ...tableFilter, bolNumber: null })
                }
              />
            )}
            {tableFilter.room && (
              <Chip
                data-testid="room-filter-chip"
                sx={{ width: '100px' }}
                label={'Room ' + tableFilter.room}
                onDelete={() => setTableFilter({ ...tableFilter, room: null })}
              />
            )}
            {tableFilter.container && (
              <Chip
                data-testid="container-filter-chip"
                sx={{ width: 'fit-content' }}
                label={'Container ' + tableFilter.container}
                onDelete={() =>
                  setTableFilter({ ...tableFilter, container: null })
                }
              />
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack direction="row">
        <Box flex={1}></Box>
      </Stack>
      {filterModalOpen && (
        <Dialog
          open={true}
          fullWidth
          maxWidth="md"
          data-testid="modal-dialog-filters"
          scroll="paper"
          onClose={closeModalDialog}
        >
          <DialogTitle data-testid="modal-dialog-title">
            Filter Pallets
            <IconButton
              aria-label="close"
              data-testid="modal-close"
              onClick={() => closeModalDialog({ filterBy: null })}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent data-testid="pallet-picklist-filters" dividers>
            <Box>
              <Stack>
                <h3>Filter by room:</h3>
                <Select
                  data-testid="pallet-room-filter"
                  onChange={(e) => setRoomFilterValue(e.target.value as string)}
                  value={roomFilterValue ?? ''}
                  defaultValue={''}
                >
                  <MenuItem value="">All</MenuItem>
                  {dropdownFilterOptions.rooms.map((option, index) => (
                    <MenuItem key={'room-' + index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack>
                <h3>Filter by BOL number:</h3>
                <Select
                  data-testid="pallet-bol-filter"
                  onChange={(e) => setBolNumberFilterValue(e.target.value)}
                  value={bolNumberFilterValue ?? ''}
                  defaultValue={''}
                >
                  <MenuItem value="">All</MenuItem>
                  {dropdownFilterOptions.bolNumbers.map((option, index) => (
                    <MenuItem
                      data-testid={'bol-number-item-' + index}
                      key={'bolNumber-' + index}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack>
                <h3>Filter by container:</h3>
                <Select
                  data-testid="pallet-container-filter"
                  onChange={(e) => setContainerFilterValue(e.target.value)}
                  value={containerFilterValue ?? ''}
                  defaultValue={''}
                >
                  <MenuItem value="">All</MenuItem>
                  {dropdownFilterOptions.containers.map((option, index) => (
                    <MenuItem key={'containerNumber-' + index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closeModalDialog({ filterBy: null })}>
              Cancel
            </Button>
            <Button
              data-testid="apply-filters-button"
              onClick={async () => {
                const filterBy: TableFilterObj = {
                  filterBy: {
                    room: roomFilterValue == '' ? null : roomFilterValue,
                    bolNumber:
                      bolNumberFilterValue == ''
                        ? null
                        : +(bolNumberFilterValue as string),
                    container:
                      containerFilterValue == '' ? null : containerFilterValue,
                  },
                };
                await closeModalDialog(filterBy);
              }}
            >
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          height: {
            xs: 'calc(100vh - 185px)',
            sm: 'calc(100vh - 205px)',
            md: 'calc(100vh - 415px)',
          },
          width: '100%',
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Stack>
              <Button
                sx={{ width: '100%', marginY: 1 }}
                data-testid={'pallet-picklist-filter-button'}
                onClick={() => setFilterModalOpen(true)}
                variant="contained"
              >
                Filter
              </Button>
            </Stack>
          </Grid>
          <StandardDataGrid
            testId="pick-pallets-table"
            loading={!!loadingId}
            columns={columns}
            rows={filteredPalletData}
            getRowId={(row) => row.id}
            initialSortModel={initialSortModel}
            disableRowSelectionOnClick={true}
            hideToolbar={mobileView}
          />
        </Container>
      </Box>
    </>
  );
};
