import React, { useContext, useMemo, useState } from 'react';
import PalletService from '../../Services/PalletService';
import MobilePage, { MobilePageTab } from '../../Components/MobilePage';
import useApiForm from '../../Hooks/useApiForm';

import BarcodeScannerFormInput from '../../Components/Forms/BarcodeScannerFormInput';
import { PalletPickList } from '../../Components/Pallet/PalletPickList';
import { MovePallets } from '../../Components/MovePallets';
import {
  Badge,
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import { PalletDetails } from '../../Models/Pallet';
import CloseIcon from '@mui/icons-material/Close';
import { TabPanel } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { Downloading } from '@mui/icons-material';
import useApiGet from '../../Hooks/useApiGet';
import { AuthContext } from '../../Providers/AuthProvider';
import DefinedMovements from '../../Components/Pallet/DefinedMovements';

export default function () {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const tabLists = ['pallet-picklist', 'defined-movement'];
  type Tab = typeof tabLists[number];
  const hash = location.hash.substring(1);
  const [tab, setTab] = useState<Tab>(
    tabLists.includes(hash) ? hash : tabLists[0]
  );
  const onTabChange = (tab: Tab) => {
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };

  const [scannedPallet, setScannedPallet] = useState<null | PalletDetails>(
    null
  );
  const [movePalletsActive, setMovePalletsActive] = useState<boolean>(false);

  const resetForms = () => {
    getPalletForm.setData('code', '');
    setScannedPallet(null);
  };

  const getPalletForm = useApiForm(
    PalletService.getByCodeDetailed,
    {
      code: '',
      detailed: true,
    },
    {
      onError: (message) => {
        getPalletForm.setErrors({ code: message });
      },
      onSuccess: (result: PalletDetails) => {
        if (result) setScannedPallet(result);
      },
      useSearchParams: true,
    }
  );

  const { data: pickingPallets, refresh: refreshPalletTable } = useApiGet(
    PalletService.getPalletsReadyForStaging,
    {
      params: { warehouseId: user?.currentWarehouseId },
    }
  );
  const {
    data: definedMovementPallets,
    refresh: refreshMovementPallets,
    loading: loadingMovements,
  } = useApiGet(PalletService.getDefinedMovementPallets, {
    params: { warehouseId: user?.currentWarehouseId },
  });

  const onPalletScan = () => {
    // if scanning and the current barcode field has focus we want it to lose focus for the second scan
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    getPalletForm.submit();
    resetForms();
  };

  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'pallet-picklist',
      icon: Downloading,
      complete: false,
      label: 'Pallet Pick List',
    },
    {
      id: 'defined-movement',
      icon: Downloading,
      complete: false,
      labelComponent: (
        <Badge
          badgeContent={definedMovementPallets?.length ?? 0}
          data-testid="badge-defined-movement"
          color="error"
          max={99}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          Defined Movements
        </Badge>
      ),
    },
  ];
  const checkAfterScanResponse = (res: { err: string } | undefined) => {
    if (res && res.err) return;
    setScannedPallet(null);
    setMovePalletsActive(false);
    // Make the pallet pick list refresh
    refreshPalletTable();
  };

  const movePalletDialogue = useMemo(() => {
    if (!scannedPallet) return null;

    setMovePalletsActive(true);

    return (
      <Dialog
        open={true}
        fullScreen
        data-testid="modal-dialog"
        scroll="paper"
        sx={{ margin: 0, padding: 0 }}
      >
        <DialogContent
          data-testid="MovePalletDialogue"
          dividers
          sx={{ margin: 0, padding: 0 }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setScannedPallet(null);
              setMovePalletsActive(false);
            }}
            aria-label="close"
            sx={{ position: 'absolute', left: '5px', top: '3px' }}
          >
            <CloseIcon />
          </IconButton>
          <MovePallets
            scannedPallet={scannedPallet}
            onAfterScan={(res) => checkAfterScanResponse(res)}
          />
        </DialogContent>
      </Dialog>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedPallet]);

  return (
    <MobilePage
      showOnDesktop
      maxWidth={false}
      sx={{
        paddingLeft: 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingTop: { xs: 1.5, sm: 2 },
      }}
      tabs={tabs}
      tab={tab}
      onTabChange={onTabChange}
    >
      <Box sx={{ marginTop: 3, marginBottom: 0, width: '100%' }}>
        <Container>
          <BarcodeScannerFormInput
            label="Pallet Barcode"
            id="code"
            form={getPalletForm}
            onBarcode={onPalletScan}
            onClear={() => resetForms()}
            disabledBackgroundInput={movePalletsActive}
          />
          {movePalletDialogue}
        </Container>
      </Box>

      <TabPanel
        value={'pallet-picklist'}
        sx={{ padding: 0, marginTop: 0, width: '100%' }}
      >
        <PalletPickList
          pickingPalletData={pickingPallets ?? []}
          refreshPalletTable={refreshPalletTable}
        />
      </TabPanel>
      <TabPanel
        value={'defined-movement'}
        sx={{ padding: 0, marginTop: 0, width: '100%' }}
      >
        <DefinedMovements
          palletsWithDefinedMovement={definedMovementPallets ?? []}
          refreshPalletTable={refreshPalletTable}
          refreshDefinedMovementTable={refreshMovementPallets}
          loading={loadingMovements}
        />
      </TabPanel>
    </MobilePage>
  );
}
