import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import CardTitle from '../../../Components/CardTitle';
import useApiGet from '../../../Hooks/useApiGet';
import { pagedParams } from '../../../Services/Api';
import { AuthContext } from '../../../Providers/AuthProvider';
import {
  ALLOW_EXPORT_LABELS,
  BillOfLadingWithCountsForCustomer,
  BOL_LABELS,
  BolStatus,
  BolStatusChip,
} from '../../../Models/BillOfLading';
import BillOfLadingService from '../../../Services/BillOfLadingService';
import StandardDataGrid from '../../../Components/StandardDataGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import CustomDateCalendar from '../../../Components/CustomDateCalendar';
import { friendlyDate } from '../../../Lib/utils';
import MobilePage from '../../../Components/MobilePage';
import useLocalStorage from '../../../Hooks/useLocalStorage';
import { Link } from 'react-router-dom';

/**
 * This page shows a list of BoL to the Operator so they can select a BoL to start loading
 */

export default function () {
  const { user } = useContext(AuthContext);
  const [filterSelectedDate, setFilterSelectedDate] =
    useLocalStorage<Date | null>(
      'CustomerPages.OutboundOrders.selectedDate',
      null,
      { sessionOnly: true, todayOnly: true }
    );

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    filterSelectedDate || new Date()
  );

  const { data, loading, refresh } = useApiGet(
    BillOfLadingService.getBillsOfLadingByDate,
    {
      params: {
        ...pagedParams,
        warehouseId: user?.currentWarehouseId,
        shipDate: selectedDate,
      },
    }
  );

  useEffect(() => {
    if (user?.currentWarehouseId) {
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.currentWarehouseId]);

  useMemo(() => {
    if (selectedDate) setFilterSelectedDate(selectedDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const columnVisibilityModel = {
    customerName: !user?.currentCustomerId,
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'BOL',
      minWidth: 50,
      maxWidth: 100,
      renderCell: (params: GridRenderCellParams) =>
        // If the user is a customer user, the BOL number should be a link to the customer BOL detail page
        user && user.roleNames?.some((r) => r == 'CUSTOMER USER') ? (
          <Link to={`${params.row.bolNumber}`}>{params.row.bolNumber}</Link>
        ) : (
          <Link to={`/bol/${params.row.bolNumber}`}>
            {params.row.bolNumber}
          </Link>
        ),
      valueGetter: (params: GridRenderCellParams) => params.row.bolNumber,
    },
    {
      field: 'customerOrderNo',
      headerName: 'Order No',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: 'consigneeName',
      headerName: 'Deliver To',
      minWidth: 250,
      maxWidth: 300,
    },
    {
      field: 'warehouseCode',
      headerName: 'Store',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'cartonsCount',
      headerName: 'Units',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'palletsCount',
      headerName: 'Pallets',
      minWidth: 50,
      maxWidth: 100,
    },
    {
      field: 'cartonsWeight',
      headerName: 'Weight',
      minWidth: 100,
      maxWidth: 150,
    },
    {
      field: 'canExportBol',
      headerName: 'Export?',
      valueGetter: (
        params: GridRenderCellParams<BillOfLadingWithCountsForCustomer>
      ) => ALLOW_EXPORT_LABELS[params.row.canExportBol ? 1 : 0],
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      maxWidth: 300,
      renderCell: (params: GridRenderCellParams) => (
        <BolStatusChip status={params.row.status as BolStatus} />
      ),
      valueGetter: (params: GridRenderCellParams) =>
        BOL_LABELS[params.row.status],
    },
  ];

  return (
    <MobilePage showTitle={false} maxWidth={false}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CardTitle
            title={`Outbound Orders on ${friendlyDate(selectedDate)}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={4} md={12} sm={12} xl={3}>
              <Card variant="outlined">
                <CardContent>
                  <CustomDateCalendar
                    initialDate={selectedDate}
                    onDateSelected={(date) => {
                      setSelectedDate(date!);
                    }}
                  ></CustomDateCalendar>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8} md={12} sm={12} xl={9}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <StandardDataGrid
                    testId="data-grid-bolsbydate-table"
                    rows={data?.items || []}
                    loading={loading}
                    columns={columns}
                    getRowId={(row) => row.id}
                    disableRowSelectionOnClick={true}
                    columnVisibilityModel={columnVisibilityModel}
                    noRowsMessage="No Outbound Orders found"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobilePage>
  );
}
