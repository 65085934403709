import { BillingCycle } from '../Models/Customer';
import { NetsuiteLog } from '../Models/NetsuiteLog';
import Api, { ServiceGetMethod, ServicePostMethod } from './Api';

const exportSalesOrdersToNetsuite: ServicePostMethod<
  { billingCycle: BillingCycle | null | undefined },
  null
> = (data, options) => {
  const opt = options ?? {};
  opt.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  return Api.post(
    'api/services/app/Netsuite/ExportSalesOrdersToNetsuite',
    data,
    opt
  );
};

const getExportLog: ServiceGetMethod<null, NetsuiteLog[]> = (options) =>
  Api.get(`api/services/app/Netsuite/GetExportLog`, options);

export { exportSalesOrdersToNetsuite, getExportLog };
