import React from 'react';
import useApiGet from '../../Hooks/useApiGet';
import { Pallet } from '../../Models/Pallet';
import PalletService from '../../Services/PalletService';
import PalletDetails from './PalletDetails';

export interface PalletDetailsForUserProps {
  palletId: number;
  backgroundColor?: string;
  onGotPalletDetails?: (palletDetails: Pallet) => void;
}

export const PalletDetailsForUser = ({
  palletId,
  backgroundColor,
  onGotPalletDetails,
}: PalletDetailsForUserProps) => {
  const { data: palletDetails } = useApiGet(PalletService.getPalletDetails, {
    params: {
      id: palletId,
    },
    onSuccess: (result) => {
      if (onGotPalletDetails != undefined) {
        onGotPalletDetails!(result);
      }
    },
  });

  return (
    <PalletDetails
      palletDetails={palletDetails}
      backgroundColor={backgroundColor}
    />
  );
};
