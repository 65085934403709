export interface RequiredTempState {
  value: string;
  label: string;
}

export const requiredTempStates: RequiredTempState[] = [
  { value: 'AMB', label: 'AMB - Ambient' },
  { value: 'BLAST', label: 'BLAST - Blast' },
  { value: 'CHD', label: 'CHD - Chilled' },
  { value: 'FZN', label: 'FZN - Frozen' },
];

// TODO: CreateBol API doesn't support BLANK and ANY for now. Add support for these 2 values in the API and allow these values in frontend.
export const requiredTempStatesForBolLine: RequiredTempState[] = [
  // { value: 'BLANK', label: 'Blank' },
  // { value: 'ANY', label: 'ANY - Temp' },
  { value: 'AMB', label: 'AMB - Ambient' },
  { value: 'BLAST', label: 'BLAST - Blast' },
  { value: 'CHD', label: 'CHD - Chilled' },
  { value: 'FZN', label: 'FZN - Frozen' },
];
