import { History } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import useApiGet from '../../Hooks/useApiGet';
import { getExportLog } from '../../Services/BillingService';
import usePermissions from '../../Hooks/usePermissions';
import { ValueMappings } from '../../Models/AuditLog';
import NetsuiteAuditLogItem from './NetsuiteAuditLogItem';

export interface NetsuiteAuditLogProps {
  tabIndex?: number;
  valueMappings?: ValueMappings;
}

export default function ({ tabIndex }: NetsuiteAuditLogProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const {
    data: items,
    loading,
    refresh,
  } = useApiGet(getExportLog, {
    params: null,
    noAutoFetch: true,
  });

  const onOpen = () => {
    setIsOpen(true);
    refresh();
  };

  const [canViewAudit] = usePermissions(['Audit.View', 'Billing.View']);
  if (!canViewAudit) {
    return null;
  }
  const filteredItems = useMemo(() => {
    if (!items || !searchText) {
      return items;
    }

    const searchLower = searchText.toLocaleLowerCase();
    return items.filter((item) =>
      item.methodName.toLocaleLowerCase().includes(searchLower)
    );
  }, [items, searchText]);

  return (
    <>
      <IconButton
        color="primary"
        data-testid="audit-log-open-btn"
        onClick={() => onOpen()}
        tabIndex={tabIndex}
      >
        <History />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth
        maxWidth={false}
      >
        <DialogTitle display="flex" justifyContent="space-between">
          <Box>Audit Log</Box>
          <TextField
            label="Search"
            margin="none"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          ) : !filteredItems?.length ? (
            <Box>No {searchText ? 'matching ' : ''}changes!</Box>
          ) : (
            <>
              {filteredItems.map((item) => (
                <NetsuiteAuditLogItem item={item} key={item.date} />
              ))}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
