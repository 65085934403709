import React from 'react';
import {
  Paper,
  Box,
  Button,
  Container,
  CircularProgress,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CardTitle from '../../Components/CardTitle';
import usePwaButton from '../../Hooks/usePwaButton';
import { useContext } from 'react';
import { AuthContext } from '../../Providers/AuthProvider';
import AuthService from '../../Services/AuthService';
import useApiForm from '../../Hooks/useApiForm';
import { ResponsiveContext } from '../../Providers/ResponsiveProvider';
import BarcodeScannerFormInput, {
  BarcodeResult,
  isValidBarcode,
} from '../../Components/Forms/BarcodeScannerFormInput';
import FormField from '../../Components/Forms/FormField';
import { toastError } from '../../Components/Toast';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
  title?: string;
  children?: React.ReactNode;
}

export default function ({ title, children }: LoginProps) {
  const pwa = usePwaButton();
  const authContext = useContext(AuthContext);
  const { mobileView } = useContext(ResponsiveContext);
  const nav = useNavigate();

  const form = useApiForm(
    AuthService.authenticate,
    {
      userNameOrEmailAddress: '',
      password: '',
      usedScanner: false,
    },
    {
      enableFormUpdatesWhenFocussed: true,
      // if there was a login error, display the error message under the username field
      // instead of using the default toast
      suppressError: true,
      onError: (message) => {
        // if we sent a barcode, reset the username/password field
        if (!form.data.userNameOrEmailAddress) {
          form.reset();
        }
        form.setErrors({ password: message });
      },
      // pass the token to the AuthContext, which will automatically refresh the authState
      // then the router will automatically replace this page with the correct route
      onSuccess: (token) => {
        authContext.setToken(token);
      },
    }
  );

  const isValid = mobileView
    ? isValidBarcode(form.data.password)
    : form.data.userNameOrEmailAddress && form.data.password;

  const getIsBarCodeLoginEnabled = () => {
    return process.env.REACT_APP_BARCODE_LOGIN_ENABLED === 'true' ?? false;
  };

  const isBarcodeLoginEnabled = getIsBarCodeLoginEnabled();

  // this Login component is rendered in place of any route if the user is not logged in,
  // which will always be true on first load while we're checking the auth token,
  // so display a loading spinner instead of the login form when first checking the auth token...
  if (authContext.loggingIn) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-testid="login-loading"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="xs">
      <Paper
        sx={{
          p: 2,
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box component="form" onSubmit={form.submit} noValidate>
          <CardTitle title={!title ? 'Sign In' : title} />

          {!children && (
            <Typography sx={{ mb: 1 }} variant="body1">
              {' '}
              Scan the barcode on your ID or fill in the details below:
            </Typography>
          )}

          {children}

          {(!isBarcodeLoginEnabled || !mobileView) && (
            <FormField
              form={form}
              id="userNameOrEmailAddress"
              type={
                form.processing && !form.data.password ? 'password' : 'text'
              }
              label="Username"
              required
              autoFocus
            />
          )}
          <BarcodeScannerFormInput
            label={
              mobileView && isBarcodeLoginEnabled ? 'ID Barcode' : 'Password'
            }
            id="password"
            type="password"
            form={form}
            onBarcode={(barcodeResult: BarcodeResult) => {
              // Getting the flag again, as there was an issue with getting the env var from outside this callback
              const isBarcodeLoginEnabled = getIsBarCodeLoginEnabled();

              if (isBarcodeLoginEnabled) {
                form.setData(
                  'usedScanner',
                  barcodeResult.entryMethod == 'scanner'
                );
                form.submit();
              } else if (barcodeResult.entryMethod == 'scanner') {
                toastError('Barcode login is not enabled');
              } else {
                // barcode is not enabled and the entryMethod is manual, so assume user has pressed enter key
                form.submit();
              }
            }}
            hideSearchButton
            hideClearButton={!mobileView}
            autoFocus={false}
          />
          <LoadingButton
            type="submit"
            loading={form.processing}
            fullWidth
            variant="contained"
            disabled={!isValid}
            sx={{ mt: 3, mb: 2 }}
            data-testid="sign-in-button"
          >
            Sign In
          </LoadingButton>
          <Button
            fullWidth
            variant="text"
            onClick={() => {
              nav('/reset-password');
            }}
            sx={{ mb: 2 }}
          >
            Forgot your Password?
          </Button>
        </Box>
      </Paper>
      {pwa.show && (
        <Button
          fullWidth
          variant="text"
          startIcon={<pwa.Icon />}
          onClick={pwa.onClick}
          sx={{ mt: 3, mb: 2 }}
        >
          Install PWA
        </Button>
      )}
    </Container>
  );
}
