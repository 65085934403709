import Api, {
  PagedAndSortedRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';
import {
  ASN,
  ASNStatus,
  ASNWithCounts,
  ASNWithCountsForCustomer,
  UpdateASNRequest,
} from '../Models/ASN';
import { Pallet } from '../Models/Pallet';

export interface ASNListRequest {
  statuses?: ASNStatus[];
  warehouseId?: number | null;
  customerId?: number;
}
export interface PagedASNListForCustomerRequest extends PagedAndSortedRequest {
  warehouseId?: number | null;
  queryDate?: Date | null;
}

export interface ASNDetailRequest {
  asnNumber: string;
}

export interface FindPalletInASNRequest {
  asnId?: number;
  code: string;
}
export interface VerifyPalletForASNRequest {
  asnId: number;
  palletId: number;
}
const markAsReviewed: ServicePostMethod<{ asnId: number }, ASN> = (
  { asnId },
  options
) => Api.put(`api/services/app/ASN/MarkAsReviewed?asnId=${asnId}`, options);

const markAsArrived: ServicePostMethod<{ asnId: number }, ASN> = (
  { asnId },
  options
) => Api.put(`api/services/app/ASN/MarkAsArrived?asnId=${asnId}`, options);

const verificationCompleteASN: ServicePostMethod<{ asnId: number }, void> = (
  { asnId },
  options
) =>
  Api.put(
    `api/services/app/ASN/VerificationCompleteASN?asnId=${asnId}`,
    options
  );

const verifyPalletForASN: ServicePostMethod<
  VerifyPalletForASNRequest,
  Pallet
> = (data, options) =>
  Api.put('api/services/app/ASN/VerifyPalletForASN', data, options);

const findPalletInASNByCode: ServicePostMethod<
  FindPalletInASNRequest,
  Pallet
> = (data, options) =>
  Api.put('api/services/app/ASN/FindPalletInASNByCode', data, options);

const getAllWithCounts: ServiceGetMethod<ASNListRequest, ASNWithCounts[]> = (
  options
) => Api.get('api/services/app/ASN/GetAllWithCounts', options);

const getAllWithCountsForCustomer: ServiceGetMethod<
  PagedASNListForCustomerRequest,
  PagedResult<ASNWithCountsForCustomer>
> = (options) =>
  Api.get('api/services/app/ASNExternal/GetAllWithCounts', options);

const getASNDetail: ServiceGetMethod<ASNDetailRequest, ASNWithCounts> = (
  options
) => Api.get('api/services/app/ASN/GetASNDetail', options);

const getASNDetailForCustomer: ServiceGetMethod<
  ASNDetailRequest,
  ASNWithCountsForCustomer
> = (options) => Api.get('api/services/app/ASNExternal/GetASNDetail', options);

const getASNListByPallet: ServiceGetMethod<{ palletId: number }, ASN[]> = (
  options
) => Api.get('api/services/app/ASN/GetASNListByPallet', options);

const updateASNStatus: ServicePostMethod<
  { asnId: number; status: ASNStatus },
  ASN
> = (options) => Api.put('api/services/app/ASN/UpdateASNStatus', options);

const setCompletedDates: ServicePostMethod<UpdateASNRequest, ASN> = (
  data,
  options
) => Api.put('api/services/app/ASN/setCompletedDates', data, options);

export default {
  markAsReviewed,
  markAsArrived,
  getAllWithCounts,
  getAllWithCountsForCustomer,
  getASNDetail,
  getASNDetailForCustomer,
  findPalletInASNByCode,
  verificationCompleteASN,
  verifyPalletForASN,
  getASNListByPallet,
  updateASNStatus,
  setCompletedDates,
};
