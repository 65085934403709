import React from 'react';
import { PalletForCustomer } from '../../../Models/Pallet';
import useApiGet from '../../../Hooks/useApiGet';
import PalletService from '../../../Services/PalletService';
import PalletDetails from '../../../Components/PalletDetails/PalletDetails';

export interface PalletDetailsForCustomerProps {
  palletId: number;
  onGotPalletDetails?: (palletDetails: PalletForCustomer) => void;
}

export const PalletDetailsForCustomer = ({
  palletId,
  onGotPalletDetails,
}: PalletDetailsForCustomerProps) => {
  const { data: palletDetails } = useApiGet(
    PalletService.getPalletDetailsForCustomer,
    {
      params: {
        id: palletId,
      },
      onSuccess: (result) => {
        if (onGotPalletDetails != undefined) {
          onGotPalletDetails!(result);
        }
      },
    }
  );

  return <PalletDetails palletDetails={palletDetails} />;
};
