import { Box, Chip, CircularProgress, Fab, Grid, Stack } from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BillOfLading } from '../../Models/BillOfLading';
import { tryFormatDateStr } from '../../Lib/utils';
import {
  DataGridPro,
  getGridStringOperators,
  GridActionsCellItem,
  GridColDef,
  GridRowClassNameParams,
  GridRowId,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import CardTitle from '../../Components/CardTitle';
import useApiGet from '../../Hooks/useApiGet';
import CartonService from '../../Services/CartonService';
import { AuthContext } from '../../Providers/AuthProvider';
import { StockOnHandPalletToFulfilBol } from '../../Models/StockOnHand';
import BolContainerService from '../../Services/BolContainerService';
import { Add } from '@mui/icons-material';
import useSingleClickEditDataGrid from '../../Hooks/useSingleClickEditDataGrid';
import BillOfLadingService from '../../Services/BillOfLadingService';
import AssignContainerPallets from '../../Components/BillOfLading/PickList/AssignContainerPallets';
import PickPalletsButton from '../../Components/BillOfLading/PickList/PickPalletsButton';
import { confirmModalDialog } from '../../Components/ModalDialog';
import { BillOfLadingLine } from '../../Models/BillOfLadingLine';
import { GridRowParams } from '@mui/x-data-grid-premium';
import { BOLContainer, isContainerClosed } from '../../Models/BOLContainer';
import EditContainer from '../../Components/Container/EditContainer';
import { useParams } from 'react-router-dom';
import UnassignAllButton from '../../Components/BillOfLading/PickList/UnassignAllButton';
import usePermissions from '../../Hooks/usePermissions';

interface BolLineWithSelectedWeightAndQty extends BillOfLadingLine {
  displaySelectedWeight?: number;
  displaySelectedQty?: number;
}
interface BolsPickListTabProps {
  bol?: BillOfLading;
  containerTabRefresh?: () => void;
}

export default function ({ bol, containerTabRefresh }: BolsPickListTabProps) {
  const DECIMAL_PLACES_WEIGHT = 2;

  const { user } = useContext(AuthContext);
  const [canUnassignAll] = usePermissions([
    'BOL.Carton.ClearAllAssignedCartons',
  ]);
  const [selectedBol, setSelectedBol] = useState<BillOfLading | undefined>(bol);

  const [selectedBolLine, setSelectedBolLine] = useState<
    BolLineWithSelectedWeightAndQty | undefined
  >(undefined);

  const [selectedItems, setSelectedItems] = useState<
    StockOnHandPalletToFulfilBol[]
  >([]);

  // Edit container dialog members.
  const [openEditContainerDialog, setOpenEditContainerDialog] = useState(false);
  const containerToEditRef = useRef<BOLContainer>();

  const { bolNumber: bolNumberParam } = useParams<{ bolNumber: string }>();
  const { refresh: refreshBolFromParam } = useApiGet(
    BillOfLadingService.getFullByBolNumber,
    {
      params: {
        bolNumber: bolNumberParam!,
        warehouseId: user?.currentWarehouseId,
      },
      onSuccess: (bol) => {
        setSelectedBol(bol);
      },
      noAutoFetch: true,
      noFetchOnParameterUpdate: true,
    }
  );

  const stockOnHandApiRef = useGridApiRef();

  const rowSelectionModel = useMemo<GridRowSelectionModel>(() => {
    if (!selectedItems.length) return [];

    return selectedItems.reduce(
      (prevRow: GridRowId[], currRow): GridRowSelectionModel => {
        prevRow.push(
          `${currRow.pallet.id}-${currRow.productId}-${currRow.batchNumber}`
        );
        return prevRow;
      },
      [] as GridRowId[]
    );
  }, [selectedItems]);

  const bolLinesColumnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'line',
        headerName: 'Line',
        flex: 1,
        minWidth: 70,
      },
      {
        field: 'basisProductId',
        headerName: 'Product ID',
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'productDescription',
        headerName: 'Product',
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'cartonsRequired',
        headerName: 'QTY',
        flex: 1,
      },
      {
        field: 'weightRequired',
        headerName: 'Weight',
        align: 'right',
        flex: 1,
        minWidth: 100,
        renderCell: (params) =>
          `${params.row.weightRequired.toFixed(DECIMAL_PLACES_WEIGHT)}kg`,
      },
      {
        field: 'displaySelectedWeight',
        headerName: 'Selected Weight',
        flex: 1,
        minWidth: 120,
        align: 'right',
        renderCell: (params) => {
          return (
            <div style={{ textAlign: 'right' }}>
              {`${(params.row.displaySelectedWeight ?? 0).toFixed(
                DECIMAL_PLACES_WEIGHT
              )}kg`}
            </div>
          );
        },
      },
      {
        field: 'displaySelectedQty',
        headerName: 'Selected QTY',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'uom',
        headerName: 'UOM',
        flex: 1,
      },
      {
        field: 'plant',
        headerName: 'Plant Code',
        flex: 1,
        minWidth: 90,
      },
      {
        field: 'lowDate',
        headerName: 'Low Date',
        flex: 1,
        minWidth: 80,
        renderCell: (params) =>
          tryFormatDateStr(params.row.lowDate, 'dd/MM/yyyy'),
      },
      {
        field: 'highDate',
        headerName: 'High Date',
        flex: 1,
        minWidth: 90,
        renderCell: (params) =>
          tryFormatDateStr(params.row.highDate, 'dd/MM/yyyy'),
      },
      {
        field: 'batch',
        headerName: 'Batch',
        flex: 1,
        minWidth: 60,
      },
      {
        field: 'requiredTempState',
        headerName: 'Temp State',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'customColumn',
        headerName: 'Country',
        flex: 1,
        minWidth: 90,
        renderCell: () => selectedBol?.countryCode,
      },
      {
        field: 'childBolNumber',
        headerName: 'Child BOL',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'childBolLine',
        headerName: 'Child BOL Line',
        flex: 1,
        minWidth: 110,
      },
    ],
    [selectedBol]
  );

  const {
    data: sohData,
    loading,
    refresh,
  } = useApiGet(CartonService.getStockOnHandForBol, {
    params: {
      warehouseId: user?.currentWarehouseId,
      bolId: selectedBol?.id || 0,
    },
    noAutoFetch: true,
    noFetchOnParameterUpdate: true,
  });

  const {
    data: containers,
    loading: containersLoading,
    refresh: refreshContainers,
  } = useApiGet(BolContainerService.getContainersForBol, {
    params: {
      billOfLadingId: selectedBol?.id || 0,
    },
    noAutoFetch: true,
    noFetchOnParameterUpdate: true,
  });

  useEffect(() => {
    if (selectedBol) {
      refresh({ clear: true });
      refreshContainers({ clear: true });
    }
    // find and auto-select the first line of the BoL
    const firstLine = [...(selectedBol?.lines || [])].sort(
      (a, b) => a.line - b.line
    )[0];
    setSelectedBolLine(firstLine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBol, setSelectedBolLine]);

  const unAssignPalletHandle = (data: StockOnHandPalletToFulfilBol) => {
    return confirmModalDialog({
      title: 'Unassign Pallet',
      acceptButtonLabel: 'Yes',
      declineButtonLabel: 'No',
      acceptButtonCountdown: 0,
      declineButtonCountdown: 0,
      content: (
        <Box textAlign={'center'}>
          <h3>Are you sure you want to unassign this pallet?</h3>
        </Box>
      ),
      onAccept: () => {
        CartonService.unassignCartons({
          PalletId: data.pallet.id,
          ProductCode: data.productId.toString(),
          BatchNumber: data.batchNumber,
        }).then(() => {
          refreshBolFromParam();
          if (containerTabRefresh) containerTabRefresh();
        });
      },
    });
  };

  const stringOperators = getGridStringOperators().filter((op) =>
    ['equals'].includes(op.value)
  );

  const containerActions = useMemo(
    () => (params: GridRowParams<BOLContainer>) => {
      const actions = [];

      const deleteAction = (
        <GridActionsCellItem
          label="Delete"
          onClick={async () => {
            const deletionMessage = `Are you sure you want to delete container '${params.row.code}' from BOL '${params.row.bolNumber}'?`;
            const confirmed = await confirmModalDialog({
              title: '',
              acceptButtonLabel: 'Delete',
              severity: 'warning',
              content: (
                <Box textAlign={'center'}>
                  <h3>{deletionMessage}</h3>
                </Box>
              ),
            });
            if (confirmed) {
              const [, error] = await BolContainerService.deleteContainer(
                params.row.id
              );
              if (!error) {
                refreshContainers();
              }
            }
          }}
          showInMenu
        />
      );
      if (params.row.isDeleteAble) actions.push(deleteAction);

      const editAction = (
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            containerToEditRef.current = params.row;
            setOpenEditContainerDialog(true);
          }}
          showInMenu
        />
      );
      actions.push(editAction);

      return actions;
    },
    [refreshContainers]
  );

  const containersColumnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'code',
        headerName: 'Number',
        flex: 0.6,
      },
      {
        field: 'type',
        headerName: 'Size',
        flex: 0.5,
      },
      {
        field: 'totalPallets',
        headerName: '# Pallets',
        flex: 0.5,
        renderCell: (params) => params.row.totalPallets || 0,
      },
      {
        field: 'weight',
        headerName: 'Weight',
        flex: 0.5,
        renderCell: (params) =>
          params.row.totalWeight ? `${params.row.totalWeight}kg` : '0',
      },
      {
        field: 'location',
        headerName: 'Location',
        flex: 0.8,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        getActions: containerActions,
      },
    ],
    [containerActions]
  );

  const palletsColumnDefs = useMemo<GridColDef[]>(
    () => [
      {
        field: 'bolNumber',
        headerName: 'BOL',
        filter: true,
        renderCell: (params) => {
          if (!params.row?.bolNumber && !params.row?.billOfLadingId) {
            return null;
          }
          return params.row?.billOfLadingId === selectedBol?.id ? (
            isContainerClosed(params.row?.plannedBOLContainerStatus) ? (
              <Chip size={'small'} label={selectedBol?.bolNumber} />
            ) : (
              <Chip
                size={'small'}
                label={selectedBol?.bolNumber}
                onDelete={() => unAssignPalletHandle(params.row)}
              />
            )
          ) : (
            <Chip size={'small'} label={params.row.bolNumber} />
          );
        },
      },
      {
        field: 'code',
        headerName: 'Pallet',
        flex: 1,
        valueGetter: (params) => params.row.pallet.code,
      },
      {
        field: 'productId',
        headerName: 'Product Code',
        flex: 0.75,
      },
      {
        field: 'productDescription',
        headerName: 'Product',
        flex: 1,
      },
      {
        field: 'qty',
        headerName: 'QTY',
        flex: 0.2,
      },
      {
        field: 'weight',
        headerName: 'Weight',
        flex: 1,
        renderCell: (params) => `${params.row.weight}kg`,
      },
      {
        field: 'location',
        headerName: 'Location',
        flex: 1,
        valueGetter: (params) => params.row.pallet.location,
      },
      {
        field: 'palletsByLocation',
        headerName: '#',
        description: 'Pallets at Location',
        flex: 0.2,
      },
      {
        field: 'depth',
        headerName: 'Depth',
        flex: 0.2,
        valueGetter: (params) => params.row.pallet.depth,
      },
      {
        field: 'blockingPallets',
        headerName: 'Blocking Pallets',
        flex: 1,
      },
      {
        field: 'plantCode',
        headerName: 'Plant Code',
        flex: 1,
        filterOperators: stringOperators,
      },
      {
        field: 'lowDate',
        headerName: 'Low Date',
        flex: 1,
        renderCell: (params) =>
          tryFormatDateStr(params.row.lowDate, 'dd/MM/yyyy'),
      },
      {
        field: 'highDate',
        headerName: 'High Date',
        flex: 1,
        renderCell: (params) =>
          tryFormatDateStr(params.row.highDate, 'dd/MM/yyyy'),
      },
      {
        field: 'batchNumber',
        headerName: 'Batch',
        flex: 1,
      },
      {
        field: 'requiredTempState',
        headerName: 'Temp State',
        flex: 1,
      },
      {
        field: 'eligibility',
        headerName: 'Country',
        flex: 1,
      },
      {
        field: 'allowLoadOut',
        headerName: 'Allow Load Out',
        renderCell: (params) => (params.row.allowLoadOut ? 'YES' : 'NO'),
      },
      {
        field: 'isLostCartons',
        headerName: 'Hold Status',
        renderCell: (params) =>
          params.row.isLostCartons ? 'Lost Carton(s)' : '',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedBol]
  );

  const AddContainerButton = () => {
    const addContainerRow = async () => {
      await BolContainerService.addContainerToBol({
        billOfLadingId: selectedBol?.id || 0,
      });

      refreshContainers();

      return true;
    };

    return (
      <Fab
        color="primary"
        size="medium"
        onClick={addContainerRow}
        id="bolContainerAddButton"
        sx={{
          position: 'absolute',
          right: 0,
          top: -16,
        }}
      >
        <Add />
      </Fab>
    );
  };

  const singleClickEdit = useSingleClickEditDataGrid();

  /**
   * Filters the Stock on Hand (SOH) data based on the selected Bill of Lading (BOL) line.
   *
   * @returns {Array} An array of filtered SOH items that match the criteria of the selected BOL line.
   *
   * The filtering criteria include:
   * - The product ID of the SOH item must match the basis product ID of the selected BOL line.
   * - If a batch number is specified in the selected BOL line, the SOH item's batch number must match.
   * - If a low date is specified in the selected BOL line, the SOH item's low date must be greater than or equal to the selected low date.
   * - If a high date is specified in the selected BOL line, the SOH item's high date must be less than or equal to the selected high date.
   *
   * The filtering is memoized to improve performance and will only recompute when `selectedBolLine` or `sohData` changes.
   *
   * @param {Object} selectedBolLine - The selected Bill of Lading line containing filtering criteria.
   * @param {Array} sohData - The array of Stock on Hand data to be filtered.
   */
  const filteredSohByBolLineId = useMemo(() => {
    return (
      (selectedBolLine?.basisProductId &&
        sohData?.filter((sohItem) => {
          const selectedLowDate = selectedBolLine.lowDate
            ? new Date(selectedBolLine.lowDate)
            : null;
          const selectedHighDate = selectedBolLine.highDate
            ? new Date(selectedBolLine.highDate)
            : null;
          const sohLowDate = sohItem.lowDate ? new Date(sohItem.lowDate) : null;
          const sohHighDate = sohItem.highDate
            ? new Date(sohItem.highDate)
            : null;

          return (
            sohItem.productId === selectedBolLine.basisProductId &&
            (!selectedBolLine.batch ||
              !sohItem.batchNumber ||
              sohItem.batchNumber == selectedBolLine.batch) &&
            (selectedLowDate && sohLowDate
              ? sohLowDate >= selectedLowDate
              : true) &&
            (selectedHighDate && sohHighDate
              ? sohHighDate <= selectedHighDate
              : true)
          );
        })) ||
      []
    );
  }, [selectedBolLine, sohData]);
  const calculateSelectedWeight = (
    sohItems: StockOnHandPalletToFulfilBol[],
    initialWeight = 0
  ) => {
    return sohItems.reduce((accumulator, selectedItem) => {
      return accumulator + selectedItem.weight;
    }, initialWeight);
  };
  const calculateSelectedQty = (
    sohItems: StockOnHandPalletToFulfilBol[],
    initialQty = 0
  ) => {
    return sohItems.reduce((accumulator, selectedItem) => {
      return accumulator + selectedItem.qty;
    }, initialQty);
  };
  const selectedItemsByBolLine = (
    items: StockOnHandPalletToFulfilBol[],
    onlyAssignedItems = false
  ) => {
    const allItems = items.filter(
      (item) =>
        item.productId === selectedBolLine?.basisProductId &&
        (!item.batchNumber ||
          !selectedBolLine?.batch ||
          item.batchNumber === selectedBolLine?.batch)
    );
    return onlyAssignedItems
      ? allItems.filter(
          (item) =>
            item.billOfLadingId && item.billOfLadingId == selectedBol?.id
        )
      : allItems.filter(
          (item) =>
            item.billOfLadingId == null ||
            item.billOfLadingId !== selectedBol?.id
        );
  };
  useEffect(() => {
    if (filteredSohByBolLineId) {
      const selectedSoh =
        selectedItemsByBolLine(filteredSohByBolLineId, true) ?? [];
      setSelectedItems([...selectedSoh, ...selectedItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSohByBolLineId, selectedBol]);

  useEffect(
    () => {
      if (!selectedBolLine) return;
      const clonedBolLine = selectedBol?.lines?.find(
        (l) => l.id === selectedBolLine.id
      ) as BolLineWithSelectedWeightAndQty;
      if (!clonedBolLine) return;

      const notAssignedItems = selectedItemsByBolLine(selectedItems);
      const assignedItems = selectedItemsByBolLine(sohData ?? [], true);
      clonedBolLine.selectedWeight =
        assignedItems.length > 0 ? calculateSelectedWeight(assignedItems) : 0;
      clonedBolLine.displaySelectedWeight = calculateSelectedWeight(
        notAssignedItems,
        clonedBolLine.selectedWeight
      );

      clonedBolLine.selectedQty =
        assignedItems.length > 0 ? calculateSelectedQty(assignedItems) : 0;
      clonedBolLine.displaySelectedQty = calculateSelectedQty(
        notAssignedItems,
        clonedBolLine.selectedQty
      );

      setSelectedBolLine(clonedBolLine);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedItems, selectedBolLine, selectedBol]
  );

  const stockOnHandRowClass = (params: GridRowClassNameParams) => {
    const nonMatchingCartonsClass = `stock-on-hand--non_matching_cartons--${params.row.hasNonMatchingCartons}`;
    // CPK-582 If a row is shaded red based on hold status, this should not be overwritten by subsequent rules
    if (!params.row.allowLoadOut) {
      return `stock-on-hand--allow_load_out--${params.row.allowLoadOut}`;
    }
    if (params.row.isLostCartons) {
      return `stock-on-hand--hold_status--${params.row.isLostCartons}`;
    }

    return nonMatchingCartonsClass;
  };

  return (
    <>
      {!selectedBol && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {selectedBol && (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
          columns={{ lg: 12, xl: 12 }}
          width={'100%'}
        >
          <Grid lg={12} xl={8} container item>
            <Grid item xs={12}>
              <Box>
                <CardTitle
                  title="Bills of Lading Lines"
                  rightChild={
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      gap={1}
                    >
                      <PickPalletsButton bol={selectedBol} />
                      {canUnassignAll && (
                        <UnassignAllButton
                          bol={selectedBol}
                          onAfterUnassigned={() => {
                            setSelectedItems([]);
                            refreshBolFromParam();
                            if (containerTabRefresh) containerTabRefresh();
                          }}
                        />
                      )}
                    </Stack>
                  }
                />

                {selectedBol.lines && (
                  <Box sx={{ height: 300, width: '100%', marginTop: 1 }}>
                    <DataGridPro
                      data-testid={'bol-lines'}
                      disableMultipleRowSelection
                      sx={{ backgroundColor: 'white' }}
                      hideFooter={true}
                      columns={bolLinesColumnDefs}
                      rows={selectedBol.lines}
                      initialState={{
                        sorting: {
                          sortModel: [{ field: 'line', sort: 'asc' }],
                        },
                      }}
                      getRowId={(row) => row.id}
                      onRowSelectionModelChange={(rowSelectionModel) => {
                        const selectedId =
                          (rowSelectionModel[0] as number) || 0;
                        const selectedItem = selectedBol?.lines?.find(
                          (l) => l.id == selectedId
                        );
                        setSelectedBolLine(selectedItem);
                        setSelectedItems([]);
                      }}
                      rowSelectionModel={selectedBolLine?.id}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid lg={12} xl={4} container item>
            <Grid item xs={12}>
              <Box>
                <CardTitle
                  title="Container(s)"
                  loading={containersLoading}
                  sx={{ height: '36.5px' }}
                />
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <AddContainerButton />
                </div>
                <Box sx={{ height: 300, width: '100%', marginTop: 1 }}>
                  <DataGridPro
                    data-testid="bol-containers"
                    sx={{
                      backgroundColor: 'white',
                      '& .container--status--2': {
                        backgroundColor: '#808080',
                        '&:hover': {
                          backgroundColor: '#808080',
                        },
                      },
                      '& .container--status--3': {
                        backgroundColor: '#808080',
                        '&:hover': {
                          backgroundColor: '#808080',
                        },
                      },
                      '& .container--status--4': {
                        backgroundColor: '#808080',
                        '&:hover': {
                          backgroundColor: '#808080',
                        },
                      },
                      '& .container--status--5': {
                        backgroundColor: '#808080',
                        '&:hover': {
                          backgroundColor: '#808080',
                        },
                      },
                      minHeight: 150,
                      maxHeight: 300,
                    }}
                    hideFooter={true}
                    columns={containersColumnDefs}
                    rows={containers ?? []}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                      },
                    }}
                    loading={containersLoading}
                    cellModesModel={singleClickEdit.cellModesModel}
                    onCellModesModelChange={
                      singleClickEdit.handleEditCellModesModelChange
                    }
                    onCellClick={singleClickEdit.handleEditCellClick}
                    getRowClassName={(params) =>
                      `container--status--${params.row.status}`
                    }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid lg={12} container item>
            <Grid item xs={12}>
              <Box>
                <CardTitle title="Stock on hand" loading={loading} />
                <div
                  style={{
                    position: 'relative',
                    marginTop: 0,
                  }}
                >
                  <AssignContainerPallets
                    containers={containers}
                    selectedPallets={selectedItems.filter(
                      (s) => !s.billOfLadingId
                    )}
                    bolLine={selectedBolLine?.id ?? 0}
                    onRefresh={() => {
                      setSelectedItems([]);
                      refreshBolFromParam();
                      if (containerTabRefresh) containerTabRefresh();
                    }}
                  />
                </div>
                <Box sx={{ height: 500, width: '100%', marginTop: 1 }}>
                  <DataGridPro
                    experimentalFeatures={{ lazyLoading: true }}
                    apiRef={stockOnHandApiRef}
                    data-testid="stock-on-hand"
                    sx={{
                      backgroundColor: 'white',
                      '& .stock-on-hand--non_matching_cartons--true': {
                        backgroundColor: '#f9d2b3',
                        '&:hover': {
                          backgroundColor: 'rgba(249, 210, 179, 0.8)',
                        },
                      },
                      '& .stock-on-hand--allow_load_out--false': {
                        backgroundColor: '#FF6969',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 105, 105, 0.8)',
                        },
                      },
                      '& .stock-on-hand--hold_status--true': {
                        backgroundColor: '#cd1ddc',
                        '&:hover': {
                          backgroundColor: 'rgba(170,25,182)',
                        },
                      },
                    }}
                    hideFooter
                    columns={palletsColumnDefs}
                    rows={filteredSohByBolLineId ?? []}
                    checkboxSelection
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'code', sort: 'asc' }],
                      },
                    }}
                    isRowSelectable={(params) =>
                      !params.row.billOfLadingId &&
                      params.row.allowLoadOut &&
                      !params.row.isLostCartons
                    }
                    getRowClassName={(params) => stockOnHandRowClass(params)}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      const selectedData = filteredSohByBolLineId?.filter(
                        (d) =>
                          newRowSelectionModel.indexOf(
                            `${d.pallet.id}-${d.productId}-${d.batchNumber}`
                          ) > -1
                      );
                      setSelectedItems(selectedData ?? []);
                    }}
                    rowSelectionModel={rowSelectionModel}
                    getRowId={(row) =>
                      `${row.pallet.id}-${row.productId}-${row.batchNumber}`
                    }
                    loading={loading}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
      {openEditContainerDialog && (
        <EditContainer
          bolContainer={containerToEditRef.current || null}
          open={openEditContainerDialog}
          onClose={() => setOpenEditContainerDialog(false)}
          onSuccess={() => {
            refreshContainers();
            setOpenEditContainerDialog(false);
          }}
        />
      )}
    </>
  );
}
