import {
  BillOfLadingWithCounts,
  BillOfLading,
  BOLOrderType,
  BolStatus,
  BillOfLadingWithCountsAndPalletDetails,
  BillOfLadingWithCountsForCustomer,
  BillOfLadingForCustomerWithContainerAndProducts,
} from '../Models/BillOfLading';
import { BillOfLadingLine } from '../Models/BillOfLadingLine';
import Api, {
  PagedAndSortedSearchRequest,
  PagedResult,
  ServiceGetMethod,
  ServicePostMethod,
} from './Api';

/**
 * This Service provides api endpoints for interacting with its related entity via the Api Service
 *
 * Each method extends the ServiceGetMethod<Response> or ServicePostMethod<Data, Response> interfaces
 * so that they can be consumed in a standardised way using the useApiGet() and useApiForm() hooks
 */

const getBillsOfLading: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<BillOfLadingWithCounts>
> = (options) =>
  Api.get('api/services/app/BillOfLading/GetAllWithCounts', options);

const getBillsOfLadingByDate: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<BillOfLadingWithCountsForCustomer>
> = (options) =>
  Api.get('api/services/app/BillOfLadingExternal/GetAllByShipDate', options);

const getFullByBolNumber: ServiceGetMethod<
  GetFullBolRequest,
  BillOfLadingWithCountsAndPalletDetails
> = (options) =>
  Api.get('api/services/app/BillOfLading/GetFullWithCounts', options);

const getBillOfLadingByIdForCustomer: ServiceGetMethod<
  { bolNumber: string },
  BillOfLadingForCustomerWithContainerAndProducts
> = (options) =>
  Api.get(
    'api/services/app/BillOfLadingExternal/GetBillOfLadingDetails',
    options
  );

const getByRemoteId: ServicePostMethod<{ bolNumber: string }, BillOfLading> = (
  { bolNumber },
  options
) =>
  Api.get(
    'api/services/app/BillOfLading/GetByRemoteId?orderNumber=' + bolNumber,
    options
  );

const getBOLOrderTypes: ServiceGetMethod<void, BOLOrderType[]> = (options) =>
  Api.get('api/services/app/BillOfLading/GetAllOrderTypes', options);

const markAsReady: ServicePostMethod<{ bolId: number }, void> = (
  { bolId },
  options
) =>
  Api.put('api/services/app/BillOfLading/MarkAsReady?bolId=' + bolId, options);

const markAsReadyToPick: ServicePostMethod<
  { bolId: number; warehouseId: number | null },
  void
> = (data, options) =>
  Api.put('api/services/app/BillOfLading/MarkAsReadyToPick', data, options);

const generatePdf: ServicePostMethod<TestBolPdfRequest, string> = (
  data,
  options
) => Api.put('api/services/app/BillOfLading/GenerateBOLPdf', data, options);

const getAreAllBOLContainersBeyondCompletedStatus: ServicePostMethod<
  { billOfLadingId: number },
  void
> = ({ billOfLadingId }, options) =>
  Api.get(
    'api/services/app/BillOfLading/AreAllBOLContainersBeyondCompletedStatus?billOfLadingId=' +
      billOfLadingId,
    options
  );

const markAsLoaded: ServicePostMethod<{ billOfLadingId: number }, boolean> = (
  { billOfLadingId },
  options
) =>
  Api.put(
    'api/services/app/BillOfLading/MarkAsLoaded?billOfLadingId=' +
      billOfLadingId,
    options
  );

const toleranceCheckForBol: ServiceGetMethod<
  { billOfLadingId: number },
  BillOfLading
> = (options) =>
  Api.get('api/services/app/BillOfLading/ToleranceCheckForBol', options);

const runToleranceCheck: ServicePostMethod<
  { warehouseId?: number },
  boolean
> = ({ warehouseId }, options) =>
  Api.post(
    'api/services/app/BillOfLading/RunToleranceCheck?warehouseId=' +
      warehouseId,
    options
  );

const countToleranceFailBOLs: ServiceGetMethod<
  { warehouseId?: number },
  number
> = (options) =>
  Api.get('api/services/app/BillOfLading/CountToleranceFailBols', options);

const createBol: ServicePostMethod<
  CreateUpdateBolRequest,
  CreateUpdateBolResponse
> = (data, options) =>
  Api.post('api/services/app/BillOfLading/CreateBol', data, options);

const updateBol: ServicePostMethod<
  CreateUpdateBolRequest,
  CreateUpdateBolResponse
> = (data, options) =>
  Api.put('api/services/app/BillOfLading/UpdateBol', data, options);

const unassignAllForBol: ServicePostMethod<{ bolId: number }, BillOfLading> = (
  { bolId },
  options
) =>
  Api.post(
    `api/services/app/BillOfLading/UnassignAllForBol?bolId=${bolId}`,
    options
  );

// export a default object so we can use FooService.GetAll() in our code - which makes it clear what we're getting
export default {
  getBillsOfLading,
  getBillsOfLadingByDate,
  getFullByBolNumber,
  getBillOfLadingByIdForCustomer,
  getByRemoteId,
  getBOLOrderTypes,
  markAsReady,
  generatePdf,
  markAsReadyToPick,
  getAreAllBOLContainersBeyondCompletedStatus,
  markAsLoaded,
  toleranceCheckForBol,
  runToleranceCheck,
  countToleranceFailBOLs,
  createBol,
  updateBol,
  unassignAllForBol,
};

/**
 * Models used in this service
 */

export interface GetFullBolRequest {
  bolNumber: number | string;
  statuses?: BolStatus[];
  warehouseId?: number | null;
}

export interface TestBolPdfRequest {
  bolNumber: number;
}

export interface CreateUpdateBolRequest {
  bolId: number | null;
  customerCode: number | null;
  customerOrderNumber: string;
  consigneeCode: string | null;
  orderType: string | null;
  export: boolean;
  warehouseCode: string | null;
  instructions: string;
  countryCode: string | null;
  dischargePortCode: string;
  dischargePortName: string;
  finalDestinationCode: string | null;
  loadingPortCode: string;
  loadingPortName: string;
  shippingCompanyCode: string;
  shippingCompanyName: string;
  vesselCode: string;
  vesselName: string;
  voyage: string;
  containerBookingRef: string;
  carrier: string;
  address1: string;
  address2: string;
  suburb: string;
  postCode: string;
  city: string;
  addressCountry: string;
  bolLines: CreateUpdateBolLineRequest[];
}

export interface CreateUpdateBolLineRequest {
  bolLineId: number;
  productCode: string | null;
  batch: string | null;
  cartonsRequired: number;
  weightRequired: number | '';
  instructions: string | null;
  highDate: string | null;
  lowDate: string | null;
  plantCode: string | null;
  requiredTempState: string;
  shipMarks: string | null;
  uom: string | null;
}

export interface CreateUpdateBolResponse {
  bolId: number;
  bolNumber: number;
}

// #region Helper methods.

/**
 * Maps an array of BillOfLadingLine objects to an array of CreateUpdateBolLineRequest objects.
 *
 * @param bolLines - The array of BillOfLadingLine objects to be mapped.
 * @returns An array of CreateUpdateBolLineRequest objects.
 */
export const mapBolLines = (
  bolLines: BillOfLadingLine[]
): CreateUpdateBolLineRequest[] =>
  bolLines.map((line) => ({
    bolLineId: line.id,
    productCode: line.basisProductId.toString(), // Convert productCode to string
    batch: line.batch,
    cartonsRequired: line.cartonsRequired,
    weightRequired: line.weightRequired,
    instructions: line.instructions,
    highDate: line.highDate ?? null,
    lowDate: line.lowDate ?? null,
    plantCode: line.plant,
    requiredTempState: line.requiredTempState,
    shipMarks: line.shipMarks ?? null,
    uom: line.uom,
  }));

// #endregion
