import { Factory } from 'fishery';
import { faker } from '@faker-js/faker';
import { SelectItem } from '../Components/Forms/FormField';
import { User, userFactory } from './User';

// this is used for assigning Billing Codes in a way which can be audited on the Customer entity
export const CUSTOMER_ENTITY_NAME = 'Customers.Customer';

export const BILLING_CYCLES = ['Weekly', 'Monthly'] as const;
export type BillingCycle = typeof BILLING_CYCLES[number];

export interface Customer {
  id: number;
  name: string;
  code: number;
  netsuiteId: string;
  billingCycle?: BillingCycle;
  adminId?: number;
  admin?: User;
  hardAssign: boolean;
  thirdParties?: string[];
}

export interface CustomerSelectOption {
  id: number;
  name: string;
  supportedProductIDs?: number[];
}

export const customerFactory = Factory.define<Customer>(() => {
  return {
    id: faker.datatype.number(),
    name: faker.company.name(),
    code: faker.datatype.number(),
    netsuiteId: faker.datatype.string(),
    billingCycle: faker.helpers.arrayElement(BILLING_CYCLES),
    adminId: faker.datatype.number(),
    admin: userFactory.build(),
    hardAssign: faker.datatype.boolean(),
    thirdParties: faker.random.words(2).split(' '),
  };
});

/**
 *  Helper functions
 */

export function billingCycleSelectItems() {
  return BILLING_CYCLES.map<SelectItem>((label) => ({
    id: label,
    label,
  }));
}

export function customerSelectItems(customers: CustomerSelectOption[]) {
  return customers
    .sort((a, b) => a.name.localeCompare(b.name))
    .map<SelectItem>((customer) => ({
      id: customer.id,
      label: customer.name,
    }));
}

export function customerCodeSelectItems(customers: Customer[]) {
  return customers
    .sort((a, b) => a.name.localeCompare(b.name))
    .map<SelectItem>((customer) => ({
      id: customer.code,
      label: customer.name,
    }));
}
