import { Consignee } from '../Models/Consignee';
import Api, {
  PagedAndSortedSearchRequest,
  PagedResult,
  ServiceGetMethod,
} from './Api';

const getAll: ServiceGetMethod<
  PagedAndSortedSearchRequest,
  PagedResult<Consignee>
> = () => Api.get('api/services/app/Consignee/GetAll?maxResultCount=9999');

export default {
  getAll,
};
