import { faker } from '@faker-js/faker';
import { Factory } from 'fishery';
import React from 'react';
import StatusChip, { StatusChipColors } from '../Components/StatusChip';
import { BillOfLadingLine } from './BillOfLadingLine';
import { Customer } from './Customer';
import { Pallet, PalletDetails } from './Pallet';
import { Seal } from './Seal';
import { BOLContainer } from './BOLContainer';
import { Warehouse, FAKE_WAREHOUSES } from './Warehouse';
import { PaperProps } from '@mui/material';

export interface BillOfLading {
  id: number;
  bolNumber: number;
  shipDate: string;
  customerOrderNo: string;
  customerCode: number;
  orderType: string;
  deliveryToCode: string;
  deliveryToOrderNo: string;
  status: BolStatus;
  warehouseCode: string;
  warehouseId?: number;
  warehouse?: Warehouse[];
  customerId?: string;
  customer?: Customer;
  consigneeId?: string;
  consigneeCode?: string;
  dischargePortCode?: string;
  dischargePortName?: string;
  finalDestinationCode?: string;
  loadingPortCode?: string;
  loadingPortName?: string;
  vesselCode?: string;
  vesselName?: string;
  shippingCompanyCode?: string;
  shippingCompanyName?: string;
  voyage?: string;
  containerBookingRef?: string;

  childBolNumbers?: number[];
  childBolOrderNumbers?: string[];

  lines?: BillOfLadingLine[];
  seals?: Seal[];
  pallets?: Pallet[];
  containers?: BOLContainer[];
  instructions: string;
  countryCode?: string;
  toleranceFail?: boolean;
  canExportBol?: boolean;
  // TODO
  // consignee: Consignee;

  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  suburb?: string;
  addressCountry?: string;
  carrier?: string;
}

export interface BillOfLadingWithCounts extends BillOfLading {
  customerName: string;
  consigneeName: string;
  cartonsCount: number;
  cartonsWeight: number;
  linesCount: number;
  palletsCount: number;
  sealsCount: number;
  containersCount: number;
  cartonsLoadedCount: number;
  cartonsLoadedWeight: number;
  palletsLoadedCount: number;
  palletsPickedCount: number;
}

export interface BillOfLadingWithCountsForCustomer {
  id: number;
  bolNumber: number;
  shipDate: string;
  consigneeName: string;
  customerOrderNo: string;
  warehouseCode: string;
  cartonsCount: number;
  cartonsWeight: number;
  palletsCount: number;
  status: BolStatus;
  canExportBol: boolean;
}

export interface BillOfLadingForCustomerWithContainerAndProducts
  extends BillOfLadingWithCountsForCustomer {
  containers: ContainersByBol[];
  cartonsByProduct: CartonsByProduct[];
  customerName: string;
  consignorName: string;
  carrier: string;
  consigneeAddress: string;
}

export interface ContainersByBol {
  containerCode: string;
  containerId: number;
  palletsCount: number;
  cartonsCount: number;
  cartonsWeight: number;
}

export interface CartonsByProduct {
  productId: number;
  productCode: string;
  productDescription: string;
  cartonsCount: number;
  cartonsWeight: number;
  palletsCount: number;
}

export interface BillOfLadingWithCountsAndPalletDetails
  extends BillOfLadingWithCounts {
  pallets: PalletDetails[];
}

export interface ContainerTotals {
  containerCode: string;
  bolNumber: number;
  loadedCartons: number;
  totalCartons: number;
  loadedPallets: number;
  totalPallets: number;
  loadedWeight: number;
  totalWeight: number;
}

// This needs to be in sync with the backend BOL Status enum.
export enum BolStatus {
  notStarted,
  readyToLoad,
  loading,
  loaded,
  readyToPick,
  pickingInProgress,
}

export const BOL_LABELS = [
  'Not Started',
  'Ready To Load',
  'Loading',
  'Loaded',
  'Ready To Pick',
  'Picking In Progress',
] as const;
export const ALLOW_EXPORT_LABELS = ['Domestic', 'Export'];

export type BolStatusLabel = typeof BOL_LABELS[number];

export const BOL_STATUS_COLORS: StatusChipColors<BolStatusLabel> = {
  'Not Started': { bg: '#E6E6E6' }, // grey/300
  'Ready To Load': { bg: '#80D4FA' }, // blue/200
  Loading: { bg: '#30CB40' }, // green/500
  Loaded: { bg: '#B9F6CA' }, // green/A100
  'Ready To Pick': { bg: '#FAC711' },
  'Picking In Progress': { bg: '#FEF444' },
};

// this is a handy StatusChip component wrapper so we can consistently render BOL status chips from any component
export const BolStatusChip = ({
  status,
  ...props
}: { status: BolStatus } & PaperProps) => (
  <StatusChip
    {...props}
    label={BOL_LABELS[status]}
    colors={BOL_STATUS_COLORS}
    width="10em"
  />
);

export interface BOLOrderType {
  id: number;
  code: string;
  name: string;
}

export const FAKE_ORDER_TYPES: BOLOrderType[] = [
  ['C', 'Customer Order'],
  ['T', 'Transfer Order'],
  ['E', 'Export Order'],
  ['L', 'Local Order'],
  ['P', 'Production Order'],
  ['S', 'Sales Order'],
  ['W', 'Warehouse Replenishment'],
  ['X', 'Warehouse Transfer'],
].map(([code, name], index) => ({ id: index + 1, code, name }));

export const bolSummaryFactory = Factory.define<BillOfLadingWithCounts>(() => {
  const warehouse = faker.helpers.arrayElement(FAKE_WAREHOUSES);
  return {
    id: faker.datatype.number(),
    bolNumber: faker.datatype.number({ min: 10000, max: 99999 }),
    shipDate: faker.datatype.datetime().toISOString(),
    customerName: faker.company.name(),
    consigneeName: faker.company.name(),
    orderType: faker.helpers.arrayElement(FAKE_ORDER_TYPES).code,
    orderNumber: faker.datatype.string(),
    customerOrderNo: faker.datatype.string(),
    customerCode: faker.datatype.number(),
    deliveryToCode: faker.datatype.string(),
    deliveryToOrderNo: faker.datatype.string(),
    status: faker.datatype.number(BOL_LABELS.length - 1),
    warehouseCode: warehouse.code,
    warehouseId: warehouse.id,
    instructions: faker.datatype.string(),

    // counts
    cartonsCount: faker.datatype.number(),
    cartonsWeight: faker.datatype.number(),
    linesCount: faker.datatype.number(),
    palletsCount: faker.datatype.number(),
    sealsCount: faker.datatype.number(),
    containersCount: faker.datatype.number(),
    cartonsLoadedCount: faker.datatype.number(),
    cartonsLoadedWeight: faker.datatype.number(),
    palletsLoadedCount: faker.datatype.number(),
    palletsPickedCount: faker.datatype.number(),
  };
});

export const bolWithCountsAndPalletsDetailsFactory =
  Factory.define<BillOfLadingWithCountsAndPalletDetails>(() => {
    return {
      ...bolSummaryFactory.build(),
      pallets: [],
    };
  });

export const bolWithCountsForCustomerFactory =
  Factory.define<BillOfLadingWithCountsForCustomer>(() => {
    return {
      id: faker.datatype.number(),
      bolNumber: faker.datatype.number({ min: 10000, max: 99999 }),
      shipDate: faker.datatype.datetime().toISOString(),
      consigneeName: faker.company.name(),
      customerOrderNo: faker.datatype.string(),
      warehouseCode: faker.helpers.arrayElement(FAKE_WAREHOUSES).code,
      cartonsCount: faker.datatype.number(),
      cartonsWeight: faker.datatype.number(),
      palletsCount: faker.datatype.number(),
      status: faker.datatype.number(BOL_LABELS.length - 1),
      canExportBol: true,
    };
  });

export const bolWithCountsForCustomerWithContainerAndProductsFactory =
  Factory.define<BillOfLadingForCustomerWithContainerAndProducts>(() => {
    return {
      ...bolWithCountsForCustomerFactory.build(),
      containers: [],
      carrier: faker.datatype.string(),
      consigneeAddress: faker.address.streetAddress(),
      cartonsByProduct: [],
      customerName: faker.company.name(),
      consignorName: faker.company.name(),
    };
  });
