import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Stack, Tab } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import FormField, { SelectItem } from '../../../Components/Forms/FormField';
import { confirmModalDialog } from '../../../Components/ModalDialog';
import useApiForm from '../../../Hooks/useApiForm';
import useApiGet from '../../../Hooks/useApiGet';
import useBOLOrderTypes from '../../../Hooks/useBOLOrderTypes';
import useWarehouses from '../../../Hooks/useWarehouses';
import { BillOfLadingWithCountsAndPalletDetails } from '../../../Models/BillOfLading';
import { consigneeSelectItems } from '../../../Models/Consignee';
import { customerCodeSelectItems } from '../../../Models/Customer';
import { CODE_GROUPS } from '../../../Models/UserDefinedCode';
import BillOfLadingService, {
  CreateUpdateBolLineRequest,
  CreateUpdateBolRequest,
  CreateUpdateBolResponse,
  mapBolLines,
} from '../../../Services/BillOfLadingService';
import ConsigneeService from '../../../Services/ConsigneeService';
import CustomerService from '../../../Services/CustomerService';
import UserDefinedCodeService from '../../../Services/UserDefinedCodeService';
import SaveBolLineGrid from './SaveBolLineGrid';

const formPropertiesToValidate: (keyof CreateUpdateBolRequest)[] = [
  'customerCode',
  'customerOrderNumber',
  'consigneeCode',
  'export',
  'orderType',
  'warehouseCode',
  'countryCode',
  'finalDestinationCode',
  'bolLines',
];

interface Props {
  bol?: BillOfLadingWithCountsAndPalletDetails | null;
  onSaveBolDetails?: (res: CreateUpdateBolResponse) => void;
  detailsTabRefresh?: () => void;
}

/**
 * Component for saving BOL details.
 *
 * @component
 * @param {Props} props - The component props.
 * @param {BillOfLadingWithCountsAndPalletDetails} props.bol - The BOL object.
 */
function SaveBolDetails({ bol, onSaveBolDetails, detailsTabRefresh }: Props) {
  const [tabValue, setTabValue] = useState('1'); // by default show the first tab (Details).
  const [disableEdit, setDisableEdit] = useState(!!bol);
  const [isValidForm, setIsValidForm] = useState(false);

  // Load consignee data
  const { data: consignees } = useApiGet(ConsigneeService.getAll);
  const consigneeItems = useMemo(
    () => consigneeSelectItems(consignees?.items || []),
    [consignees]
  );

  const consigneeInternalItems = useMemo(
    () =>
      consigneeSelectItems(consignees?.items.filter((c) => c.internal) || []),
    [consignees]
  );

  // Load customers data
  const { data: customers } = useApiGet(CustomerService.getCustomers, {
    params: { maxResultCount: 9999 },
  });
  const customerItems = useMemo(
    () => customerCodeSelectItems(customers?.items || []),
    [customers]
  );

  // Load BOL Order Types.
  const { bolOrderTypesByCode } = useBOLOrderTypes();
  const bolOrderTypeItems = useMemo(() => {
    return [
      ...Object.keys(bolOrderTypesByCode).map(
        (code) =>
          ({
            id: code,
            label: bolOrderTypesByCode[code],
          } as SelectItem)
      ),
    ];
  }, [bolOrderTypesByCode]);

  // Export options.
  const exportTypeOptions = useMemo(() => {
    return [
      { label: 'Export', value: 'true' },
      { label: 'Local', value: 'false' },
    ].map<SelectItem>((obj) => ({
      id: obj.value,
      label: obj.label,
    }));
  }, []);

  // Warehouse options.
  const { warehouseCodeSelectItems } = useWarehouses();

  // Country options.
  const { data: countryCodeGroup } = useApiGet(
    UserDefinedCodeService.getGroupDetails,
    {
      params: {
        name: CODE_GROUPS.COUNTRY_CODES,
      },
    }
  );
  const countrySelectItems = useMemo(() => {
    return (
      countryCodeGroup?.userDefinedCodes?.map<SelectItem>((code) => {
        return {
          id: code.code,
          label: code.name,
        };
      }) || []
    );
  }, [countryCodeGroup]);

  // AddressCountry options.
  const addressCountrySelectItems = useMemo(() => {
    const defaultCountry = { id: '', label: 'Select Country' } as SelectItem;
    return [
      defaultCountry,
      ...(countryCodeGroup?.userDefinedCodes?.map<SelectItem>((code) => ({
        id: code.name, // we are sending country name to API not the code.
        label: code.name,
      })) || []),
    ];
  }, [countryCodeGroup]);

  const form = useApiForm(
    bol?.id ? BillOfLadingService.updateBol : BillOfLadingService.createBol,
    {
      bolId: bol?.id || 0,
      consigneeCode: bol?.consigneeCode || null,
      customerOrderNumber: bol?.customerOrderNo || '',
      customerCode: bol?.customerCode || null,
      orderType: bol?.orderType || null,
      export: !!bol?.canExportBol,
      warehouseCode: bol?.warehouseCode || null,
      instructions: '',
      countryCode: bol?.countryCode || null,
      dischargePortCode: bol?.dischargePortCode || '',
      dischargePortName: bol?.dischargePortName || '',
      finalDestinationCode: bol?.finalDestinationCode || null,
      loadingPortCode: bol?.loadingPortCode || '',
      loadingPortName: bol?.loadingPortName || '',
      shippingCompanyCode: bol?.shippingCompanyCode || '',
      shippingCompanyName: bol?.shippingCompanyName || '',
      vesselCode: bol?.vesselCode || '',
      vesselName: bol?.vesselName || '',
      voyage: bol?.voyage || '',
      containerBookingRef: bol?.containerBookingRef || '',
      carrier: bol?.carrier || '',
      address1: bol?.address1 || '',
      address2: bol?.address2 || '',
      suburb: bol?.suburb || '',
      postCode: bol?.postalCode || '',
      city: bol?.city || '',
      addressCountry: bol?.addressCountry || '',
      bolLines: mapBolLines(bol?.lines || []),
    },
    {
      onSuccess: (res) => {
        onSaveBolDetails?.(res);
        detailsTabRefresh?.();
        setDisableEdit(true);
      },
      onError: () => {
        setDisableEdit(false);
      },
    }
  );

  /**
   * Validates the properties of a CreateBolRequest object.
   *
   * @param properties - The properties to validate.
   * @param data - The data object to validate against.
   * @returns A boolean indicating whether all properties are valid.
   */
  function validateProperties(
    properties: (keyof CreateUpdateBolRequest)[],
    data: CreateUpdateBolRequest
  ): boolean {
    for (const property of properties) {
      // make sure there is at least one bol line.
      if (property === 'bolLines') {
        if ((data[property] as CreateUpdateBolLineRequest[]).length === 0) {
          return false;
        }
      }

      // make sure the defined properties are not null, undefined or empty string.
      if (
        data[property] === null ||
        data[property] === undefined ||
        data[property] === ''
      ) {
        return false;
      }
    }
    return true;
  }

  // #region useEffects

  useEffect(() => {
    setIsValidForm(validateProperties(formPropertiesToValidate, form.data));
  }, [form.data]);

  useEffect(() => {
    const bolLinesToCreateUpdate = mapBolLines(bol?.lines || []);
    form.setData('bolLines', bolLinesToCreateUpdate);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bol?.lines]);

  // #endregion

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [isInternal, setIsInternal] = useState(false);
  function handleChange(e: SelectItem) {
    if (e != null && e.id == 'X') {
      setIsInternal(true);
    } else {
      setIsInternal(false);
    }
  }

  return (
    <Box component="form" onSubmit={form.submit}>
      <Stack
        direction={'row'}
        spacing={1}
        sx={{ position: 'absolute', right: 50, zIndex: 1 }}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!isValidForm || disableEdit}
          onClick={() => {
            form.submit();
          }}
        >
          Save BOL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          sx={{ display: !bol || !disableEdit ? 'none' : 'inline-flex' }}
          onClick={() => {
            setDisableEdit(!disableEdit);
          }}
        >
          Edit BOL
        </Button>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          sx={{ display: !bol || disableEdit ? 'none' : 'inline-flex' }}
          onClick={async () => {
            await confirmModalDialog({
              title: 'Cancel Edit',
              content: 'Are you sure? All unsaved changes will be lost.',
              onAccept: () => {
                setDisableEdit(!disableEdit);
                form.reset();
                detailsTabRefresh?.();
              },
            });
          }}
        >
          Cancel
        </Button>
      </Stack>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange}>
            <Tab label="BOL Details" value="1" />
            <Tab label="Shipping" value="2" />
            <Tab label="Address" value="3" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <Stack direction="row" spacing={2}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="customerCode"
              type="autocomplete"
              size="small"
              label="Customer *"
              options={customerItems}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="export"
              type="autocomplete"
              size="small"
              options={exportTypeOptions}
              label="Local/Export *"
              isOptionEqualToValue={(option) => option.id === 'false'}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="countryCode"
              type="autocomplete"
              size="small"
              label="Country *"
              options={countrySelectItems}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="customerOrderNumber"
              type="text"
              size="small"
              label="Customer order number"
              required
              inputProps={{ 'data-lpignore': true }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="orderType"
              type="autocomplete"
              size="small"
              label="BOL order type *"
              options={bolOrderTypeItems}
              sx={{ paddingTop: 2 }}
              onChange={(_event, value) => handleChange(value as SelectItem)}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="consigneeCode"
              type="autocomplete"
              size="small"
              label="Ship to *"
              options={isInternal ? consigneeInternalItems : consigneeItems}
              sx={{ paddingTop: 2 }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="warehouseCode"
              type="autocomplete"
              size="small"
              label="Source Warehouse *"
              options={warehouseCodeSelectItems}
              sx={{ paddingTop: 2 }}
            />
          </Stack>
        </TabPanel>
        <TabPanel value="2">
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="dischargePortCode"
              type="text"
              size="small"
              label="Discharge port code"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="dischargePortName"
              type="text"
              size="small"
              label="Discharge port name"
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="loadingPortCode"
              type="text"
              size="small"
              label="Loading port code"
            />
          </Stack>
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="loadingPortName"
              type="text"
              size="small"
              label="Loading port name"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="vesselCode"
              type="text"
              size="small"
              label="Vessel code"
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="voyage"
              type="text"
              size="small"
              label="Voyage"
            />
          </Stack>
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="shippingCompanyCode"
              type="text"
              size="small"
              label="Shipping company code"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="vesselName"
              type="text"
              size="small"
              label="Vessel name"
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="containerBookingRef"
              type="text"
              size="small"
              label="Container booking reference"
            />
          </Stack>
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="shippingCompanyName"
              type="text"
              size="small"
              label="Shipping company name"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="finalDestinationCode"
              type="autocomplete"
              options={countrySelectItems}
              size="small"
              label="Final destination *"
              sx={{ marginTop: 3 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="carrier"
              type="text"
              size="small"
              label="Carrier"
              sx={{ marginTop: 3 }}
            />
          </Stack>
        </TabPanel>
        <TabPanel value="3">
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="address1"
              type="text"
              size="small"
              label="Address1"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="address2"
              type="text"
              size="small"
              label="Address2"
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="city"
              type="text"
              size="small"
              label="City"
            />
          </Stack>
          <Stack direction="row" spacing={2} my={1}>
            <FormField
              form={form}
              disabled={disableEdit}
              id="postCode"
              type="text"
              size="small"
              label="Postcode"
              sx={{ marginTop: 0 }}
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="suburb"
              type="text"
              size="small"
              label="Suburb"
            />
            <FormField
              form={form}
              disabled={disableEdit}
              id="addressCountry"
              type="autocomplete"
              size="small"
              label="Address country"
              options={addressCountrySelectItems}
            />
          </Stack>
        </TabPanel>
      </TabContext>

      <SaveBolLineGrid
        disableEdit={disableEdit}
        bolLines={bol?.lines}
        onValidBolLines={(bolLines, containsInvalidGridRow) => {
          if (containsInvalidGridRow) {
            form.setData('bolLines', []);
          } else {
            form.setData('bolLines', bolLines);
          }
        }}
      />
    </Box>
  );
}

export default SaveBolDetails;
