import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import React, { useState } from 'react';
import { SelectItem } from '../../Components/Forms/FormField';

/***
 * This modal allows the Admin to edit a customer
 */

export interface EditCustomerBillingCodesProps {
  customerName: string | undefined;
  groupName: string;
  selectedIds: number[];
  items: SelectItem[];
  onClose: () => void;
  onSuccess: (newIds: number[]) => void;
}

export default function ({
  customerName,
  groupName,
  selectedIds,
  items,
  onClose,
  onSuccess,
}: EditCustomerBillingCodesProps) {
  // don't update the original list yet
  const [mySelectedIds, setMySelectedIds] = useState([...selectedIds]);

  const toggle = (id: number) => {
    if (mySelectedIds.includes(id)) {
      setMySelectedIds(mySelectedIds.filter((i) => i != id));
    } else {
      setMySelectedIds([...mySelectedIds, id]);
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xl" scroll="paper">
      <DialogTitle display="flex" justifyContent="space-between">
        <Box data-testid="billing-group-name">{groupName}</Box>
        <Box sx={{ fontWeight: 'normal' }}>{customerName}</Box>
      </DialogTitle>
      <DialogContent sx={{ columns: '300px' }}>
        {items.map((item) => (
          <FormGroup key={item.id}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mySelectedIds.includes(item.id as number)}
                  onClick={() => toggle(item.id as number)}
                />
              }
              label={item.label}
            />
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={mySelectedIds.length == items.length}
          onClick={() => setMySelectedIds(items.map((i) => i.id as number))}
        >
          Select All
        </Button>
        <Button
          disabled={!mySelectedIds.length}
          onClick={() => setMySelectedIds([])}
        >
          Select None
        </Button>
        <Box flexGrow={1} />
        <Button
          onClick={onClose}
          tabIndex={2}
          data-testid="cancel-billing-codes"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onSuccess(mySelectedIds)}
          tabIndex={1}
          data-testid="update-billing-codes"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
