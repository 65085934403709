import { Factory } from 'fishery';
import { SelectItem } from '../Components/Forms/FormField';
import { faker } from '@faker-js/faker';

export interface Product {
  id: number;
  code: string;
  eCertCode?: string;
  description: string;
  eCertDesc?: string;
  hsc?: string;
  unitOfMeasure?: string;
  packageType?: string;
  weight?: number;
  packWeight?: number;
  palletStandardConfiguration?: number;
  billGroup?: string;
  species?: string;
  brand?: string;
  syncStatus?: number;
  eCertExempt?: boolean;
  supportedCustomerIDs?: number[];
}

export const productFactory = Factory.define<Product>(() => ({
  id: faker.datatype.number(),
  code: faker.datatype.string(),
  description: faker.datatype.string(),
}));

/**
 *  Helper functions
 */

export function productSelectItems(products: Product[]) {
  return products
    .map<SelectItem>((product) => ({
      id: product.id,
      label: `${product.code || ''} - ${product.description || ''}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Generates an array of select items based on the given products array.
 * Each select item contains the product code as the ID and a label that combines the code and description.
 * The resulting array is sorted alphabetically by the label.
 *
 * @param products - The array of products to generate select items from.
 * @returns An array of select items.
 */
export function productSelectItemsByCode(products: Product[]) {
  return products
    .map<SelectItem>((product) => ({
      id: product.code,
      label: `${product.code || ''} - ${product.description || ''}`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
