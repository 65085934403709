import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideSummary } from './PalletLocationsSummaryCard';

interface PalletLocationSummaryCardProps {
  roomName: string;
  sides: SideSummary[];
  backgroundColor?: string;
  textColor?: string;
  testId?: string;
}

export default function PalletLocationSummaryCard({
  roomName,
  sides,
  backgroundColor,
  textColor,
  testId,
}: PalletLocationSummaryCardProps) {
  const PalletLocationSummaryCardItem = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: textColor,
    flex: 'auto 1 1',
    backgroundColor: backgroundColor,
    cursor: 'pointer',
  }));
  const wrapperTestId =
    testId ?? `pallet-summary-card-${roomName.replace(' ', '-')}`;
  return (
    <Paper
      sx={{
        minWidth: 190,
        padding: 0.3,
        border: 'solid 2px transparent',
        borderColor: backgroundColor,
      }}
      elevation={2}
    >
      <Stack direction="row" flexWrap="wrap" gap={0.5}>
        <PalletLocationSummaryCardItem
          elevation={0}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor,
          }}
        >
          <Typography data-testid={wrapperTestId}>{roomName}</Typography>
        </PalletLocationSummaryCardItem>
        {sides.map((side, index) => {
          return (
            <PalletLocationSummaryCardItem elevation={0} key={index}>
              <SideDetailsBlock
                label={side.sideName}
                palletNumber={side.palletCount}
                testId={`side-details-block-${side.sideName}`}
              />
            </PalletLocationSummaryCardItem>
          );
        })}
      </Stack>
    </Paper>
  );
}

export function SideDetailsBlock({
  label,
  palletNumber,
  testId,
}: {
  label: string;
  palletNumber: number;
  testId?: string;
}) {
  return (
    <Stack
      direction={'column'}
      data-testid={testId ?? `details-block-${label.toLowerCase()}`}
    >
      <Typography
        sx={{
          fontSize: '0.85rem',
        }}
        data-testid={`side-${label}`}
      >
        {label}
      </Typography>
      <Typography
        sx={{ fontWeight: 600 }}
        data-testid={`pallet-number-section-${label}`}
      >
        {palletNumber}
      </Typography>
    </Stack>
  );
}
