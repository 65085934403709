import { CloudSyncTwoTone } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Fab,
  FormControlLabel,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  GridColDef,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridSortCellParams,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import FetchBol from '../../Components/BillOfLading/FetchBol';
import CardTitle from '../../Components/CardTitle';
import MobilePage from '../../Components/MobilePage';
import StandardDataGrid from '../../Components/StandardDataGrid';
import { toast, toastError } from '../../Components/Toast';
import useApiForm from '../../Hooks/useApiForm';
import useApiGet from '../../Hooks/useApiGet';
import useBOLOrderTypes from '../../Hooks/useBOLOrderTypes';
import { formatNumber } from '../../Lib/utils';
import {
  BOLContainer,
  BOLContainerStatus,
  CONTAINER_LABELS,
  ContainerChip,
} from '../../Models/BOLContainer';
import { AuthContext } from '../../Providers/AuthProvider';
import BolContainerService from '../../Services/BolContainerService';

export default function () {
  const { user } = useContext(AuthContext);
  const [containers, setContainers] = useState<BOLContainer[] | null>(null);
  const [open, setOpen] = useState(false);
  const canSyncBOL =
    user?.permissions?.find((p) => p === 'Pages.AdminBolLoadList') !==
    undefined;

  const allowedBolOrderTypesForFiltering = ['E', 'L', 'X'];
  const { bolOrderTypesByCode } = useBOLOrderTypes();

  const [checkedCodes, setCheckedCodes] = useState<string[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  /**
   * Handles the change event for a checkbox input.
   *
   * @param event - The synthetic event triggered by the checkbox change.
   * @param checked - A boolean indicating whether the checkbox is checked or not.
   *
   * This function updates the state of `checkedCodes` based on the checkbox value.
   * If the checkbox is checked, the value is added to `checkedCodes`. If unchecked,
   * the value is removed from `checkedCodes`.
   * If the value is 'all', `checkedCodes` is set to an empty array.
   * If 'all' is checked, all other checkboxes are unchecked.
   *
   * It then constructs a new filter model for a grid based on the updated `checkedCodes`.
   * The filter model is updated to include filters for the `bolOrderType` field.
   *
   * Finally, the function updates the state of the filter model with the new filter model.
   */
  const handleCheckboxChange = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    const { value } = event.target as HTMLInputElement;

    let newCheckedCodes: string[];
    if (value === 'all') {
      newCheckedCodes = [];
    } else {
      newCheckedCodes = checked
        ? [...checkedCodes, value]
        : checkedCodes.filter((code) => code !== value);
    }

    setCheckedCodes(newCheckedCodes);

    const newExternalFilterModel: GridFilterModel = {
      logicOperator: GridLogicOperator.Or,
      items:
        newCheckedCodes.length > 0
          ? newCheckedCodes.map(
              (x) =>
                ({
                  field: 'bolOrderType',
                  operator: 'equals',
                  value: x,
                  id: x,
                } as GridFilterItem)
            )
          : [],
    };

    const updatedFilterModel = {
      logicOperator: GridLogicOperator.Or,
      items: [
        ...filterModel.items.filter((x) => x.field !== 'bolOrderType'), // remove existing filters for bolOrderType
        ...newExternalFilterModel.items,
      ],
    };

    setFilterModel(updatedFilterModel);
  };

  const statuses = useMemo(
    () => [
      BOLContainerStatus.open,
      BOLContainerStatus.loading,
      BOLContainerStatus.readyToPick,
      BOLContainerStatus.pickingInProgress,
      BOLContainerStatus.readyToLoad,
    ],
    []
  );

  const closeModal = () => {
    setOpen(false);
  };

  const onSuccess = () => {
    toast(
      'Bill of Lading imported. Pallets and Cartons will sync in the background.'
    );
    closeModal();
    refresh();
  };

  const { loading, refresh } = useApiGet(
    BolContainerService.getContainersByStatuses,
    {
      params: {
        statuses,
        warehouseId: user?.currentWarehouseId,
      },
      onSuccess: (data) => {
        setContainers(data);
      },
    }
  );
  const updateContainerDetailsForm = useApiForm(
    BolContainerService.updateContainerPriority,
    {
      id: 0,
      loadType: '',
      priority: undefined,
      location: '',
    },
    {
      onSuccess: () => {
        refresh();
      },
      onError: (error) => {
        toastError(error);
      },
    }
  );

  const handleRowUpdate = async (
    updatedRow: BOLContainer,
    originalRow: BOLContainer
  ) => {
    if (
      updatedRow.loadType !== originalRow.loadType ||
      updatedRow.priority !== originalRow.priority ||
      updatedRow.location !== originalRow.location
    ) {
      updateContainerDetailsForm.setData('id', updatedRow.id);
      updateContainerDetailsForm.setData('loadType', updatedRow.loadType);
      updateContainerDetailsForm.setData('location', updatedRow.location);

      // Use a temporary variable to handle the conversion
      let priority: number | string | undefined = updatedRow.priority;

      // Convert empty string to undefined
      if (priority?.toString() === '') {
        priority = undefined;
      }

      updateContainerDetailsForm.setData('priority', priority);

      await updateContainerDetailsForm.submit();

      // returning the original row here. When the form is submitted, the grid will re-render
      // using this approach as if there was an error from the server, it's async and we won't be able to revert back to the original
      // row if there was an error, returning the original row ensures if there was an error, the original data is preserved.
      return originalRow;
    }
    return originalRow;
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'bolNumber',
        headerName: 'BOL',
        minWidth: 50,
        maxWidth: 150,
        type: 'number',
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Link to={`/bol/${params.row.bolNumber}`}>
            {params.row.bolNumber}
          </Link>
        ),
      },
      {
        field: 'code',
        headerName: 'Container',
        minWidth: 130,
        maxWidth: 200,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Link to={`/bol/${params.row.bolNumber}/${params.row.code}`}>
            {params.row.code}
          </Link>
        ),
      },
      {
        field: 'shipDate',
        headerName: 'Ship Date',
        minWidth: 100,
        maxWidth: 200,
        type: 'date',
        valueGetter: (params: GridRenderCellParams<BOLContainer>) =>
          new Date(params.row.shipDate ?? 0),
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 150,
        maxWidth: 230,
      },
      {
        field: 'bolOrderType',
        headerName: 'BOL Order Type',
        minWidth: 150,
        maxWidth: 230,
        valueFormatter: (params) => bolOrderTypesByCode[params.value as string],
      },
      {
        field: 'customerOrderNo',
        headerName: 'Customer Order #',
        minWidth: 140,
        maxWidth: 300,
      },
      {
        field: 'vesselName',
        headerName: 'Vessel',
        minWidth: 140,
        maxWidth: 300,
      },
      {
        field: 'loadType',
        headerName: 'Load Type',
        minWidth: 100,
        maxWidth: 200,
        editable: true,
      },
      {
        field: 'priority',
        headerName: 'Priority',
        minWidth: 100,
        maxWidth: 200,
        editable: true,
        type: 'number',
        sortComparator: (
          v1: number | null | string,
          v2: number | null | string,
          params: GridSortCellParams
        ): number => {
          const sortModel: GridSortModel = params.api.getSortModel();
          const sortDirection = sortModel.find(
            (model: GridSortItem) => model.field === params.field
          )?.sort;

          // If both values are null or empty strings, they are considered equal
          if ((v1 === null || v1 === '') && (v2 === null || v2 === '')) {
            return 0;
          }

          if (v1 === null || v1 === '') {
            return sortDirection === 'asc' ? 1 : -1;
          }

          if (v2 === null || v2 === '') {
            return sortDirection === 'asc' ? -1 : 1;
          }
          // Both values are numbers, so we can compare them directly
          return (v1 as number) - (v2 as number);
        },
      },
      {
        field: 'location',
        headerName: 'Staging Location',
        minWidth: 150,
        maxWidth: 250,
        editable: true,
      },
      {
        field: 'palletsPicked',
        headerName: 'Pallets Picked',
        minWidth: 100,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <>
            {formatNumber(params.row.palletsInPickedLocation ?? 0)} /{' '}
            {formatNumber(params.row.totalPallets ?? 0)}
          </>
        ),
      },
      {
        field: 'palletsLoaded',
        headerName: 'Pallets Loaded',
        minWidth: 100,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <>
            {formatNumber(params.row.loadedPallets ?? 0)} /{' '}
            {formatNumber(params.row.totalPallets ?? 0)}
          </>
        ),
      },
      {
        field: 'cartonsLoaded',
        headerName: 'Cartons Loaded',
        minWidth: 100,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <>
            {formatNumber(params.row.loadedCartons ?? 0)} /{' '}
            {formatNumber(params.row.totalCartons ?? 0)}
          </>
        ),
      },
      {
        field: 'cartonsWeight',
        headerName: 'Weight',
        minWidth: 160,
        maxWidth: 300,
        align: 'right',
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <>
            {formatNumber(params.row.loadedWeight ?? 0)} /{' '}
            {formatNumber(params.row.totalWeight ?? 0)}
          </>
        ),
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 200,
        maxWidth: 380,
        editable: false,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <Tooltip title={params.row.notes}>
            <span>{params.row.notes?.substring(0, 30)}</span>
          </Tooltip>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        maxWidth: 250,
        renderCell: (params: GridRenderCellParams<BOLContainer>) => (
          <ContainerChip status={params.row.status} />
        ),
        valueGetter: (params: GridRenderCellParams) =>
          CONTAINER_LABELS[params.row.status],
      },
    ],
    [bolOrderTypesByCode]
  );

  const initialSortModel: GridSortModel = [
    {
      field: 'bolNumber',
      sort: 'asc',
    },
  ];

  return (
    <MobilePage sx={{ position: 'relative' }} maxWidth={false}>
      <Box sx={{ height: '82vh' }}>
        <Stack direction={'row'} spacing={2}>
          <CardTitle title="Load Plan" />
          <FormControlLabel
            data-testid="bol-order-type-all"
            key={'all'}
            color="default"
            control={<Checkbox />}
            label={'All'}
            value={'all'}
            onChange={handleCheckboxChange}
            checked={checkedCodes.length === 0}
          />
          {Object.entries(bolOrderTypesByCode)
            .filter(([code]) => allowedBolOrderTypesForFiltering.includes(code))
            .map(([code, name]) => (
              <FormControlLabel
                data-testid={`bol-order-type-${code}`}
                key={code}
                color="default"
                control={<Checkbox />}
                label={name}
                value={code}
                onChange={handleCheckboxChange}
                checked={checkedCodes.includes(code)}
              />
            ))}
        </Stack>
        {canSyncBOL && (
          <Fab
            color="primary"
            size="medium"
            data-testid="loadplan-datagrid-fab-button"
            sx={{
              position: 'absolute',
              right: 24,
              top: 10,
            }}
            onClick={() => setOpen(true)}
          >
            <CloudSyncTwoTone sx={{ top: '50px' }} />
          </Fab>
        )}
        <StandardDataGrid
          testId="load-plan-data-grid"
          loading={loading}
          columns={columns}
          rows={containers || []}
          getRowId={(row) => row.id}
          initialSortModel={initialSortModel}
          initialFilterModel={filterModel}
          onFilterModelChange={(updatedGridFilterModel) => {
            setFilterModel(updatedGridFilterModel);
          }}
          disableRowSelectionOnClick={true}
          onRowUpdate={handleRowUpdate}
        />
        {open && <FetchBol onClose={closeModal} onSuccess={onSuccess} />}
      </Box>
    </MobilePage>
  );
}
