import React, { useCallback, useState } from 'react';
import MobilePage, { MobilePageTab } from '../../../Components/MobilePage';
import { TabPanel } from '@mui/lab';
import { History, Inventory2Outlined, Widgets } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Pallet, PalletForCustomer } from '../../../Models/Pallet';
import CartonDetails from '../../PalletSearch/CartonDetails';
import CustomerPalletSearch from './CustomerPalletSearch';
import PalletMovementForCustomer from './PalletMovementForCustomer';

const tabLists = ['pallet', 'cartons', 'history'];
type Tab = typeof tabLists[number];

export default function () {
  const navigate = useNavigate();

  // get the pallet code from the url
  const { palletCode } = useParams<{ palletCode: string }>();

  // Preload with the pallet code from the url
  const [palletCodeState, setPalletCodeState] = React.useState<string>(
    !palletCode ? '' : palletCode
  );

  // if the url contains a #tab we set the default tab to it
  const [tab, setTab] = useState<Tab>('pallet');
  const [pallet, setPallet] = useState<PalletForCustomer | null>(null);

  const onTabChange = (tab: Tab) => {
    // add the hash to the url, so we can redirect to the tab if needed
    navigate(`#${tab}`, { replace: true });
    setTab(tab);
  };

  //Start Pallet Tab related codes
  const [selectedPalletId, setSelectedPalletId] = useState<number | null>(null);

  const onGotPalletEnquiryInfo = useCallback(
    (pallet: Pallet | PalletForCustomer) => {
      setSelectedPalletId(pallet.id);
      setPalletCodeState(pallet.barcode);
      setPallet(pallet);
    },
    []
  );

  // End Pallet Tab related code
  //Start Cartons Tab related code

  //End Carton related code

  const tabs: MobilePageTab<Tab>[] = [
    {
      id: 'pallet',
      icon: Widgets,
    },
    {
      id: 'cartons',
      icon: Inventory2Outlined,
    },
    {
      id: 'history',
      icon: History,
    },
  ];
  return (
    <MobilePage
      maxWidth={false}
      tabs={tabs}
      tab={tab}
      onTabChange={onTabChange}
      title="Pallet Enquiry"
    >
      <TabPanel value="pallet" sx={{ padding: 0 }}>
        <CustomerPalletSearch
          palletCode={palletCodeState!}
          onGotPalletEnquiryInfo={onGotPalletEnquiryInfo}
        />
      </TabPanel>
      <TabPanel value="cartons" sx={{ padding: 0 }}>
        <CartonDetails pallet={pallet as Pallet}></CartonDetails>
      </TabPanel>
      <TabPanel value="history" sx={{ padding: 0 }}>
        <PalletMovementForCustomer palletId={selectedPalletId} />
      </TabPanel>
    </MobilePage>
  );
}
